
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";
import { decOfNum } from "@/utils/DecOfNum";
import { stringToNumber } from "@/utils/StringToNumber";

interface Age {
  id: number;
  label: string;
}

@Component({
  inheritAttrs: false,
})
export default class ChoosePassenger extends Vue {
  visible = false;
  openAge = false;
  count = 1;
  adultCount = 1;
  childCount = 0;
  childCountArray: Age[] = [];
  ageList = [
    { id: 1, label: "1 год" },
    { id: 2, label: "2 года" },
    { id: 3, label: "3 года" },
    { id: 4, label: "4 года" },
    { id: 5, label: "5 года" },
    { id: 6, label: "6 лет" },
    { id: 7, label: "7 лет" },
    { id: 8, label: "8 лет" },
    { id: 9, label: "9 лет" },
    { id: 10, label: "10 лет" },
    { id: 11, label: "11 лет" },
    { id: 12, label: "12 лет" },
  ];

  @Prop({ default: () => uuidv4() })
  id: string;

  @Prop({ default: 1 })
  valueAdultCount: number;

  @Prop({ default: 0 })
  valueChildCount: number;

  @Prop({ default: "Выберите значения" })
  optionLabel: string;

  @Prop()
  label: string;

  @Prop()
  help: string;

  @Prop()
  classes: string;

  @Prop({ default: false })
  hasError: boolean;

  @Watch("valueAdultCount", { immediate: true })
  async changeValueAdultCount(val: number) {
    await this.$nextTick();
    this.adultCount = val;
    this.count = stringToNumber(this.adultCount) + stringToNumber(this.childCount);
  }

  @Watch("valueChildCount", { immediate: true })
  async changeValueChildCount(val: number) {
    await this.$nextTick();
    this.childCount = val;
    this.count = stringToNumber(this.adultCount) + stringToNumber(this.childCount);
  }

  get touristCount() {
    if (this.childCount > 0 || this.adultCount > 1) {
      return this.count + " " + decOfNum(this.count, ["человек", "человека", "человек"]);
    } else {
      return "1 взрослый";
    }
  }

  open(event) {
    this.visible = !this.visible;
    this.$emit("focus", event);
    this.$emit("change-adult", this.adultCount);
    this.$emit("change-child", this.childCountArray);
  }

  changeAge(item: Age) {
    if (this.count < 10) {
      this.childCountArray.push(item);
      this.count++;
      this.childCount++;
      this.openAge = false;
      this.$emit("change-child", this.childCountArray);
      // this.$emit("change-child", this.childCountArray.length);
    }
  }

  changeAdult(event: number | string) {
    if (typeof event === "string") {
      if (event === "-" && this.count !== 1) {
        this.adultCount--;
        this.count--;
        this.$emit("change-adult", this.adultCount);
      }
      if (event === "+" && this.count < 10) {
        this.adultCount++;
        this.count++;
        this.$emit("change-adult", this.adultCount);
      }
    } else {
      this.$emit("change-adult", event);
      this.adultCount = event;
    }
  }

  delChild(i: number) {
    this.childCountArray.splice(i, 1);
    this.count--;
    // this.$emit("change-child", this.childCountArray.length);
    this.$emit("change-child", this.childCountArray);
  }
}
