
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AppStore from "@/store/AppStore";

@Component
export default class BreadCrumbs extends Vue {
  get breadCrumbs() {
     return getModule(AppStore, this.$store).breadCrumbs;
  }

  get shouldShow() {
    return this.breadCrumbs.length >= 2;
  }

  routeTo(route: any){
    getModule(AppStore, this.$store).setRoute(route)
  }
}
