
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseCountUsers extends Vue {
  @Prop({ default: { adult: 0, child: 0, adv: 0 } })
  places: {
    adult;
    child;
    adv;
  };
}
