
import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseFilterModel } from "@/components/BaseFilterModel";
import { numberMask } from "@/utils/InputMaskDefinitions";

@Component
export default class DirectionsFilter extends Vue {
  @Prop()
  filterModel: BaseFilterModel;

  showMobile = false;

  get numberMask() {
    return numberMask;
  }
}
