import { BaseService } from "@/services/BaseService";

export enum SeatStrokeColors {
  OCCUPIED = "#dddde3",
  FREE = "#3068F7",
}

export class SeatsSVGService extends BaseService {
  private svg: string;
  private parser = new DOMParser();

  public setSvg(svg: string) {
    this.svg = svg;

    return this;
  }

  public markOccupiedSeats(
    occupiedSeats: number[],
    selectedSeats: number[],
    chosen: number[]
  ) {
    const doc = this.parser.parseFromString(this.svg, "image/svg+xml");
    if (chosen) {
      if (chosen.length) {
        const filteredOccupied = occupiedSeats.filter(
          (item) => chosen.findIndex((iter) => iter === item) === -1
        );
        if (filteredOccupied.length) {
          filteredOccupied.forEach((seatNumber) => {
            const rect = doc?.querySelector(
              `rect.js-seat[data-id="${seatNumber}"]`
            );
            if (rect) {
              // rect.classList.add("js-occupied-seat");
              rect.parentElement?.classList.add("js-occupied-group-seat");
              rect.setAttribute("stroke", SeatStrokeColors.OCCUPIED);
            }
          });
        }
        if (chosen.length) {
          chosen.forEach((seatNumber) => {
            const rect = doc?.querySelector(
              `rect.js-seat[data-id="${seatNumber}"]`
            );
            if (rect) {
              rect.parentElement?.classList.add("js-seat-group-selected");
            }
          });
        }
      }
    }

    if (occupiedSeats) {
      if (occupiedSeats.length) {
        occupiedSeats.forEach((seatNumber) => {
          const rect = doc?.querySelector(
            `rect.js-seat[data-id="${seatNumber}"]`
          );
          if (rect && !selectedSeats.includes(seatNumber)) {
            rect.classList.add("js-occupied-seat");
            rect.parentElement?.classList.add("js-occupied-group-seat");
            rect.setAttribute("stroke", SeatStrokeColors.OCCUPIED);
          }
        });
      }
    }
    if (selectedSeats) {
      if (selectedSeats.length) {
        selectedSeats.forEach((seatNumber) => {
          const rect = doc?.querySelector(
            `rect.js-seat[data-id="${seatNumber}"]`
          );
          if (rect) {
            rect.parentElement?.classList.add("js-seat-group-selected");
            // rect.setAttribute("stroke", SeatStrokeColors.OCCUPIED);
          }
        });
      }
    }

    return this.setSvg(doc.documentElement.outerHTML);
  }

  public disableTextPointerEvents() {
    const doc = this.parser.parseFromString(this.svg, "image/svg+xml");

    doc?.querySelectorAll("text").forEach((textElement) => {
      const style = this.rtrim(textElement.getAttribute("style"), ";");

      textElement.setAttribute("style", `${style}; pointer-events: none`);
    });

    return this.setSvg(doc.documentElement.outerHTML);
  }

  public get() {
    return this.svg;
  }

  private rtrim(str: string | null, charlist: string) {
    if (!str) {
      return str;
    }

    charlist = !charlist
      ? " \\s\xA0"
      : charlist.replace(/([\\[\\]\(\)\.\?\/\*\{\}\+\$\^\\:])/g, "$1");
    const re = new RegExp("[" + charlist + "]+$", "g");

    return str.replace(re, "");
  }
}
