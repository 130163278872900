
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { minLength, minValue, required } from "vuelidate/lib/validators";
import dayjs from "dayjs";
import { TourType } from "@/models/TourType";
import HotelFilterTop from "@/modules/Hotels/components/HotelFilterTop.vue";
import TheContainer from "@/components/TheContainer.vue";
import BaseChoosePassenger from "@/components/BaseChoosePassenger.vue";
import { CityModel } from "@/models/common/CityModel";
import { getModule } from "vuex-module-decorators";
import AppStore from "@/store/AppStore";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { CommonService } from "@/services/CommonService";
import { Validations } from "vuelidate-property-decorators";

import { ExcursionService } from "@/modules/Excursions/ExcursionService";
import { TourService } from "@/modules/Tours/TourService";
import DirectionSearchSelect from "@/components/directions/DirectionSearchSelect.vue";
import BaseAdvancedFilter from "./BaseAdvancedFilter.vue";
import {
  BaseDaysRangeModel,
  BaseFilterModel,
  FilterTypes,
} from "@/models/BaseFilterModel";
@Component({
  components: {
    HotelFilterTop,
    TheContainer,
    DirectionSearchSelect,
    BaseChoosePassenger,
    BaseAdvancedFilter,
  },
})
export default class BaseFilter extends Vue {
  @Prop()
  city_prop: number;

  @Prop()
  hotelId?: number;

  @Prop()
  direction: string;

  @Prop({ default: false })
  isExcursion: boolean;

  @Prop()
  externalFilterModel: BaseFilterModel;

  $refs: {
    multiselectRef1: any;
    multiselectRef2: any;
    ExcursionCalendar: any;
    SeaTourCalendar: any;
  };

  baseFilterModel: BaseFilterModel = new BaseFilterModel();
  advancedFilterVisible = false;
  daysRange: BaseDaysRangeModel | null = null;
  routeDaysRange: BaseDaysRangeModel | null = null;

  markedDates: { date: string; class: string }[] = [];
  daysDuration: string = "";
  selectedTourId: number = 0;

  //  Откуда
  fromCity: CityModel = new CityModel();
  fromCities: CityModel[] = [];

  debounceTimeout: null | ReturnType<typeof setInterval>;

  @Prop({ default: false })
  isCatalog: boolean;

  @Prop({ default: true })
  isDirection: boolean;

  async findFromCities(query: string) {
    if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(async () => {
      if (!!query) {
        this.fromCities = await this.$serviceLocator
          .getService(CommonService)
          .getCities(query);

        if (this.$globalParams?.from && this.fromCities?.length) {
          this.fromCity = this.fromCities[0];
          this.onFromCitiesSelected(this.fromCities[0]);
        }
      }
    }, 300);
  }

  get allTourCaptions() {
    const types = TourType;
    Object.keys(types).forEach((el) => {
      if (
        [
          "CATALOG_EXCURSION",
          "CATALOG_HOTEL_WITHOUT_DIRECTION",
          "CATALOG_HOTEL_WITH_DIRECTION",
        ].includes(el)
      ) {
        delete types[el];
      }
    });
    return types;
  }

  async mounted() {
    await this.$nextTick();
    await this.$nextTick();
    this.currentFilterTab();
    if (this.$globalParams?.from) {
      this.findFromCities(this.$globalParams?.from);
      this.findToCities(this.$globalParams?.to);
    }
    if (this.$globalParams?.days_duration) {
      this.daysDuration = this.$globalParams.days_duration;
    }
  }

  updated() {
    this.setAutocompleteOff();
  }

  getCalendarFilterModelAsPojo(date: any) {
    const filterModelAsPojo = this.baseFilterModel.cloneAndRemoveEmptyValue([
      "child_count",
    ]);
    const start = dayjs(date).format("YYYY-MM-DD");
    const end = dayjs(date).add(60, "day").format("YYYY-MM-DD");

    filterModelAsPojo.start_date = start;
    filterModelAsPojo.finish_date = end;

    return filterModelAsPojo;
  }

  async excursionCalendarOpened(args: any) {
    this.markedDates.splice(0, this.markedDates.length);
    const calDate = this.$refs.ExcursionCalendar.getCalendarDateFormatted();
    if (this.isCatalog) {
      this.baseFilterModel.to_city_id = this.city_prop;
    }

    if (
      !this.baseFilterModel.from_city_id ||
      !this.baseFilterModel.to_city_id ||
      !this.baseFilterModel.duration_from
    ) {
      return;
    }

    const filter = this.getCalendarFilterModelAsPojo(args || calDate);
    if (this.selectedTourId) {
      filter.id = this.selectedTourId;
    }

    let days;
    if (this.isCatalog && !this.isExcursion) {
      days = await this.$serviceLocator
        .getService(TourService)
        .getSeaTourCalendar(filter);
    } else {
      days = await this.$serviceLocator
        .getService(ExcursionService)
        .getExcursionCalendar(filter);
    }

    days.forEach((iter) => {
      if (!!iter.count) {
        const formatedDate = iter.date.replace(/-0+/g, "-");
        this.markedDates.push({
          date: formatedDate,
          class: "vfc-calendar-day__selected",
        });
      }
    });
  }

  async seaTourCalendarOpened(args: any) {
    this.markedDates.splice(0, this.markedDates.length);
    const calDate = this.$refs.SeaTourCalendar.getCalendarDateFormatted();

    if (
      !this.baseFilterModel.from_city_id ||
      !this.baseFilterModel.to_city_id
    ) {
      return;
    }

    const filter = this.getCalendarFilterModelAsPojo(args || calDate);

    const days = this.isSeaTourType
      ? await this.$serviceLocator
          .getService(TourService)
          .getSeaTourCalendar(filter)
      : await this.$serviceLocator
          .getService(TourService)
          .getSeaTourCalendar(filter);

    days.forEach((iter) => {
      if (!!iter.count) {
        const formatedDate = iter.date.replace(/-0+/g, "-");
        this.markedDates.push({
          date: formatedDate,
          class: "vfc-calendar-day__selected",
        });
      }
    });
  }

  childChanged(
    childArray: {
      id: number;
      label: string;
    }[]
  ) {
    this.baseFilterModel.child_count = childArray.length;
    const agesArr = childArray.map((iter) => +iter.label.split(" ")[0]);
    this.baseFilterModel.child_ages = [...agesArr];
  }

  onFromCitiesSelected(result: CityModel) {
    this.baseFilterModel.from = result.name;
    this.baseFilterModel.from_city_id = result.id;

    this.fromCity = this.fromCity || new CityModel();
    this.fromCity.id = this.baseFilterModel.from_city_id;
    this.fromCity.name = this.baseFilterModel.from;
  }
  //

  //  Куда
  toCity: CityModel = new CityModel();
  toCities: CityModel[] = [];

  async findToCities(query: string) {
    if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(async () => {
      if (!!query) {
        this.toCities = await this.$serviceLocator
          .getService(CommonService)
          .getCities(query);
        if (this.$globalParams?.to && this.toCities?.length) {
          this.toCity = this.toCities[0];
          this.onToCitiesSelected(this.toCities[0]);
        }
      }
    }, 300);
  }

  onToCitiesSelected(result: CityModel) {
    this.baseFilterModel.direction = result.name;
    this.baseFilterModel.to_city_id = result.id;

    this.toCity = this.toCity || new CityModel();
    this.toCity.id = this.baseFilterModel.to_city_id;
    this.toCity.name = this.baseFilterModel.direction;
  }

  onDirectionSelect(result: any) {
    this.baseFilterModel.to_city_id = result.city_id;
    this.baseFilterModel.direction = result.name;
    if (Object.keys(result).includes("duration")) {
      this.selectedTourId = result.id;
    }

    this.toCity = this.toCity || new CityModel();
    this.toCity.id = this.baseFilterModel.to_city_id;
    this.toCity.name = this.baseFilterModel.direction;
    if (result.duration && result.duration > 0) {
      this.daysDuration = result.duration;
    }
    // Hotel
    if (result.groupId === 2) {
      this.baseFilterModel.name = result.name;
    }
    if (result.groupId === 1) {
      this.baseFilterModel.name = null;
    }
  }
  //

  optionsCountDays = [
    { id: 1, days: "Все" },
    { id: 2, days: "1-3" },
    { id: 3, days: "4-6" },
    { id: 4, days: "7-9" },
    { id: 5, days: "10-12" },
    { id: 6, days: "13-15" },
    { id: 7, days: "16-18" },
    { id: 8, days: "18-20" },
    { id: 9, days: "21-23" },
    { id: 10, days: "24-30" },
  ];

  // Для восстановления фильтра, например из URL
  @Watch("externalFilterModel", { immediate: true, deep: true })
  async fromEnabledsss() {
    await this.$nextTick();
    this.baseFilterModel = this.externalFilterModel ?? new BaseFilterModel();
    this.baseFilterModel = BaseViewModel.fromPlainIfNotClass(
      BaseFilterModel,
      this.baseFilterModel
    );

    this.daysRange = {
      dateRange: {
        // @ts-ignore
        start: this.$globalParams?.date_from || this.baseFilterModel?.date_from,
        // @ts-ignore
        end: this.$globalParams?.date_to || this.baseFilterModel?.date_to,
      },
    };

    if (this.isExcursionsType) {
      this.daysDuration =
        this.baseFilterModel?.duration_from +
        "-" +
        this.baseFilterModel?.duration_to;
      this.daysRange = {
        dateRange: {
          // @ts-ignore
          start:
            this.$globalParams?.date_from ||
            this.baseFilterModel?.start_date_from,
          // @ts-ignore
          end:
            this.$globalParams?.date_to || this.baseFilterModel?.start_date_to,
        },
      };
    }

    if (this.isHotelType) {
      this.daysRange = {
        dateRange: {
          // @ts-ignore
          start:
            this.$globalParams?.date_from || this.baseFilterModel?.date_from,
          // @ts-ignore
          end: this.$globalParams?.date_to || this.baseFilterModel?.date_to,
        },
      };
    }

    if (this.isSeaTourType) {
      this.daysRange = {
        dateRange: {
          // @ts-ignore
          start:
            this.$globalParams?.date_from || this.baseFilterModel?.start_date,
          // @ts-ignore
          end: this.$globalParams?.date_to || this.baseFilterModel?.finish_date,
        },
      };
    }

    if (this.isRouteType) {
      this.routeDaysRange = {
        dateRange: {
          // @ts-ignore
          start:
            this.$globalParams?.date_from || this.baseFilterModel?.start_date,
          // @ts-ignore
          end: this.$globalParams?.date_to || this.baseFilterModel?.finish_date,
        },
      };
    }

    this.fromCity.id = this.baseFilterModel.from_city_id;
    this.fromCity.name = this.baseFilterModel.from;
    this.toCity.id = this.baseFilterModel.to_city_id;
    this.toCity.name = this.baseFilterModel.direction;
  }

  @Watch("isDirection")
  onChangeCurrentTourTypes() {
    this.currentFilterTab();
  }

  @Watch("currentTourType")
  onChangeCurrentTourType() {
    // this.$v.baseFilterModel.$reset();
    this.setAutocompleteOff();
  }

  async setAutocompleteOff() {
    await this.$nextTick();
    await this.$nextTick();
    if (this.$refs.multiselectRef1) {
      this.$refs.multiselectRef1.$refs.search.setAttribute(
        "autocomplete",
        "off"
      );
    }
    if (this.$refs.multiselectRef2) {
      this.$refs.multiselectRef2.$refs.search.setAttribute(
        "autocomplete",
        "off"
      );
    }
  }

  get advancedFilterImageSrc() {
    return this.advancedFilterVisible
      ? this.$getImage("/icons/close_black.svg")
      : this.$getImage("/icons/filter_adv.svg");
  }

  get currentTourType(): TourType {
    return getModule(AppStore, this.$store).currentTourType;
  }

  get moreFiltersButtonVisible() {
    return (
      getModule(AppStore, this.$store).currentTourType === TourType.HOTEL ||
      getModule(AppStore, this.$store).currentTourType === TourType.SEA_TOUR
    );
  }

  get fromCaption() {
    return "Откуда";
  }

  get fromVisible() {
    return getModule(AppStore, this.$store).currentTourType !== TourType.HOTEL;
  }

  get directionCaption() {
    return this.isSeaTourType || this.isHotelType
      ? "Направление/Отель"
      : this.isExcursionsType
      ? "Направление/Тур"
      : "Куда";
  }

  get passangersCaption() {
    return getModule(AppStore, this.$store).currentTourType ===
      TourType.HOTEL || this.isCatalog
      ? "Туристы"
      : "Пассажиры";
  }

  get tourType() {
    return getModule(AppStore, this.$store).currentTourType;
  }

  get findButtonCaption() {
    switch (this.tourType) {
      case TourType.HOTEL: {
        return "Найти отель";
      }
      case TourType.SEA_TOUR: {
        return "Найти тур";
      }
      case TourType.EXCURSION: {
        return "Найти тур";
      }
      case TourType.ROUTE: {
        return "Найти";
      }
      case TourType.CATALOG_EXCURSION: {
        return "Найти";
      }
      case TourType.CATALOG_HOTEL_WITH_DIRECTION: {
        return "Найти";
      }
      case TourType.CATALOG_HOTEL_WITHOUT_DIRECTION: {
        return "Найти";
      }
    }
  }

  get isExcursionsType() {
    return TourType.EXCURSION === this.tourType;
  }

  get isHotelType() {
    return TourType.HOTEL === this.tourType;
  }

  get isSeaTourType() {
    return TourType.SEA_TOUR === this.tourType;
  }

  get isRouteType() {
    return TourType.ROUTE === this.tourType;
  }

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  currentFilterTab() {
    switch (this.$globalParams?.type) {
      case FilterTypes["DIRECTION"]:
        this.changeTourType(TourType.ROUTE);
        break;
      case FilterTypes["TOUR"]:
        this.changeTourType(TourType.SEA_TOUR);
        break;
      case FilterTypes["EXCURSION"]:
        this.changeTourType(TourType.EXCURSION);
        break;
      case FilterTypes["HOTEL"]:
        this.changeTourType(TourType.HOTEL);
        break;
      default:
        this.changeTourType(TourType.SEA_TOUR);
    }
  }

  @Validations()
  validations() {
    if (this.tourType && !this.isCatalog) {
      switch (this.tourType) {
        case TourType.HOTEL: {
          return {
            baseFilterModel: {
              direction: { required },
              date_from: { required, validFormat: this.validateDatesRule },
              date_to: { required, validFormat: this.validateDatesRule },
            },
          };
        }

        case TourType.SEA_TOUR: {
          return {
            baseFilterModel: {
              from: { required },
              direction: { required },
              start_date: { required, minLength: minLength(4) },
              finish_date: { required, minLength: minLength(4) },
            },
          };
        }
        case TourType.EXCURSION: {
          return {
            baseFilterModel: {
              from: { required },
              direction: { required },
              start_date_from: { required, minLength: minLength(4) },
              duration_from: { required, minLength: minValue(1) },
              duration_to: { required },
            },
          };
        }
        case TourType.ROUTE: {
          return {
            baseFilterModel: {
              from: { required, minLength: minLength(4) },
              direction: { required },
              start_date: { required },
            },
          };
        }
        case TourType.CATALOG_HOTEL_WITH_DIRECTION: {
          return {
            baseFilterModel: {
              from: { required },
              start_date_to: { required, minLength: minLength(4) },
              start_date_from: { required, minLength: minLength(4) },
              duration_from: { required, minLength: minValue(1) },
            },
          };
        }
        case TourType.CATALOG_HOTEL_WITHOUT_DIRECTION: {
          return {
            baseFilterModel: {
              start_date_to: { required, minLength: minLength(4) },
              start_date_from: { required, minLength: minLength(4) },
              duration_from: { required, minLength: minValue(1) },
            },
          };
        }
        case TourType.CATALOG_EXCURSION: {
          return {
            baseFilterModel: {
              start_date_to: { required, minLength: minLength(4) },
              start_date_from: { required, minLength: minLength(4) },
              duration_from: { required, minLength: minValue(1) },
            },
          };
        }
        default:
          return {
            baseFilterModel: {
              from: { required, minLength: minLength(4) },
              direction: { required },
              date_from: { required },
              date_to: { required },
            },
          };
      }
    } else {
      switch (this.isDirection) {
        case true: {
          return {
            baseFilterModel: {
              from: { required },
              start_date_to: { required, minLength: minLength(4) },
              start_date_from: { required, minLength: minLength(4) },
              duration_from: { required, minLength: minValue(1) },
            },
          };
        }
        case false: {
          return {
            baseFilterModel: {
              start_date_to: { required, minLength: minLength(4) },
              start_date_from: { required, minLength: minLength(4) },
              duration_from: { required, minLength: minValue(1) },
            },
          };
        }
      }
    }
  }

  validateDatesRule(_val: any) {
    if (!!this.baseFilterModel.date_to && !!this.baseFilterModel.date_from) {
      // return (
      //   dayjs(this.$v.baseFilterModel.date_to?.$model).diff(
      //     this.$v.baseFilterModel.date_from?.$model,
      //     "day"
      //   ) > 0
      // );
      return true;
    } else {
      return false;
    }
  }

  isActiveMenuItem(tourType: TourType) {
    return tourType === this.currentTourType;
  }

  changeTourType(tourType: TourType) {
    getModule(AppStore, this.$store).updateCurrentTourType(tourType);
  }

  toggleAdvFilter() {
    this.advancedFilterVisible = !this.advancedFilterVisible;
  }

  // При изменении расширенного фильтра, смержим параметры
  async onAdvancedFilterChanged(extFilterModel: BaseFilterModel) {
    await this.$nextTick();

    const mergedPojo = { ...this.baseFilterModel, ...extFilterModel };
    this.baseFilterModel = BaseViewModel.fromPlainIfNotClass(
      BaseFilterModel,
      mergedPojo
    );
  }

  search() {
    let routeName = "main";
    if (this.tourType === TourType.EXCURSION) {
      this.baseFilterModel.start_date_from = this.daysRange?.dateRange?.start;
      if (this.daysRange?.dateRange?.end) {
        this.baseFilterModel.start_date_to = this.daysRange?.dateRange?.end;
      } else {
        this.baseFilterModel.start_date_to = this.daysRange?.dateRange?.start;
      }
      if (typeof this.daysDuration === "number") {
        this.baseFilterModel.duration = this.daysDuration;
      } else {
        const arr = this.daysDuration.split("-");
        this.baseFilterModel.duration_from = +arr[0];
        this.baseFilterModel.duration_to = +arr[1];
      }
    }

    if (this.tourType === TourType.HOTEL) {
      this.baseFilterModel.date_from = this.daysRange?.dateRange?.start;
      this.baseFilterModel.date_to = this.daysRange?.dateRange?.end;
      this.baseFilterModel.from_city_id = 0;
      this.baseFilterModel.from = "";
    }
    if (
      this.tourType === TourType.CATALOG_HOTEL_WITHOUT_DIRECTION ||
      !this.isDirection
    ) {
      this.baseFilterModel.date_from = this.daysRange?.dateRange?.start;
      this.baseFilterModel.date_to = this.daysRange?.dateRange?.end;
      this.baseFilterModel.direction = this.direction;
    }
    if (this.tourType === TourType.CATALOG_EXCURSION) {
      this.baseFilterModel.direction = this.direction;
      this.baseFilterModel.start_date_from = this.daysRange?.dateRange?.start;
      this.baseFilterModel.start_date_to = this.daysRange?.dateRange?.end;
      this.baseFilterModel.date_from = this.daysRange?.dateRange?.start;
      this.baseFilterModel.date_to = this.daysRange?.dateRange?.end;
    }
    if (
      this.tourType === TourType.CATALOG_HOTEL_WITH_DIRECTION ||
      (this.isDirection && this.isCatalog)
    ) {
      this.baseFilterModel.direction = this.direction;
      this.baseFilterModel.start_date = this.daysRange?.dateRange?.start;
      this.baseFilterModel.start_date_to = this.daysRange?.dateRange?.end;
      this.baseFilterModel.date_from = this.daysRange?.dateRange?.start;
      this.baseFilterModel.date_from = this.daysRange?.dateRange?.start;
      this.baseFilterModel.date_to = this.daysRange?.dateRange?.end;
      this.baseFilterModel.finish_date = this.daysRange?.dateRange?.end;
    }

    if (this.tourType === TourType.SEA_TOUR) {
      this.baseFilterModel.start_date = this.daysRange?.dateRange?.start;
      this.baseFilterModel.finish_date = this.daysRange?.dateRange?.end;
    }

    if (this.tourType === TourType.ROUTE) {
      this.baseFilterModel.start_date = this.routeDaysRange?.dateRange?.start;
      this.baseFilterModel.finish_date = this.routeDaysRange?.dateRange?.end;
    }

    this.$v.$touch();
    if (this.$v.$invalid) {
      return false;
    } else {
      if (this.tourType && !this.isCatalog) {
        switch (this.tourType) {
          case TourType.HOTEL:
            routeName = "hotelsList";
            break;
          case TourType.ROUTE:
            routeName = "directionsList";
            break;
          case TourType.SEA_TOUR:
            routeName = "toursList";
            break;
          case TourType.EXCURSION:
            routeName = "excursionsList";
            break;
        }
      }

      if (this.baseFilterModel.child_count === 0) {
        this.baseFilterModel.child_ages = [];
      }

      if (this.currentRoute.name === routeName) {
        this.$emit("search-start", this.tourType, this.baseFilterModel);
      } else {
        const filterStr = this.baseFilterModel.cloneAndRemoveEmptyValue([
          "child_count",
        ]);
        if (this.city_prop) {
          filterStr.to_city_id = this.city_prop;
        }
        if (this.selectedTourId || this.hotelId) {
          filterStr.hotel_id = this.selectedTourId || this.hotelId;
        }
        getModule(AppStore, this.$store).setRoute({
          name: routeName,
          query: filterStr,
          params: {},
        });

        getModule(AppStore, this.$store).setRoute({
          name: this.currentRoute.name,
          query: filterStr,
          params: this.currentRoute.params,
        });
      }
    }
  }
}
