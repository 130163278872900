import dayjs from "dayjs";
import { stringify } from "query-string";
import { PaginationCollection } from "@/api/PaginationCollection";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { Pagination } from "@/models/core/Pagination";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { BaseService } from "@/services/BaseService";
import {
  ExcursionModel,
  ExcursionTransportSeat,
} from "./models/ExcursionModel";
import { ExcursionCategoryModel } from "./models/ExcursionCategoryModel";

export class ExcursionService extends BaseService {
  public searchByName(name: string) {
    return this.getArrayOrEmpty(
      ExcursionModel,
      `search-module/tours/excursion/search-by-name?name=${name}`
    );
  }

  public async findhExcursions(
    queryObject: any,
    pagination: Pagination
  ): Promise<PaginationCollection<ExcursionModel>> {
    return await this.getArrayOrEmptyWithPaginationGet(
      ExcursionModel,
      `search-module/tours/excursion/search/?${stringify(queryObject, {
        arrayFormat: "bracket",
      })}`,
      {},
      pagination
    );
  }

  public getChosenSeats(seatsInfo: ExcursionTransportSeat, tourists: any[]) {
    if (seatsInfo?.seats) {
      const filtered = Array.from<ExcursionTransportSeat>(seatsInfo.seats).filter((seat: ExcursionTransportSeat) => {
        return tourists.findIndex((item) => item.id === seat.tourist_id) > -1;
      });
      return filtered.map((seat: ExcursionTransportSeat) => seat.number);
    }
    return [];
  }

  public async getExcursion(
    slug: string,
    queryObject: any
  ): Promise<ExcursionModel> {
    const url = `search-module/tours/excursion/${this.getIdBySlug(
      slug
    )}?${stringify(queryObject, { arrayFormat: "bracket" })}`;
    return await this.getOneOrFail(ExcursionModel, url);
  }

  public async getExcursionCalendar(
    queryObject: any
  ): Promise<{ date: string; count: number }[]> {
    const url = `search-module/tours/excursion/calendar?${stringify(
      queryObject,
      { arrayFormat: "bracket" }
    )}`;
    return (await this.getArrayOrEmpty(BaseViewModel, url)) as any;
  }

  async getExcursionsCategories(): Promise<ExcursionCategoryModel[]> {
    return await this.getArrayOrEmpty(
      ExcursionCategoryModel,
      "search-module/tours/excursion/categories/list"
    );
  }

  public async getTourServices() {
    return await this.apiRequest.getJSON("front/tours/excursion/59/services");
  }

  public async getByIdList(
    idList: number[],
    queryObject: any
  ): Promise<ExcursionModel[]> {
    const params = { ids: Array.from(new Set(idList)), ...queryObject };
    if (!!idList && Array.isArray(idList) && idList.length > 0) {
      return await this.getArrayOrEmpty(
        ExcursionModel,
        `search-module/tours/excursion/collect-by-ids?${stringify(params, {
          arrayFormat: "bracket",
        })}`
      );
    }
    return [];
  }

  public getDefaultFilterParams() {
    const filterModel = new BaseFilterModel();

    filterModel.from_city_id = 1105;
    filterModel.from = "Москва";
    filterModel.to_city_id = 27;
    filterModel.direction = "Анапа";

    filterModel.start_date_from = dayjs().format("YYYY-MM-DD");
    filterModel.start_date_to = dayjs().add(10, "day").format("YYYY-MM-DD");
    filterModel.duration_from = 7;
    filterModel.duration_to = 9;
    filterModel.adult_count = 1;
    filterModel.child_count = 0;
    return filterModel.cloneAndRemoveEmptyValue(["child_count"]);
  }

  public getOccupiedSeats(seatsInfo: ExcursionTransportSeat) {
    if (seatsInfo?.seats) {
      return Array.from<ExcursionTransportSeat>(seatsInfo.seats).map(
        (seat: ExcursionTransportSeat) => seat.number
      );
    }
    return [];
  }

  public async getExcursionTransportInfo(
    id: number,
    fromCityId: number,
    toCityId: number,
    date: string
  ) {
    return await this.getOneOrEmpty(
      ExcursionTransportSeat,
      `search-module/transports/${id}?date=${date}&from_city_id=${fromCityId}&to_city_id=${toCityId}`
    );
  }
}
