
import { Vue, Component } from "vue-property-decorator";
import { AgencyService } from "./AgencyService";
import EmployeeEditForm from "./EmployeeEditForm.vue";
import { EmployeeModel } from "./models/EmployeeModel";

@Component({
  inheritAttrs: false,
})
export default class EmployeeSearchAutocomplete extends Vue {
  model: EmployeeModel = new EmployeeModel();

  employeeList: EmployeeModel[] = [];

  async mounted() {
    await this.updateData();
  }

  async updateData(selectModel?: EmployeeModel) {
    this.employeeList = await this.$serviceLocator.getService(AgencyService).getFullEmployeeList();
    if (this.employeeList.length > 0) {
      this.model = selectModel || this.employeeList[0];
      this.$emit("on-select", selectModel || this.model);
    }
  }

  onSelect(result: EmployeeModel) {
    this.$emit("on-select", result);
  }

  async addNewEmpl() {
    const model = new EmployeeModel();
    const res = await this.$modalManager.modalShow(
      EmployeeEditForm,
      { model },
      { clickToClose: true, classes: "v--modal w-45vw m-auto left-0" }
    );
    if (res) {
      await this.$serviceLocator.getService(AgencyService).addEmployee(model);
      await this.updateData(model);
    }
  }
}
