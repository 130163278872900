import { SwiperOptions } from "swiper/types/swiper-options";

const settings: { [key: string]: SwiperOptions } = {
  "pagination-navigation": {
    speed: 800,
    pagination: {
      el: ".Swiper__pagination",
      clickable: true,
      type: "bullets",
    },
    navigation: {
      nextEl: ".Swiper__next",
      prevEl: ".Swiper__prev",
    },
    grabCursor: true,
  },
  pagination: {
    speed: 1000,
    navigation: {
      nextEl: ".Swiper__next",
      prevEl: ".Swiper__prev",
    },
    grabCursor: true,
  },
  "pagination-bottom": {
    speed: 1000,
    navigation: {
      nextEl: ".Swiper__next",
      prevEl: ".Swiper__prev",
    },
    spaceBetween: 6,
    grabCursor: true,
  },
};

export default settings;
