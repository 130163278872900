
import { Vue, Component, Prop } from "vue-property-decorator";
import { ExcursionModel } from "@/modules/Excursions/models/ExcursionModel";
import { formatDate } from "@/utils/Formaters";
import { RouteLink } from "@/models/core/RouteLink";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import ExcursionFavoritesAndShownService from "../ExcursionFavoritesAndShownService";
import { getModule } from "vuex-module-decorators";
import AppStore from "@/store/AppStore";
import { Calc } from "@/utils/CalcDiscount";
import { DirectionsCardModel } from "@/modules/Directions/models/DirectionsCardModel";
import ChooseSeatsModalExcursion from "@/components/ChooseSeatsModalExcursion.vue";

@Component({ components: { ChooseSeatsModalExcursion } })
export default class ExcursionListItem extends Vue {
  @Prop()
  item: ExcursionModel;

  @Prop()
  additionalMenuItems: RouteLink[];

  @Prop({ default: false })
  isFromSelecton: boolean;

  @Prop()
  detailPageRouteQuery: any;

  fromSelectedDirection: DirectionsCardModel = new DirectionsCardModel();
  backSelectedDirection: DirectionsCardModel = new DirectionsCardModel();

  isChoosePlaceModal: boolean = false;
  isBack: boolean = false;

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  get selectedDirection() {
    return this.isBack ? this.item?.transport_back : this.item.transport_forth;
  }

  get isOneWay() {
    return (
      this.currentRoute.query?.date_from === this.currentRoute.query?.date_to ||
      !this.currentRoute.query?.date_to
    );
  }

  get pageRouteQuery() {
    return this.detailPageRouteQuery ?? this.currentRoute.query;
  }

  get DiscountSum() {
    return Calc(this.item.price_from);
  }

  get visibleBtn() {
    return this.item?.transport_back && this.item.transport_forth;
  }

  get fromCityId() {
    return this.isBack
      ? this.item?.transport_back?.routes?.start?.point_city?.id
      : this.item?.transport_forth?.routes?.start?.point_city?.id;
  }

  get toCityId() {
    return this.isBack
      ? this.item?.transport_back?.routes?.finish?.point_city?.id
      : this.item?.transport_forth?.routes?.finish?.point_city?.id;
  }

  openBusModal() {
    this.isChoosePlaceModal = true;
  }

  toogleFavorites() {
    // if (!this.$serviceLocator.getService(AuthService).isUserAuthorized) {
    //   return this.$notify.warning("Пожалуйста, авторизуйтесь");
    // }
    const filterModel = BaseViewModel.fromPlainIfNotClass(
      BaseFilterModel,
      this.currentRoute.query
    );
    this.$serviceLocator
      .getService(ExcursionFavoritesAndShownService)
      .toogleFavor(this.item, filterModel);
  }

  clickRemoveItem() {
    this.$emit("on-remove-from-favor", this.item);
  }

  get duration() {
    return `${this.item.duration?.days}, ${this.item.duration?.nights}`;
  }

  goToCard() {
    getModule(AppStore, this.$store).setRoute({
      name: "excursion-card",
      params: { slug: `${this.item.id}` },
      query: this.pageRouteQuery,
    });
  }

  convertDate(date: string) {
    return formatDate(date);
  }
}
