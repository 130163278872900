
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import TheContainer from "@/components/TheContainer.vue";
// import BaseCarousel from "@/components/base/BaseCarousel.vue";
import BaseModalGallery from "@/components/BaseModalGallery.vue";
import HotelInfrastructure from "@/modules/Hotels/components/HotelInfrastructure.vue";
import HotelRooms from "@/modules/Hotels/components/HotelRooms.vue";
import HotelSlider from "@/modules/Hotels/components/HotelSlider.vue";
import ModalReserveAutobus from "@/modules/Hotels/components/ModalReserveAutobus.vue";
import ModalChangeParams from "@/modules/Hotels/components/ModalChangeParams.vue";
import ModalHotelMap from "@/modules/Hotels/components/ModalHotelMap.vue";
import ModalShare from "@/modules/Hotels/components/ModalShare.vue";
import HotelPlaces from "@/modules/Hotels/components/HotelPlaces.vue";
import AppStore from "@/store/AppStore";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { HeadMetaTagsBuilder } from "@/utils/HeadMetaTagsBuilder";
import ShareSocial from "@/components/ShareSocial.vue";
import { decOfNum } from "@/utils/DecOfNum";
import { formatDateStandart } from "@/utils/Formaters";
import { slugSplit } from "@/utils/SlugSplit";
import { ApplicationService } from "../Order/ApplicationService";
import { PhotoModel, RoomModel } from "./models/RoomModel";
import { HotelService } from "./HotelService";
import { HotelModel } from "./models/HotelModel";
import HotelFavoritesAndShownService from "./HotelFavoritesAndShownService";
import { getModule } from "vuex-module-decorators";
import { Notification } from "element-ui";

@Component({
  components: {
    ModalHotelMap,
    ModalShare,
    // BaseCarousel,
    BaseModalGallery,
    TheContainer,
    HotelRooms,
    HotelInfrastructure,
    HotelSlider,
    ModalReserveAutobus,
    ModalChangeParams,
    HotelPlaces,
    ShareSocial,
  },
})
export default class HotelCardPage extends Vue {
  @Prop()
  slug: string;

  descrFull = false;

  roomsCountToShow = 5;
  hotelModel: HotelModel = new HotelModel();
  showModal = false;
  showModalBus = false;
  showModalParams = false;
  showModalMap = false;
  showModalShare = false;
  galleryImages: PhotoModel[] = [];
  filterModel: BaseFilterModel = new BaseFilterModel();
  selectedRoom: RoomModel = new RoomModel();
  rooms: RoomModel[] = [];

  get routeQueryParams() {
    return Object.keys(getModule(AppStore, this.$store).currentRoute.query)
      .length === 0
      ? this.$serviceLocator.getService(HotelService).getDefaultFilterParams()
      : getModule(AppStore, this.$store).currentRoute.query;
  }

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  // @Watch("hotelModel.id", { immediate: true })
  // changeSlug() {
  //   const slug = getModule(AppStore, this.$store).currentRoute.params.slug.replace(`-${this.hotelModel.id}`, "");
  //   if (this.hotelModel.slug && this.hotelModel.slug !== slug) {
  //     getModule(AppStore, this.$store).setRoute({
  //       name: 'hotel-card' || "",
  //       query: getModule(AppStore, this.$store).currentRoute.query,
  //       params: { slug: `${this.hotelModel.slug}-${this.hotelModel.id}` },
  //     });
  //   }
  // }

  async updateData() {
    const id = getModule(AppStore, this.$store).currentRoute.params?.slug;
    this.hotelModel = await this.$serviceLocator
      .getService(HotelService)
      .getHotel(id, this.routeQueryParams);
    this.rooms = this.hotelModel.rooms?.slice(0, this.roomsCountToShow);
  }

  async mounted() {
    if (Object.keys(this.currentRoute.query).length === 0) {
      const defFolterPojo = this.$serviceLocator
        .getService(HotelService)
        .getDefaultFilterParams();
      getModule(AppStore, this.$store).setRoute({
        name: "hotel-card",
        query: defFolterPojo,
        params: getModule(AppStore, this.$store).currentRoute.params,
      });
    }
    try {
      this.filterModel = BaseViewModel.fromPlainIfNotClass(
        BaseFilterModel,
        this.routeQueryParams
      );
      await this.updateData();
      this.loadBreadCrumbList();

      await this.updateFovoritesAndShowed();
    } catch (err: any) {
      throw err;
    }
  }

  head() {
    return HeadMetaTagsBuilder.createHead();
  }

  showMoreRooms() {
    this.rooms = this.hotelModel.rooms?.slice(
      0,
      this.rooms.length + this.roomsCountToShow
    );
  }

  toBook(room: RoomModel) {
    this.selectedRoom = room;
    this.bookBusModalClosed();
  }

  loadGallery(images: PhotoModel[]) {
    this.galleryImages = images;
    this.showModal = true;
  }

  toogleFavorites() {
    this.$serviceLocator
      .getService(HotelFavoritesAndShownService)
      .toogleFavor(this.hotelModel, this.filterModel);
  }

  async updateFovoritesAndShowed() {
    const ids = this.$serviceLocator
      .getService(HotelFavoritesAndShownService)
      .getShowedIdList([this.hotelModel?.id]);
    this.$serviceLocator
      .getService(HotelFavoritesAndShownService)
      .updateFavorStatus([this.hotelModel]);
    this.$serviceLocator
      .getService(HotelFavoritesAndShownService)
      .addToShown(this.hotelModel, this.routeQueryParams);
  }

  loadBreadCrumbList() {
    const hotelName = this.hotelModel.name;
    const direction = this.hotelModel.city && this.hotelModel.city.name;
    const query = getModule(AppStore, this.$store).currentRoute.query;

    const breadCrumbList = [
      { title: "Отели", routeName: "hotelsList", query },
      { title: hotelName },
    ];

    getModule(AppStore, this.$store).updateBreadCrumbList(breadCrumbList);
  }

  async onChangeParams(newFilter: BaseFilterModel) {
    this.filterModel.date_from = newFilter.date_from;
    this.filterModel.date_to = newFilter.date_to;
    this.filterModel.adult_count = newFilter.adult_count;
    this.filterModel.child_count = newFilter.child_count;
    this.filterModel.child_ages = [...newFilter.child_ages];

    await this.updateRouteQuery();
    this.updateData();
    this.showModalParams = false;
  }

  updateRouteQuery() {
    this.filterModel = BaseFilterModel.fromPlainIfNotClass(
      BaseFilterModel,
      this.filterModel
    );
    const filterModelAsPojo = this.filterModel.cloneAndRemoveEmptyValue([
      "child_count",
    ]);
    return getModule(AppStore, this.$store).setRoute({
      name: "hotel-card",
      query: filterModelAsPojo,
      params: getModule(AppStore, this.$store).currentRoute.params,
    });
  }

  bookBus(isBook: boolean) {
    if (isBook) {
      // window.open(routeData.href, "_blank");
    }
  }

  async bookBusModalClosed() {
    getModule(AppStore, this.$store).setOldRoute({
      ...this.currentRoute,
      title: this.hotelModel.name,
    });
    this.showModalBus = false;
    try {
      const result = await this.$serviceLocator
        .getService(ApplicationService)
        .addDraftHotelApplication(
          this.filterModel.child_ages,
          this.hotelModel,
          this.selectedRoom
        );

      getModule(AppStore, this.$store).setRoute({
        name: "order",
        params: { id: result.data.id },
        query: this.currentRoute.query,
      });
    } catch (e: any) {
      const err = e.data?.message;
      Notification.warning({
        title: "Ошибка",
        message: err[Object.keys(err)?.[0]]?.[0] || "",
      });
    }
  }

  get duration() {
    return !!this.hotelModel.rooms && this.hotelModel.rooms.length > 0
      ? this.hotelModel.rooms[0].duration?.days +
          ", " +
          this.hotelModel.rooms[0].duration?.nights
      : "нет";
  }

  get durationNights() {
    return !!this.hotelModel.rooms && this.hotelModel.rooms.length > 0
      ? this.hotelModel.rooms[0].duration?.nights
      : "нет";
  }

  get people() {
    return !!this.hotelModel.rooms && this.hotelModel.rooms.length > 0
      ? this.hotelModel.rooms[0].people?.total
      : !!this.filterModel
      ? +this.filterModel.adult_count +
        +this.filterModel.child_count +
        " " +
        decOfNum(
          +this.filterModel.adult_count + +this.filterModel.child_count,
          ["человек", "человека", "человек"]
        )
      : "нет";
  }

  get arrivalTime() {
    return this.hotelModel.arrival_time || "не указан";
  }

  get leaveTime() {
    return this.hotelModel.departure_time || "не указано";
  }

  get dateFrom() {
    return !!this.hotelModel.rooms && this.hotelModel.rooms.length > 0
      ? this.hotelModel.rooms[0].from_date
      : "";
  }

  get dateTo() {
    return !!this.hotelModel.rooms && this.hotelModel.rooms.length > 0
      ? this.hotelModel.rooms[0].to_date
      : "";
  }

  formatDateStandart(date) {
    return formatDateStandart(date);
  }
}
