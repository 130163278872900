
import { Component, Prop, Vue } from "vue-property-decorator";
import { MealModel } from "@/modules/Hotels/models/MealModel";
import { ServiceInHotel } from "@/modules/Hotels/models/ServiceInHotel";

type InputType = "checkbox" | "radio";

@Component
export default class FilterGroup extends Vue {
  // currentModel = null;
  isItemsVisible = false;

  @Prop({ default: "checkbox" })
  typeInput: InputType;

  @Prop()
  value: any;

  @Prop({ default: "" })
  title: string;

  @Prop({ required: true })
  name: string;

  @Prop({ default: "Показать еще" })
  textShow: string;

  @Prop({ default: "Скрыть" })
  textHide: string;

  @Prop({ default: "name" })
  textLabel: string;

  @Prop({ default: "id" })
  inputVal: string;

  @Prop({ default: 4 })
  limitItem: number;

  @Prop({ default: true })
  isLimit: boolean;

  @Prop({ required: true })
  items: MealModel[] | ServiceInHotel[];

  get getItems() {
    return this.items;
  }

  get currentModel() {
    return this.value;
  }

  set currentModel(newVal: any) {
    this.$emit("input", newVal);
  }

  current(id: number | string) {
    if (typeof this.value === "number" || typeof this.value === "string") {
      return this.value === id;
    } else {
      return this.value.includes(id);
    }
  }
}
