
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ShareSocial extends Vue {
  @Prop()
  networkList: string[];

  @Prop()
  url: string;

  @Prop()
  title: string;

  @Prop()
  description: string;
}
