
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseLoader from "@/components/BaseLoader.vue";

type ButtonType = "button" | "submit";

@Component({
  components: { BaseLoader },
})
export default class AvButton extends Vue {
  @Prop({ default: "button" })
  type: ButtonType;

  @Prop({ default: false })
  loading: boolean;

  @Prop({ default: 25 })
  loaderSize: number;

  @Prop()
  closeIcon: boolean;

  @Prop({ default: false })
  blue: boolean;

  @Prop({ default: false })
  gray: boolean;

  @Prop({ default: false })
  transparent: boolean;

  @Prop({ default: false })
  dark: boolean;

  @Prop({ default: false })
  fluid: boolean;

  @Prop({ default: false })
  large: boolean;

  @Prop({ default: false })
  medium: boolean;

  @Prop({ default: false })
  small: boolean;

  @Prop({ default: false })
  outline: boolean;

  @Prop()
  classes: string;

  get isDisabled() {
    return "disabled" in this.$attrs || this.loading;
  }
}
