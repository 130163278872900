
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import { DirectionService } from "@/modules/Directions/DirectionService";
import { BaseDaysRangeModel, BaseFilterModel } from "@/models/BaseFilterModel";

@Component
export default class DirectionCalendar extends Vue {
  $refs!: {
    DirectionCalendar: any;
  };

  @Prop()
  filterModel: BaseFilterModel;

  @Prop()
  isCatalog: boolean;

  @Prop()
  value: BaseDaysRangeModel;

  @Watch("value", { immediate: true, deep: true })
  async onDaysRangeChanged() {
    await this.$nextTick();
    this.startDay = this.value?.dateRange.start;
    this.endDay = this.value?.dateRange.end;
  }

  innerValueModel: any = {};
  dayClickedCount = 0;
  startDay: string | undefined = "";
  endDay: string | undefined = "";

  calendarConfig = {
    isMultiple: true,
    calendarsCount: 2,
    isDateRange: false,
    isDatePicker: true,
    placeholder: "Выберите даты",
    isAutoCloseable: false,
    markedDates: [],
  };

  daysPrice: { date: string; price: number }[] = [];

  opened() {
    this.dayClickedCount = 0;
    this.endDay = "";
    this.startDay = "";

    const calDate = this.$refs.DirectionCalendar.getCalendarDateFormatted();
    // const calJsDate = this.$refs.DirectionCalendar.getCalendarDateJsFormatted();

    this.innerValueModel.selectedDate = "";

    this.datesChanged(calDate);
  }

  datesChanged(date: string) {
    this.updatePrices(date);
  }

  dayClicked(day) {
    const dateArr = day?.date?.split("-").reverse();
    const dayNum = dateArr?.[0]?.length < 2 ? `0${dateArr[0]}` : dateArr[0];
    const month = dateArr?.[1]?.length < 2 ? `0${dateArr[1]}` : dateArr[1];
    const year = dateArr[2];
    const dateItem = new Date(`${year}-${month}-${dayNum}`).setDate(
      new Date(`${year}-${month}-${dayNum}`).getDate() + 1
    );
    const isValid = dayjs(new Date()).isSameOrBefore(dayjs(dateItem));
    if (!isValid) {
      return;
    }
    this.startDay = this.dayClickedCount === 0 ? day.date : this.startDay;
    this.dayClickedCount = this.dayClickedCount + 1;

    if (this.dayClickedCount === 2) {
      this.endDay = day.date;
    }
    this.emitInput();
    this.updatePrices(day.date);

    if (this.dayClickedCount === 2) {
      this.emitAndClose();
    }
  }

  async updatePrices(date: string) {
    this.calendarConfig?.markedDates?.splice(
      0,
      this.calendarConfig?.markedDates?.length
    );
    this.daysPrice.splice(0, this.daysPrice.length);

    if (
      !!this.filterModel &&
      !!this.filterModel.from_city_id &&
      !!this.filterModel.to_city_id
    ) {
      const filterModelAsPojo = this.filterModel.cloneAndRemoveEmptyValue([
        "child_count",
      ]);
      const start = dayjs(date).format("YYYY-MM-DD");
      const end = dayjs(date).add(60, "day").format("YYYY-MM-DD");
      filterModelAsPojo.start_date = start;
      filterModelAsPojo.finish_date = end;

      if (this.dayClickedCount > 0) {
        const from = this.filterModel.from_city_id;
        filterModelAsPojo.from_city_id = this.filterModel.to_city_id;
        filterModelAsPojo.to_city_id = from;
      }

      const days = await this.$serviceLocator
        .getService(DirectionService)
        .getDirectionCalendar(filterModelAsPojo);

      days.forEach((iter) => {
        if (!!iter.price) {
          const formatedDate = iter.date.replace(/-0+/g, "-");
          (this.calendarConfig.markedDates as any).push({
            date: formatedDate,
            class: "vfc-calendar-day__selected",
          });
          this.daysPrice.push({ date: formatedDate, price: iter.price });
        }
      });
    }
  }

  returnDontNeed() {
    this.endDay = "";
    this.dayClickedCount = 0;
    this.emitAndClose();
  }

  emitAndClose() {
    this.emitInput();
    this.$refs.DirectionCalendar.close();
  }

  emitInput() {
    const emitVal = new BaseDaysRangeModel();
    emitVal.dateRange = {
      start: this.startDay as any,
      end: this.endDay as any,
    };
    this.$emit("input", emitVal);
  }

  getPriceByDate(date: any) {
    const result = this.daysPrice.find((iter) => iter.date === date.date);
    return result?.price?.toLocaleString("ru-RU");
  }

  get selectCaption() {
    return this.dayClickedCount === 0
      ? "Выберите дату отправления"
      : "Выберите дату возвращения";
  }

  get formatDateRange() {
    return `${
      !!this.startDay ? dayjs(this.startDay).format("DD.MM.YYYY") : ""
    } ${!!this.startDay && !!this.endDay ? "~" : ""} ${
      !!this.endDay ? dayjs(this.endDay).format("DD.MM.YYYY") : ""
    }`;
  }
}
