
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";

@Component({
  inheritAttrs: false,
})
export default class BaseInputMask extends Vue {
  @Ref("phoneFiled") readonly phoneFiled!: HTMLInputElement | any;

  @Prop({ default: false })
  isShakeError: boolean;

  @Prop()
  label: string;

  @Prop({ default: () => uuidv4() })
  id: string;

  @Prop({ default: "" })
  mask;

  @Prop()
  value;

  @Prop()
  help: string;

  @Prop()
  classes: string;

  @Prop()
  errorText: string;

  @Prop({ default: false })
  hasError: boolean;

  get currentClasses() {
    return {
      "Field__input--error": this.hasError,
      "Field__input--help": !!this.help,
    };
  }

  mounted() {
    if (this.value) {
      this.updateInput(this.value);
    }
  }

  onDelete(arg) {
    this.$emit("input", arg?.maskRef?._unmaskedValue);
  }

  onComplete({ detail }) {
    this.$emit("input", detail.unmaskedValue);
  }

  /**
   * Обновляет значение поля Input через Imask
   */
  private updateInput(val) {
    if (this.$refs.phoneFiled && !!this.phoneFiled.maskRef) {
      this.phoneFiled.maskRef.typedValue = String(val);
    }
  }
}
