
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { TourServicesModel } from "../models/TourServicesModel";
import { stringToNumber } from "@/utils/StringToNumber";

@Component
export default class TourServices extends Vue {
  isActive = false;
  value = 0;
  minCount = 1;

  @Prop({ required: true })
  index: number;

  @Prop({ default: "service" })
  inputName: string;

  @Prop({ required: true })
  service: TourServicesModel;

  @Watch("isActive")
  changeIsActive(val: boolean) {
    if (!val) {
      this.$emit("add-service", this.service.id);
      this.value = 0;
      this.minCount = 1;
    } else {
      this.value = 1;
    }
  }

  @Watch("value")
  changeValue(val: number) {
    if (val > this.minCount || val !== 0) {
      this.minCount = val;
      this.$emit("add-service", {
        ...this.service,
        count: this.value,
      });
      this.isActive = true;
    } else {
      this.isActive = false;
      this.$emit("add-service", this.service.id);
    }
  }

  get price() {
    return stringToNumber(this.service.sum);
  }
}
