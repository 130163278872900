
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import HotelListItem from "@/modules/Hotels/components/HotelListItem.vue";
import { HotelModel } from "@/modules/Hotels/models/HotelModel";
import { decOfNum } from "@/utils/DecOfNum";
import HotelFavoritesAndShownService from "../HotelFavoritesAndShownService";

@Component({ components: { HotelListItem } })
export default class HotelList extends Vue {
  sort: number | null = null;

  @Prop()
  hotelList: HotelModel[];

  @Prop()
  loadingState: boolean;

  @Prop({ default: false })
  showMoreEnabled: boolean;

  @Watch("hotelList", { immediate: true })
  onListChanged() {
    this.updateFavorites();
  }

  optionsSelect = [
    { id: 1, label: "По рейтингу (по возрастанию)" },
    { id: 2, label: "По рейтингу (по убыванию)" },
    { id: 3, label: "По цене (по возрастанию)" },
    { id: 4, label: "По цене (по убыванию)" },
  ];

  updateFavorites() {
    this.$serviceLocator
      .getService(HotelFavoritesAndShownService)
      .updateFavorStatus(this.hotelList);
  }

  generateText(count: number) {
    return (
      count +
      " " +
      decOfNum(count, ["предложение", "предложения", "предложений"])
    );
  }

  onSortModeChanged(mode) {
    this.$emit("on-sort-mode-changed", mode);
  }
}
