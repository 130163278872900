import { TouristFoldersService } from "../Tourist/TouristFoldersService";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { FovoritesSupport } from "@/models/common/FovoritesSupport";
import { ServiceLocator } from "@/ServiceLocator";
import BaseFavoritesAndShownService from "@/services/BaseFavoritesAndShownService";

export default class ExcursionFavoritesAndShownService extends BaseFavoritesAndShownService {
  async toogleFavorites(item: FovoritesSupport, filterModelForPayLoad: BaseFilterModel) {
    return await ServiceLocator.instance.getService(TouristFoldersService).addToMainFolder(item.id, filterModelForPayLoad);
  }
}
