
import { Vue, Component, Prop } from "vue-property-decorator";
import { BaseServiceModel } from "@/models/BaseServiceModel";

@Component
export default class BaseService extends Vue {
  @Prop({ default: "/icons/hotel/"})
  pathToIcon: string;

  @Prop({ default: ".svg" })
  ext: string;

  @Prop({ default: false })
  isName: boolean;

  @Prop({ default: false })
  small: boolean;

  @Prop({ default: true })
  isTooltip: boolean;

  @Prop({ default: false })
  smallName: boolean;

  @Prop()
  service: BaseServiceModel;
}
