
import { Vue, Component, Prop } from "vue-property-decorator";
import { RoomModel } from "../models/RoomModel";
import { decOfNum } from "@/utils/DecOfNum";
import { Calc } from "@/utils/CalcDiscount";
import { getModule } from "vuex-module-decorators";
import AppStore from "@/store/AppStore";

@Component
export default class HotelRoomsAccordion extends Vue {
  @Prop()
  rooms: RoomModel[];

  @Prop()
  visible: boolean;

  @Prop()
  to: object;

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  routeTo(data: any) {
    getModule(AppStore, this.$store).setRoute(data);
  }

  getPeople(room: RoomModel) {
    const count = Number(this.currentRoute.query?.adult_count || 0) + Number(this.currentRoute.query?.child_count || 0);
    return count ? count + " " + decOfNum(count, ["человек", "человека", "человек"]) : "";
  }

  DiscountSum(room: RoomModel) {
    return Calc(room.price);
  }

  roomPrice(room: RoomModel) {
    return room.price?.toLocaleString("ru-RU") || null;
  }
}
