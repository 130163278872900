
import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class BaseAccordion extends Vue {
  isAccorActive = false;

  @Prop({ default: "" })
  classTitle: string;

  @Prop({ default: "" })
  styleTitle: string;

  @Prop({ default: "" })
  classContent: string;

  @Prop({ default: "" })
  classIcon: string;

  @Prop({ default: false })
  innerPaddingZero;

  openAccor(): void {
    this.$emit("change-accordion");
    this.isAccorActive = !this.isAccorActive;
  }
}
