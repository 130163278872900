
import { Component, Prop, Vue } from "vue-property-decorator";
import {
  ExcursionRoutesModel,
  ExcursionTransportForthModel,
} from "@/modules/Excursions/models/ExcursionModel";
import { decOfNum } from "@/utils/DecOfNum";
import { formatDate } from "@/utils/Formaters";
import { stringToNumber } from "@/utils/StringToNumber";
import dayjs from "dayjs";
import { DirectionsCardModel } from "@/modules/Directions/models/DirectionsCardModel";

@Component
export default class TrasportOrderLeftSide extends Vue {
  @Prop()
  transportBack: ExcursionTransportForthModel;

  @Prop()
  transportForth: ExcursionTransportForthModel;

  @Prop({ default: 1 })
  passengersCount: number;

  @Prop()
  forthSeats: string;

  @Prop()
  backSeats: string;

  @Prop()
  forthSeatsArr?: number[] | null;

  @Prop()
  backSeatsArr?: number[] | null;

  @Prop()
  backhDateStart: string;

  @Prop()
  forthDateStart: string;

  @Prop({ default: true })
  costIncludInTour: boolean;

  @Prop()
  forthPrice: number | string;

  @Prop()
  backPrice: number | string;

  @Prop()
  startFromToCityName: any;

  @Prop()
  finishFromToCityName: any;

  @Prop()
  startPointInfoStart: any;

  @Prop()
  startPointInfoFinish: any;

  @Prop()
  endPointInfoStart: any;

  @Prop()
  endPointInfoFinish: any;

  @Prop()
  back: ExcursionTransportForthModel;

  @Prop()
  forth: ExcursionTransportForthModel;

  @Prop()
  backhDateFinish: any;

  @Prop()
  forthDateFinish: any;

  @Prop()
  tourists: any;

  isChoosePlaceModal: boolean = false;
  isBack = false;
  fromSelectedDirection: DirectionsCardModel = new DirectionsCardModel();
  backSelectedDirection: DirectionsCardModel = new DirectionsCardModel();

  get isOneWay() {
    return this.backhDateStart === this.forthDateStart || !this.backhDateStart;
  }

  get selectedDirection() {
    const transport = !this.isBack ? this.forth : this.back;
    // @ts-ignore
    const obj = { ...transport, id: transport.transport?.id };
    obj.routes = {
      start: { start_date: obj.start_at },
    } as ExcursionRoutesModel;
    return obj;
  }

    get fromCityId() {
    return this.isBack ? this.back?.from_city?.id : this.forth?.from_city?.id;
  }

  get toCityId() {
    return this.isBack ? this.back?.to_city?.id : this.forth?.to_city?.id;
  }


  get toStartTime() {
    return dayjs(this.forthDateStart).utc().format("HH:mm");
  }

  get toStartDate() {
    return formatDate(this.forthDateStart);
  }

  get backStartTime() {
    return dayjs(this.backhDateStart).utc().format("HH:mm");
  }

  get backStartDate() {
    return formatDate(this.backhDateStart);
  }

  get toFinishTime() {
    return dayjs(this.forthDateFinish).utc().format("HH:mm");
  }

  get toFinishDate() {
    return formatDate(this.forthDateFinish);
  }

  get backFinishTime() {
    return dayjs(this.backhDateFinish).utc().format("HH:mm");
  }

  get backFinishDate() {
    return formatDate(this.backhDateFinish);
  }

  get trasportPrice() {
    const tuda = !!this.forthPrice
      ? stringToNumber(this.forthPrice)
      : stringToNumber(this.transportForth?.seat_cost) * this.passengersCount;
    const obratno = !!this.backPrice
      ? stringToNumber(this.backPrice)
      : stringToNumber(this.transportBack?.seat_cost) * this.passengersCount;
    return tuda + obratno;
  }

  convertDate(date: string) {
    return formatDate(date);
  }

  openBusModalForth() {
    this.isChoosePlaceModal = true;
    this.isBack = false;
  }

  openBusModalBack() {
    this.isChoosePlaceModal = true;
    this.isBack = true;
  }

  get touristCount() {
    if (this.passengersCount > 0) {
      return (
        this.passengersCount +
        " " +
        decOfNum(this.passengersCount, ["пассажир", "пассажира", "пассажиров"])
      );
    } else {
      return "1 пассажир";
    }
  }
}
