import { RequestAPI } from "@/api/core/RequestAPI";
import { BaseService } from "./services/BaseService";

export type ConstructorOf<T extends BaseService> = new (...args: any[]) => T;

export class ContextServiceRegistry {
  private ctx: any;
  private api: RequestAPI;
  private servicesMap = new Map<ConstructorOf<BaseService>, any>();

  public updateRequestApi(api: RequestAPI) {
    this.api = api;
    for (const iterServ of this.servicesMap.values()) {
      iterServ.apiRequest = api;
    }
  }

  public register<T extends BaseService>(
    Ctor: ConstructorOf<T>,
    ...args: any[]
  ) {
    const service = new Ctor(...args);
    this.servicesMap.set(Ctor, service);
    return this;
  }

  public getService<T extends BaseService>(Ctor: ConstructorOf<T>): T {
    let service = this.servicesMap.get(Ctor);
    if (!service) {
      service = new Ctor();
      service.nuxtContext = this.ctx;
      service.apiRequest = this.api;
      this.servicesMap.set(Ctor, service);
    }
    return service;
  }

  public exists<T extends BaseService>(
    Ctor: new (...args: any[]) => T
  ): boolean {
    return !!this.getService(Ctor);
  }

  public get nuxtContext() {
    return this.ctx;
  }

  public get apiRequest() {
    return this.api;
  }
}

let instance: ContextServiceRegistry = new ContextServiceRegistry();

export class ServiceLocator {
  public static get instance() {
    return instance;
  }

  public static createFreshServiceLocator() {
    instance = new ContextServiceRegistry();
  }
}
