
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { MealModel } from "@/modules/Excursions/models/MealModel";
import { HotelCategoriesModel } from "@/modules/Hotels/models/HotelCategoriesModel";
import { ServiceInHotel } from "../models/ServiceInHotel";
import { HotelDictianarySerivce } from "../HotelDictianarySerivce";

@Component
export default class HotelFilterTop extends Vue {
  @Prop()
  value: BaseFilterModel;

  internalFilterModel: BaseFilterModel = new BaseFilterModel();

  @Watch("value", { immediate: true, deep: true })
  async modelChahged() {
    await this.$nextTick();
    this.internalFilterModel = BaseViewModel.fromPlainIfNotClass(BaseFilterModel, this.value);
  }

  itemsCountInGroup = 3;
  serviceInHotelList: ServiceInHotel[] = [];
  hotelCategory: HotelCategoriesModel[] = [];
  mealModelList: MealModel[] = [];

  @Prop()
  clearClicked;

  // Вот такой хак.
  // Внешний компонент (BaseFilter) имеет свой стейт baseFilterModel и здесь передает свой стейт через v-model
  // На каждый клик мы дергаем this.$emit("input", что тригерит изменение стейта внешнего компонента,
  // а он трегерит перерендеринг своих детей, в том числе этого компонента, что приводит к срабаыванию вотчера на пропс. Делаем сранение старого и новго значения
  @Watch("clearClicked")
  onNeedClearFilter(oldVal, newVal) {
    if (oldVal.props !== newVal.props) {
      // this.initFilter();
      this.clearFilter();
      this.$emit("input", this.internalFilterModel);
    }
  }

  async mounted() {
    await this.initFilter();
  }

  async initFilter() {
    this.internalFilterModel = new BaseFilterModel();
    const result = await Promise.all([
      this.$serviceLocator.getService(HotelDictianarySerivce).getHotelCategories(),
      this.$serviceLocator.getService(HotelDictianarySerivce).getHotelServicesMain(),
      this.$serviceLocator.getService(HotelDictianarySerivce).getMealsList(),
    ]);
    this.hotelCategory = result[0];
    this.serviceInHotelList = result[1];
    this.mealModelList = result[2];

    // this.hotelCategory = await this.$serviceLocator.getService(HotelDictianarySerivce).getHotelCategories();
    // this.serviceInHotelList = await this.$serviceLocator.getService(HotelDictianarySerivce).getHotelServicesMain();
    // this.mealModelList = await this.$serviceLocator.getService(HotelDictianarySerivce).getMealsList();
  }

  clearFilter() {
    this.internalFilterModel.category_types = [];
    this.internalFilterModel.services = [];
    this.internalFilterModel.meal_category_types = [];
  }

  get serviceChunkCount() {
    return Math.ceil(this.serviceInHotelList.length / this.itemsCountInGroup);
  }

  servicesByChunk(groupInd: number) {
    return this.serviceInHotelList.slice(
      (groupInd - 1) * this.itemsCountInGroup,
      (groupInd - 1) * this.itemsCountInGroup + this.itemsCountInGroup
    );
  }

  get mealsChunkCount() {
    return Math.ceil(this.mealModelList.length / this.itemsCountInGroup);
  }

  mealsByChunk(groupInd: number) {
    return this.mealModelList.slice(
      (groupInd - 1) * this.itemsCountInGroup,
      (groupInd - 1) * this.itemsCountInGroup + this.itemsCountInGroup
    );
  }

  onFilterModelChanged() {
    this.$emit("input", this.internalFilterModel);
  }
}
