
import { phoneNumberMask } from "@/utils/InputMaskDefinitions";
import { Component, Prop, Vue } from "vue-property-decorator";
import { EmployeeModel, employeeValidationModel } from "./models/EmployeeModel";

const validations = () => {
  return { model: { ...employeeValidationModel } };
};

@Component({ validations })
export default class EmployeeEditForm extends Vue {
  @Prop()
  returnDataResolver;

  @Prop()
  model: EmployeeModel;

  phoneMask = phoneNumberMask;

  onOkClick() {
    if (!!this.returnDataResolver) {
      this.returnDataResolver(true);
    }
    this.$emit("close");
  }
}
