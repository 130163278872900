import { Pagination } from "@/models/core/Pagination";
import { Sort } from "@/models/core/Sort";
import { ApiResponse } from "./ApiResponse";
import { ErrorInterceptor, RequestInterceptor, ResponseInterceptor } from "./ApiInterceptor";

export enum ResponseType {
  JSON,
  TEXT,
  ANY,
}

export abstract class RequestAPI {
  private _baseUrl?: string;
  private requestInterceptorList: RequestInterceptor[] = [];
  private responseInterceptorList: ResponseInterceptor[] = [];
  private errorInterceptorList: ErrorInterceptor[] = [];

  constructor(_options?: any, baseUrl?: string) {
    this._baseUrl = baseUrl;
  }

  public addRequestInterceptor(interceptor: RequestInterceptor) {
    this.requestInterceptorList.push(interceptor);
    return this.requestInterceptorList;
  }

  public addResponseInterceptor(interceptor: ResponseInterceptor) {
    this.responseInterceptorList.push(interceptor);
    return this.responseInterceptorList;
  }

  public addErrorInterceptor(interceptor: ErrorInterceptor) {
    this.errorInterceptorList.push(interceptor);
    return this.errorInterceptorList;
  }

  public set baseUrl(baseUrl: string | undefined) {
    this._baseUrl = baseUrl;
  }

  public get baseUrl(): string | undefined {
    return this._baseUrl;
  }

  public getJSON(url: string, config?: any, pagination?: Pagination, sort?: Sort): Promise<ApiResponse> {
    return this.doRequest(this.buildUrl(url, pagination, sort), ResponseType.JSON, { ...config, ...{ method: "GET" } });
  }

  public getText(url: string, config?: any, pagination?: Pagination, sort?: Sort): Promise<ApiResponse> {
    return this.doRequest(this.buildUrl(url, pagination, sort), ResponseType.TEXT, { ...config, ...{ method: "GET" } });
  }

  public post(url: string, config?: any, data?: any, pagination?: Pagination, sort?: Sort): Promise<ApiResponse> {
    return this.doRequest(this.buildUrl(url, pagination, sort), ResponseType.JSON, { ...config, ...{ method: "POST" } }, data);
  }

  public postForm(url: string, config?: any, formData?: FormData): Promise<ApiResponse> {
    config.headers = {
      ...config.headers,
      ...{
        "Content-Type": "multipart/form-data",
      },
    };

    return this.post(url, { ...config }, formData);
  }

  public put(url: string, config?: any, data?: any): Promise<ApiResponse> {
    return this.doRequest(this.buildUrl(url), ResponseType.JSON, { ...config, ...{ method: "PUT", data } }, data);
  }

  public patch(url: string, config?: any, data?: any): Promise<ApiResponse> {
    return this.doRequest(this.buildUrl(url), ResponseType.JSON, { ...config, ...{ method: "PATCH", data } }, data);
  }

  public delete(url: string, config?: any, data?: any): Promise<ApiResponse> {
    return this.doRequest(this.buildUrl(url), ResponseType.JSON, { ...config, ...{ method: "DELETE", data } }, data);
  }

  public download(_url: string, _config?: any) {
    //  try {
    //   const response = await this.$serviceRegistry
    //     .getService(AuthService)
    //     .apiRequest.getJSON(`admin/contact-form/template/back/${this.contactForm.clContactFormId}`, { responseType: "blob" });
    //   if (!!response && response.status === 200) {
    //     const url = window.URL.createObjectURL(new Blob([response.data]));
    //     const link = document.createElement("a");
    //     link.href = url;
    //     link.setAttribute("download", `${this.contactForm.clContactFormFromTemplate}`);
    //     document.body.appendChild(link);
    // Надо убрать линк-элемент потом (см. МедТех)
    //     link.click();
    //   }
    // } catch {}
  }

  protected async doRequest(url: string, responseType: ResponseType, config?: any, data?: any): Promise<ApiResponse> {
    const extConfig = this.beforProcessRequest(config);
    try {
      const resp = await this.processRequest(url, responseType, extConfig, data);
      return this.afterProcessResponse(resp);
    } catch (err: any) {
      this.afterProcessResponse(err);

      if (!!this.errorInterceptorList) {
        for (const iter of this.errorInterceptorList) {
          iter(err as any);
        }
      }
      return Promise.reject(err);
    }
  }

  protected beforProcessRequest(config: any) {
    let extConfig = { ...config };
    if (!!this.requestInterceptorList) {
      for (const iter of this.requestInterceptorList) {
        const addOptions = iter(extConfig);
        if (!!addOptions) {
          extConfig = { ...extConfig, ...addOptions };
        }
      }
    }
    return extConfig;
  }

  protected abstract processRequest(url: string, responseType: ResponseType, config?: any, data?: any): Promise<ApiResponse>;

  protected afterProcessResponse(resp: ApiResponse) {
    if (!!this.responseInterceptorList) {
      for (const iter of this.responseInterceptorList) {
        iter(resp);
      }
    }
    return resp;
  }

  protected createResponse(resp: any, originalUrl: string, method: string): ApiResponse {
    return {
      data: resp.data,
      status: resp.status,
      statusText: resp.statusText,
      headers: resp.headers,
      originalUrl,
      method,
    };
  }

  protected createErrorResponse(error: any, originalUrl: string, method: string): ApiResponse {
    if (!!error.response) {
      return this.createResponse(error.response, originalUrl, method);
    } else {
      return this.createResponse({ data: null, status: 500, statusText: error.message, originalUrl }, originalUrl, method);
    }
  }

  // FIXME: тут надо с? решить 1 = 1
  // page может быть и без офсета может в самом пагинации сделать формирование и в сорте

  // или вообще как функцию передавать для форматирования урла извне
  protected buildUrl(query: string, pagination?: Pagination, sort?: Sort): string {
    let url = this._baseUrl ? `${this._baseUrl}/${query}` : query;

    if (!!sort && !!sort.sortField) {
      url = url + `&sortfield=${sort.sortField}&sorttype=${sort.sortType || "DESC"}`;
    }

    if (!!pagination) {
      url = url.includes("?") ? url + `&page=${pagination.currentPage || 1}` : url + `?page=${pagination.currentPage || 1}`;
      if (pagination.perPage) {
        url = url + `&limit=${pagination.perPage}`;
      }
    }
    return url;
  }
}
