
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseModalNotice extends Vue {
  @Prop()
  showModal: boolean;

  beforeOpen() {
    document.documentElement.style.overflowY = "hidden";
  }

  beforeClose() {
    document.documentElement.style.overflowY = "auto";
  }
}
