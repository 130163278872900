
import { Vue, Component, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import dayjs from "dayjs";
import TheContainer from "@/components/TheContainer.vue";
import ExcursionProgram from "@/modules/Excursions/components/ExcursionProgram.vue";
import TourDate from "@/modules/Excursions/components/TourDate.vue";
import ModalIntermediatePoints from "@/modules/Excursions/components/ModalIntermediatePoints.vue";
import AppStore from "@/store/AppStore";
import TourServices from "@/modules/Tours/components/TourServices.vue";
import TourAside from "@/modules/Tours/components/TourAside.vue";
import TourManager from "@/modules/Tours/components/TourManager.vue";
import TourTransport from "@/modules/Tours/components/TourTransport.vue";
import ExcursionHotel from "@/modules/Excursions/components/ExcursionHotel.vue";
import ModalHotelMore from "@/modules/Excursions/components/ModalHotelMore.vue";
import HotelSlider from "@/modules/Hotels/components/HotelSlider.vue";
import ModalShare from "@/modules/Hotels/components/ModalShare.vue";
import { AdditionalServicesModel } from "@/modules/Tours/models/AdditionalServicesModel";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { formatDateStandart } from "@/utils/Formaters";
import ChooseSeatsModalExcursion from "@/components/ChooseSeatsModalExcursion.vue";
import { ApplicationService } from "../Order/ApplicationService";
import { HotelModel } from "../Hotels/models/HotelModel";
import { RoomModel } from "../Hotels/models/RoomModel";
import ExcursionFavoritesAndShownService from "./ExcursionFavoritesAndShownService";
import { ExcursionService } from "./ExcursionService";
import {
  ExcursionDateModel,
  ExcursionModel,
  ExcursionRoutesElModel,
  ExcursionTransportForthModel,
} from "./models/ExcursionModel";
import { Notification } from "element-ui";

@Component({
  components: {
    ChooseSeatsModalExcursion,
    HotelSlider,
    ModalShare,
    TheContainer,
    ExcursionProgram,
    ExcursionHotel,
    TourServices,
    TourAside,
    TourManager,
    TourTransport,
    TourDate,
    ModalIntermediatePoints,
    ModalHotelMore,
  },
})
export default class ExcursionCardPage extends Vue {
  @Prop()
  slug: string;

  topFilterModel: BaseFilterModel = new BaseFilterModel();
  excursionModel: ExcursionModel = new ExcursionModel();
  showedeExcursions: ExcursionModel[] = [];
  additionalServices: AdditionalServicesModel[] = [];
  roomModel: RoomModel = new RoomModel();
  numberPrice: number = 0;
  isChoseHotel = false;
  isOpenItinerary = false;
  isOpenMoreHotel = false;
  isOpenNoticeModal = false;
  showModalShare: boolean = false;
  isChoosePlaceModal = false;
  isBack = false;
  intermediates: ExcursionRoutesElModel[] = [];
  selectedRooms: RoomModel[] = [];
  selectedSeats: number[] = [];
  selectedBackSeats: number[] = [];

  tourManager = {
    image: this.$getImage("/images/manager.png"),
    full_name: "Мария Сомченко",
    position: "Менеждер тура",
  };

  get routeQueryParams() {
    return Object.keys(this.currentRoute.query).length === 0
      ? this.$serviceLocator
          .getService(ExcursionService)
          .getDefaultFilterParams()
      : this.currentRoute.query;
  }

  async mounted() {
    if (Object.keys(this.currentRoute.query).length === 0) {
      const defFolterPojo = await this.$serviceLocator
        .getService(ExcursionService)
        .getDefaultFilterParams();
    }
    try {
      await this.updateData();
      this.loadBreadCrumbList();
      this.topFilterModel = BaseViewModel.fromPlainIfNotClass(
        BaseFilterModel,
        this.routeQueryParams
      );
      this.updateFovoritesAndShowed();
    } catch (err: any) {
      throw err;
    }
  }

  hotelPeople(hotel: HotelModel) {
    return !!hotel.rooms && hotel.rooms.length > 0
      ? hotel.rooms[0].people
      : { adult: 0, child: 0, total: 0 };
  }

  passangersCount() {
    return !!this.excursionModel.people?.total &&
      this.excursionModel.people.total.split(" ").length > 0
      ? parseInt(this.excursionModel.people.total.split(" ")[0])
      : 1;
  }

  durationAndPeople(hotel: HotelModel) {
    return !!hotel.rooms && hotel.rooms.length > 0
      ? hotel.rooms[0].duration.nights +
          ", " +
          hotel.rooms[0].people.adult +
          ", " +
          hotel.rooms[0].people.child
      : "";
  }

  hotelStartDate(hotel: HotelModel) {
    return !!hotel.rooms && hotel.rooms.length > 0
      ? hotel.rooms[0].from_date
      : "";
  }

  get livingCaption() {
    return this.excursionModel.is_hotel_selection
      ? "Проживание (выберите один из вариантов)"
      : "Проживание";
  }

  get peopleAndDates() {
    return (
      this.excursionModel.people?.total +
      " , c " +
      formatDateStandart(this.excursionModel.start_date) +
      " по " +
      formatDateStandart(this.excursionModel.finish_date)
    );
  }

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  selectedRoomChanged(room: RoomModel) {
    if (!room) {
      return;
    }

    if (this.excursionModel.is_hotel_selection) {
      this.selectedRooms = [];

      this.excursionModel.hotels.forEach((iter) => {
        if (iter.id !== room.hotel_id) {
          const ref = this.$refs[`excurtionHotel${iter?.id}`] as any;
          if (!!ref) {
            ref[0].clearSelectedRoom();
          }
        }
      });
    }
    this.selectedRooms = this.selectedRooms.filter(
      (iter) => iter.hotel_id !== room.hotel_id
    );
    this.selectedRooms.push(room);
  }

  isHotelSelected(hotel: HotelModel) {
    return (
      this.excursionModel.is_hotel_selection &&
      !!this.selectedRooms.find((iter) => iter.hotel_id === hotel.id)
    );
  }

  async tourDayClicked(day: ExcursionDateModel) {
    this.topFilterModel = BaseViewModel.fromPlainIfNotClass(
      BaseFilterModel,
      this.topFilterModel
    );
    this.topFilterModel.start_date_from = dayjs(day.start_date).format(
      "YYYY-MM-DD"
    );
    this.topFilterModel.start_date_to = dayjs(day.finish_date).format(
      "YYYY-MM-DD"
    );
    const filterModelAsPojo = this.topFilterModel.cloneAndRemoveEmptyValue([
      "child_count",
    ]);
    await this.updateData();
  }

  async updateData() {
    const id = this.currentRoute.params.slug;
    this.excursionModel = await this.$serviceLocator
      .getService(ExcursionService)
      .getExcursion(id, this.currentRoute.query);
  }

  loadBreadCrumbList() {
    const breadCrumbList = [
      {
        title: "Туры ...",
        routeName: "excursionsList",
        query: this.currentRoute.query,
      },
      { title: this.excursionModel.name },
    ];
    getModule(AppStore, this.$store).updateBreadCrumbList(breadCrumbList);
  }

  openMoreHotel(room: RoomModel) {
    this.roomModel = room;
    this.isOpenMoreHotel = true;
  }

  openItinerary(isBack = false) {
    this.intermediates = !isBack
      ? this.excursionModel.transport_forth.routes.intermediates
      : this.excursionModel.transport_back.routes.intermediates;
    this.isOpenItinerary = true;
  }

  chosePlace(model: ExcursionTransportForthModel, isBack: boolean) {
    this.isBack = isBack;
    this.excursionModel.transport_forth = !this.isBack
      ? model
      : this.excursionModel.transport_forth;
    this.excursionModel.transport_back = this.isBack
      ? model
      : this.excursionModel.transport_back;
    console.log(this.excursionModel, 'model')
    this.isChoosePlaceModal = true;
  }

  seatsSelected(selectedSeats: number[]) {
    this.selectedSeats = [...selectedSeats];
  }

  backSeatsSelected(selectedSeats: number[]) {
    this.selectedBackSeats = [...selectedSeats];
  }

  addService(service: AdditionalServicesModel | number) {
    if (typeof service === "number") {
      this.additionalServices = this.additionalServices.filter(
        (el) => el.id !== service
      );
    } else {
      const exists = this.additionalServices.find((el) => el.id === service.id);
      if (!exists) {
        this.additionalServices.push(service);
      } else {
        this.additionalServices = this.additionalServices.filter(
          (el) => el.id !== service.id
        );
        this.additionalServices.push(service);
      }
    }
  }

  get fromCityId() {
    return (
      (this.isBack && this.backCityId) || this.routeQueryParams.from_city_id
    );
  }

  get backCityId() {
    return this.excursionModel.transport_back?.routes?.finish?.point_city?.id;
  }

  get toCityId() {
    return this.routeQueryParams.to_city_id;
  }

  async onBuy() {
    getModule(AppStore, this.$store).setOldRoute({
      ...this.currentRoute,
      title: this.excursionModel.name,
    });
    if (
      this.excursionModel.is_hotel_selection &&
      this.selectedRooms.length === 0
    ) {
      this.isOpenNoticeModal = true;
    }
    if (
      !this.excursionModel.is_hotel_selection &&
      this.selectedRooms.length < this.excursionModel.hotels.length
    ) {
      this.isOpenNoticeModal = true;
    }

    if (!this.isOpenNoticeModal) {
      if (!!this.excursionModel.transport_forth) {
        this.excursionModel.transport_forth.seats = this.selectedSeats;
      }
      if (!!this.excursionModel.transport_back) {
        this.excursionModel.transport_back.seats = this.selectedBackSeats;
      }

      const toCityId = this.routeQueryParams.to_city_id;
      const fromCityId = this.routeQueryParams.from_city_id;
      const seatsCount =
        Number(this.currentRoute.query?.child_count || 0) +
        Number(this.currentRoute.query?.adult_count || 0);
      const ToCityBack =
        this.excursionModel.transport_back?.routes?.finish?.point_city?.id ||
        toCityId;
      const start_date = this.currentRoute.query?.start_date;
      this.excursionModel.seatsCount = seatsCount;
      try {
        const result = await this.$serviceLocator
          .getService(ApplicationService)
          .addExcersionTourDraftApp(
            this.currentRoute?.query.child_ages || [],
            fromCityId,
            toCityId,
            this.excursionModel,
            this.selectedRooms,
            this.additionalServices,
            ToCityBack,
            start_date
          );
        getModule(AppStore, this.$store).setRoute({
          name: "order",
          params: { id: result.data.id },
          query: this.currentRoute.query,
        });
      } catch (e: any) {
        const err = e.data?.message;
        Notification.warning({
          title: "Ошибка",
          message: err[Object.keys(err)?.[0]]?.[0] || "",
        });
      }
    }
  }

  async updateFovoritesAndShowed() {
    if (!!this.excursionModel && this.excursionModel.id > 0) {
      const ids = this.$serviceLocator
        .getService(ExcursionFavoritesAndShownService)
        .getShowedIdList([this.excursionModel.id]);
      if (!!ids) {
        this.showedeExcursions = await this.$serviceLocator
          .getService(ExcursionService)
          .getByIdList(ids, this.routeQueryParams);
      }
      this.$serviceLocator
        .getService(ExcursionFavoritesAndShownService)
        .updateFavorStatus([this.excursionModel]);
      this.$serviceLocator
        .getService(ExcursionFavoritesAndShownService)
        .addToShown(this.excursionModel, this.routeQueryParams);
    }
  }

  toogleFavorites() {
    if (!false) {
      return this.$notify.warning("Пожалуйста, авторизуйтесь");
    }

    this.$serviceLocator
      .getService(ExcursionFavoritesAndShownService)
      .toogleFavor(this.excursionModel, this.topFilterModel);
  }
}
