
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseDataNotFound extends Vue {
  @Prop({ default: "/icons/bad_smile.svg" })
  iconPath: string;

  @Prop({ default: "Ничего не найдено" })
  title: string;

  @Prop({ default: false })
  hasBtn: boolean;

  @Prop({ default: "Попробуйте сбросить фильтры или изменить запрос" })
  text: string;
}
