
import { Component, Prop, Vue } from "vue-property-decorator";
import { yandexMap, ymapMarker, loadYmap } from "vue-yandex-maps";
import { settings } from "@/utils/YandexMapSettings";

@Component({ components: { yandexMap, ymapMarker } })
export default class ModalHotelMap extends Vue {
  @Prop({ required: true })
  showModal: boolean;

  @Prop()
  address: string;

  @Prop({ default: "" })
  city: string;

  settings: any = settings;
  controls: any = [];
  coords: [] = [];
  markerIcon: any = {
    layout: "default#imageWithContent",
    imageHref: "/img/map-fill.svg",
    imageSize: [42, 40],
    imageOffset: [-10, -15],
  };

  beforeOpen() {
    document.documentElement.style.overflowY = "hidden";
  }

  async mounted() {
      await loadYmap({ ...this.settings, debug: false });
      const vm = this;
      // @ts-ignore
      window.ymaps
        .geocode(this.city + " " + this.address, {
          results: 1,
        })
        .then(function (res) {
          const firstGeoObject = res.geoObjects.get(0);
          const coords = firstGeoObject.geometry.getCoordinates();
          vm.coords = coords;
        });
  }

  beforeClose() {
    document.documentElement.style.overflowY = "auto";
  }
}
