
import { Component, Vue, Watch } from "vue-property-decorator";
import BaseFilter from "@/components/filter/BaseFilter.vue";
import TourList from "@/modules/Tours/components/TourList.vue";
import TheContainer from "@/components/TheContainer.vue";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { Pagination } from "@/models/core/Pagination";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { TourType } from "@/models/TourType";
import HotelFilter from "@/modules/Hotels/components/HotelFilter.vue";
import { HotelModel } from "../Hotels/models/HotelModel";
import { TourService } from "./TourService";
import { getModule } from "vuex-module-decorators";
import AppStore from "@/store/AppStore";

@Component({
  components: {
    TheContainer,
    BaseFilter,
    TourList,
    HotelFilter,
  },
})
export default class TourListPage extends Vue {
  pagination: Pagination = new Pagination();
  tourList: HotelModel[] = [];
  showedTours: HotelModel[] = [];
  extendedFilterModel: BaseFilterModel = new BaseFilterModel();
  topFilterModel: BaseFilterModel = new BaseFilterModel();
  loadingState = false;
  showMoreEnabled = false;
  isShowFilter = false;
  pending = false;

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  async mounted() {
    try {
      this.topFilterModel = BaseViewModel.fromPlainIfNotClass(
        BaseFilterModel,
        this.currentRoute.query
      );
      this.syncLeftFilter(this.topFilterModel);
      await this.updateTourlList(this.topFilterModel, this.pagination);
    } catch (err: any) {}
  }

  async updateTourlList(filterModel: BaseFilterModel, pagination: Pagination) {
    if (this.pending) {
      return;
    }
    this.pending = true;
    this.loadingState = true;
    const filterModelAsPojo = filterModel.cloneAndRemoveEmptyValue([
      "child_count",
    ]);
    const tourListWithPagination = await this.$serviceLocator
      .getService(TourService)
      .findSeaTours(filterModelAsPojo, pagination);
    this.tourList = tourListWithPagination.data;
    this.pagination = tourListWithPagination.pagination;

    this.showMoreEnabled = this.pagination.total > this.tourList.length;
    this.loadingState = false;
    this.pending = false;
    this.isShowFilter = true;
  }

  @Watch("extendedFilterModel", { deep: true })
  async onExtendedFilterModelChanged() {
    if (this.pending) {
      return;
    }
    const extendedFilterModel = BaseViewModel.fromPlainIfNotClass(
      BaseFilterModel,
      this.extendedFilterModel
    ) as BaseFilterModel;
    this.topFilterModel = BaseViewModel.fromPlainIfNotClass(
      BaseFilterModel,
      this.topFilterModel
    ) as BaseFilterModel;
    this.topFilterModel.category_types = [
      ...extendedFilterModel.category_types,
    ];
    this.topFilterModel.price_from = extendedFilterModel.price_from;
    this.topFilterModel.price_to = extendedFilterModel.price_to;
    this.topFilterModel.name = extendedFilterModel.name;

    this.topFilterModel.meal_category_types = [
      ...extendedFilterModel.meal_category_types,
    ];
    this.topFilterModel.sea_distances = [...extendedFilterModel.sea_distances];
    this.topFilterModel.services = [...extendedFilterModel.services];
    this.topFilterModel.price = extendedFilterModel.price;
    this.topFilterModel.favorite = extendedFilterModel.favorite;

    const combinedFilter = this.topFilterModel.cloneAndRemoveEmptyValue([
      "child_count",
      "price",
      "favorite",
    ]);
    this.updateRouteQuery(combinedFilter);

    Pagination.clearSelectedPages(this.pagination);
    this.pagination.currentPage = 1;

    await this.updateTourlList(this.topFilterModel, this.pagination);
  }

  syncLeftFilter(filterModel: BaseFilterModel) {
    this.extendedFilterModel.category_types = [...filterModel.category_types];
    this.extendedFilterModel.price_from = filterModel.price_from;
    this.extendedFilterModel.price_to = filterModel.price_to;
    this.extendedFilterModel.name = filterModel.name;
    this.extendedFilterModel.meal_category_types = [
      ...filterModel.meal_category_types,
    ];
    this.extendedFilterModel.sea_distances = [...filterModel.sea_distances];
    this.extendedFilterModel.services = [...filterModel.services];
  }

  //  При обновлении фильтра, если на той же странице (отелей) - по событию от фильтра
  async showSearchResultFromTopFilter(
    _type: TourType,
    filterModel: BaseFilterModel
  ) {
    this.topFilterModel.direction = filterModel.direction;
    this.topFilterModel.start_date = filterModel.start_date;
    this.topFilterModel.finish_date = filterModel.finish_date;
    this.topFilterModel.to_city_id = filterModel.to_city_id;
    this.topFilterModel.from = filterModel.from;
    this.topFilterModel.from_city_id = filterModel.from_city_id;
    this.topFilterModel.child_count = filterModel.child_count;
    this.topFilterModel.child_ages = [...filterModel.child_ages];
    this.topFilterModel.adult_count = filterModel.adult_count;
    // Синхронизируем левый фильтр
    this.syncLeftFilter(filterModel);
    // Делаем из модели фильтра POJO (чистоый JS-объект)
    const filterModelAsPojo = filterModel.cloneAndRemoveEmptyValue([
      "child_count",
    ]);

    // Чистим модель пагинации
    Pagination.clearSelectedPages(this.pagination);
    this.pagination.currentPage = 1;
    // Обновляем строку в браузере (для SEO)
    this.updateRouteQuery(filterModelAsPojo);
    // Обновляем данные
    // await this.updateTourlList(filterModel, this.pagination);
  }

  async loadMore() {
    Pagination.createLoadMorePagination(this.pagination);
    const result = await this.$serviceLocator
      .getService(TourService)
      .findSeaTours(this.currentRoute.query, this.pagination);
    this.tourList = [...this.tourList, ...result.data];
    this.showMoreEnabled = this.pagination.total > this.tourList.length;
  }

  updateRouteQuery(filterModelAsPojo: any) {
    getModule(AppStore, this.$store).setRoute({
      name: "toursList",
      query: filterModelAsPojo,
      params: {},
    });
  }

  onSortModeChanged({ id }: { id: number }) {
    if (id === 1) {
      this.extendedFilterModel.favorite = 0;
      this.extendedFilterModel.price = null;
    }
    if (id === 2) {
      this.extendedFilterModel.favorite = 1;
      this.extendedFilterModel.price = null;
    }
    if (id === 3) {
      this.extendedFilterModel.price = 0;
      this.extendedFilterModel.favorite = null;
    }
    if (id === 4) {
      this.extendedFilterModel.price = 1;
      this.extendedFilterModel.favorite = null;
    }
  }
}
