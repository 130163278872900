
import { Vue, Component, Prop } from "vue-property-decorator";
import { decOfNum } from "@/utils/DecOfNum";
import { stringToNumber } from "@/utils/StringToNumber";
import { RoomModel } from "@/modules/Hotels/models/RoomModel";
import { ExcursionModel } from "@/modules/Excursions/models/ExcursionModel";
import { formatDate, formatDateStandart } from "@/utils/Formaters";
import { AdditionalServicesModel } from "../models/AdditionalServicesModel";

@Component
export default class TourAside extends Vue {
  isVisible = false;
  isOpened: boolean = false;
  showFirstItem = false;

  @Prop()
  excursionModel: ExcursionModel;

  @Prop()
  duration: any;

  @Prop()
  peopleAndDates: string;

  @Prop()
  people: any;

  @Prop()
  tourServices: AdditionalServicesModel[];

  @Prop()
  rooms: RoomModel[];

  @Prop({ default: false })
  isExcursion: boolean;

  get isUserAgency() {
    return false;
  }
  formatDateStandart(date) {
    return formatDateStandart(date);
  }
  get trasportPrice() {
    const tuda = !this.excursionModel?.transport_forth?.forthCheckbox
      ? stringToNumber(this.excursionModel?.transport_forth?.total_price) //* this.passengersCount
      : 0;
    const obratno = !this.excursionModel?.transport_back?.backCheckbox
      ? stringToNumber(this.excursionModel?.transport_back?.total_price) // * this.passengersCount
      : 0;
    return tuda + obratno;
  }

  get numberPrice() {
    let roomsProce = 0;
    if (!!this.rooms) {
      this.rooms.forEach((element) => {
        roomsProce += element.price;
      });
    }
    return roomsProce;
  }

  get agencyCommissionTotal() {
    let result =
      stringToNumber(this.excursionModel.agency_commission) +
      stringToNumber(this.excursionModel?.transport_forth?.agency_commission) +
      stringToNumber(this.excursionModel?.transport_back?.agency_commission);

    let roomsProce = 0;
    this.rooms.forEach((element) => {
      roomsProce += element.agency_commission;
    });

    result = result + roomsProce;

    return result;
  }

  get totalPrice() {
    const excPrice = !!this.excursionModel.excursion_price
      ? this.excursionModel.excursion_price
      : 0;
    const mealPrice = !!this.excursionModel.meal_price
      ? this.excursionModel.meal_price
      : 0;
    const total =
      mealPrice +
      excPrice +
      this.trasportPrice +
      this.numberPrice +
      this.servicePrice;
    return total;
  }

  get durationText() {
    return this.duration?.days + " " + this.duration?.nights;
  }

  get passengersCount() {
    return this.people?.total.split(" ")[0] || 0;
  }

  get nightsCount() {
    return this.duration?.nights.split(" ")[0] || 0;
  }

  get servicePrice() {
    let price = 0;
    if (!!this.tourServices) {
      this.tourServices.forEach((element) => {
        price += element.count * stringToNumber(element.sum);
      });
    }
    return price;
  }

  get toStartTime() {
    return this.excursionModel.transport_forth.routes.start.start_time;
  }

  get backStartTime() {
    return this.excursionModel.transport_back.routes.start.start_time;
  }

  get toFinishTime() {
    return this.excursionModel.transport_forth.routes.finish.finish_time;
  }

  get backFinishTime() {
    return this.excursionModel.transport_back.routes.finish.finish_time;
  }

  convertDate(date: string) {
    return formatDate(date);
  }

  get buttonText() {
    switch (this.isVisible) {
      case true:
        return "Свернуть";
      case false:
        return "Подробнее";
    }
  }

  trasformText(count: number) {
    return `${count} ` + decOfNum(count, ["место", "места", "мест"]);
  }

  get passangersCountText() {
    return this.people.total;
  }

  async toBook() {
    // await this.$serviceLocator.getService(ApplicationService).addDraftTourApplication(this.excursionModel, this,tourServices);
    // this.showModalBus = true;
  }
}
