/* eslint-disable camelcase */
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { TicketModel } from "./OrderModel";

export enum AppEntityType {
  HOTEL = "hotels",
  TOURS = "tours",
  SEA_TOURS = "sea_tours",
  TRANSPORT = "transport",
}

export enum AppStatus {
  DRAFT = 1,
  ACTUAL = 2,
}

export class PaymentsInfo extends BaseViewModel {
  total: string;
  paid: number;
  to_pay: number;
}

export class DraftAppModel extends BaseViewModel {
  id = 0;
  start_date?: string | (string | null)[];
  applicationable_id = 0;
  applicationable_type: AppEntityType; // hotels, tours, sea_tours, transport
  adult_count = 1;
  child_count = 0;
  status = 0;
  can_edit: boolean;
  phone = "";
  email = "";
  status_name = "Черновик";
  number: string = "";
  child_ages: number[] = [];
  tourists: TicketModel[] = [];
  agency_commission = 0;
  finish_date?: any;
  attendant_phone = "";
  payment_status_name?: string;

  agency_user?: {
    full_name: string;
    email: string;
    phone: string;
  };

  payments_info?: {
    paid: number;
    to_pay: number;
    total: string;
    can_pay: boolean;
  };

  people: {
    adult: string;
    child: string;
    total: string;
  };

  seats: {
    transport_id: number;
    number: number | null;
    date: string;
    tourist_id?: number;
    from_city_id: number;
    to_city_id: number;
  }[];

  applicationable: {
    id: number;
    name: string;
  };

  accommodations: {
    hotel_id: number;
    hotel_room_id: number;
    hotel_meal_id?: number;
    start_at?: string;
    finish_at?: string;
    start_date: string | undefined;
    finish_date: string | undefined;

    price_total: number;
    agency_commission: number;
    hotel?: any;
    room?: any;
    meal?: { id: number; name: string };
    duration?: {
      days: string;
      nights: string;
    };
  }[];

  transport_forth: {
    transport_id: number;
    start_at?: string;
    finish_at?: string;
    start_date: string | undefined;
    finish_date: string | undefined;

    count: number;
    price: number;
    price_total: number;
    transport?: any;
    agency_commission: number;
    from_city_id: number;
    to_city_id: number;

    from_city?: { name: string; id: string };
    to_city?: { name: string; id: string };

    to_info?: string;
    from_info?: string;
  };

  transport_back: {
    transport_id: number;
    start_at?: string;
    finish_at?: string;
    start_date: string | undefined;
    finish_date: string | undefined;
    count: number;
    price: number;
    price_total: number;
    transport?: any;
    agency_commission: number;
    from_city_id: number | string;
    to_city_id: number | string;
    from_city?: { name: string; id: string };
    to_city?: { name: string; id: string };
    to_info?: string;
    from_info?: string;
  };

  services_type: "tour_services";
  services: {
    id: number;
    serviceable: {
      id: number;
      name: string;
    };
    places: string;
    price_total: string;
  }[];

  documents: { name: string; url: string }[];

  excursion_price: number;
  meal_price: number;

  manager_email: string;
  manager_name: string;
  manager_phone: string;
}

export class ShortApplicationModel extends BaseViewModel {
  id = 0;
  applicationable_id = 0;
  applicationable_type: AppEntityType; // hotels, tours, sea_tours, transport
  requestorable_id = 0;
  requestorable_type = "";
  payment_status_name: string | undefined = "";
  number = "";
  tourists: TicketModel[] = [];
  сreated_at = "";
  status: number; // status, при первом оформлении отдавай 1, а после оформления данных туристов - 2
  statusName = "Черновик";
  statusColor = "#16192C";
  typeName = "";
  dateFrom: string | undefined = "";
  dateTo: string | undefined = "";
  simpleDatesInterval: string | undefined = "";
  directionOrHotelName: string | undefined = "";
  directionOrHoteCaption = "";
  tourOrHotelShortDescription: string | undefined = "";
  totalPrice = 0;
  payments_info?: {
    paid: number;
    to_pay: number;
    total: string;
    can_pay: boolean;
  };

  firstBlockCaption = "";
  secondBlockCaption = "";
  showSecondDetailBlock = false;
  secondBlockName? = "";
  secondBlockShortDescription? = "";
  documents: { name: string; url: string }[];

  agencyCommissionTotal = 0;
}

export class PaymentsMethodsModel extends BaseViewModel {
  id: number = 0;
  name: string = "";
}
