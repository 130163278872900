
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import ExcursionFavoritesAndShownService from "../ExcursionFavoritesAndShownService";
import ExcursionListItem from "@/modules/Excursions/components/ExcursionListItem.vue";
import { ExcursionModel } from "@/modules/Excursions/models/ExcursionModel";
import { decOfNum } from "@/utils/DecOfNum";

@Component({ components: { ExcursionListItem } })
export default class ExcursionList extends Vue {
  @Prop()
  excursionsList: ExcursionModel[];

  @Prop()
  loadingState: boolean;

  @Prop({ default: false })
  showMoreEnabled: boolean;

  sort: number | null = null;

  optionsSelect = [
    { id: 1, label: "По рейтингу (по возрастанию)" },
    { id: 2, label: "По рейтингу (по убыванию)" },
    { id: 3, label: "По цене (по возрастанию)" },
    { id: 4, label: "По цене (по убыванию)" },
  ];

  @Watch("excursionsList", { immediate: true })
  onListChanged() {
    this.updateFavorites();
  }

  updateFavorites() {
    this.$serviceLocator
      .getService(ExcursionFavoritesAndShownService)
      .updateFavorStatus(this.excursionsList);
  }

  generateText(count: number) {
    return (
      count +
      " " +
      decOfNum(count, ["предложение", "предложения", "предложений"])
    );
  }

  onSortModeChanged(mode) {
    this.$emit("on-sort-mode-changed", mode);
  }
}
