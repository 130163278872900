
import { Component, Prop, Vue } from "vue-property-decorator";
import { CatalogExcursionModel } from "@/modules/Catalog/models/CatalogExcursionModel";

@Component
export default class ModalCatalogFilter extends Vue {
  @Prop()
  showModal: boolean;

  @Prop()
  video: CatalogExcursionModel["excursion_tour"]["video"];
}
