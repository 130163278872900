/* eslint-disable camelcase */

import { BaseServiceModel } from "@/models/BaseServiceModel";
import { BaseViewModel } from "@/models/core/BaseViewModel";

export class PhotoModel {
  url: string = "";
}

export type AccommodationType = {
  id: number;
  name: string;
};

export type ObjType = {
  id: number;
  name: string;
};

export type durationType = {
  days: string;
  nights: string;
};

export class RoomModel extends BaseViewModel {
  id: number = 0;
  name: string = "";
  hotel_id: number = 0;
  price: number = 0;
  area: number = 0;
  persons: string = "";
  free_space: number = 0;
  balcony_area: number = 0;
  services: BaseServiceModel[] = [];
  inclusived: string = "";
  service: ObjType[] = [];
  equipments: ObjType[] = [];
  furnitures: ObjType[] = [];
  bathrooms: ObjType[] = [];
  photos: PhotoModel[] = [];
  accommodation: ObjType;
  total_persons: number;
  from_date: string;
  external_info?: {
    line?: string;
    places?: any;
  };
  from_day_name: string;
  to_date: string;
  to_day_name: string;
  to_day_min_name: string;
  duration: durationType;
  people: {
    adult: any;
    child: any;
    total: any;
  };

  places: {
    adult: any;
    child: any;
    adv: any;
  };

  meal: { id: number; name: string; sum: number; external_id?: string };
  meals: any[] = [];

  agency_commission = 0;

  premises: { area: string; name: string }[];
}
