
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseRating extends Vue {
  @Prop({ default: 0 })
  rating: string | number;

  @Prop({ default: "" })
  name: string;

  @Prop({ default: true })
  readonly: boolean;

  @Prop({ default: false })
  noStars: boolean;

  @Prop({ default: 20 })
  starSize: number;

  @Prop({ default: false })
  showRating: boolean;

  @Prop({ default: 0.5 })
  increment: number;

  @Prop({ default: "#EEF0FB" })
  inactiveColor: string;

  @Prop({ default: "#FFBB36" })
  activeColor: string;

  @Prop({ default: 5 })
  maxRating: number;

  @Prop({ default: false })
  reverse: boolean;

  get ratingName() {
    return "";
  }

  get ratingCount() {
    return this.rating || 0;
  }
}
