
// import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { Component, Prop, Vue } from "vue-property-decorator";
import { SplideOptions } from "@splidejs/splide/src/js/splide.d";
import { ObjType, RoomModel } from "@/modules/Hotels/models/RoomModel";
import { stringToNumber } from "@/utils/StringToNumber";
// const vueCustomScrollbar = () => import("vue-custom-scrollbar");

// @Component({ components: { vueCustomScrollbar } })
@Component
export default class ModalHotelMore extends Vue {
  @Prop()
  showModal: boolean;

  @Prop()
  roomModel: RoomModel;

  $refs!: {
    primary: any;
    secondary: any;
  };

  get price() {
    return stringToNumber(this.roomModel?.price).toLocaleString("ru-RU") || 0;
  }

  get duration() {
    return this.roomModel.duration;
  }

  get nightsCount() {
    return this.duration?.nights?.split(" ")[0] || 0;
  }

  get durationName() {
    return this.duration?.days + " " + this.duration?.nights;
  }

  opening() {
    this.$refs.primary.sync(this.$refs.secondary.splide);
  }

  arrayToText(array: ObjType[]) {
    const text: string[] = [];

    array.forEach((element: ObjType) => {
      text.push(element.name);
    });

    return text.join(", ");
  }

  primaryOptions: SplideOptions = {
    type: "loop",
    // width: 800,
    pagination: false,
    arrows: false,
    cover: true,
  };

  secondaryOptions: SplideOptions = {
    type: "slide",
    rewind: true,
    // width: 800,
    gap: 16,
    pagination: false,
    fixedWidth: 121,
    fixedHeight: 67,
    cover: true,
    focus: "center",
    isNavigation: true,
    // arrows: false,
    classes: {
      arrows: "splide__arrows HotelMore__arrows",
      arrow: "splide__arrow HotelMore__arrow",
      prev: "splide__arrow--prev HotelMore__prev",
      next: "splide__arrow--next HotelMore__next",
    },
  };

  beforeOpen() {
    document.documentElement.style.overflowY = "hidden";
  }

  beforeClose() {
    document.documentElement.style.overflowY = "auto";
  }
}
