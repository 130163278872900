
import { Vue, Component, Prop } from "vue-property-decorator";
import { HotelModel } from "@/modules/Hotels/models/HotelModel";
import ModalHotelMap from "@/modules/Hotels/components/ModalHotelMap.vue";

@Component({ components: { ModalHotelMap } })
export default class TourPageHotel extends Vue {
  @Prop()
  hotelModel: HotelModel;

  showModalMap: boolean = false;

  get seaDistance() {
    return this.hotelModel.sea_distance
      ? `До моря ${this.hotelModel.sea_distance}`
      : "";
  }
}
