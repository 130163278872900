import { BaseFilterModel } from "@/models/BaseFilterModel";
import { FovoritesSupport } from "@/models/common/FovoritesSupport";
import { ServiceLocator } from "@/ServiceLocator";
import { BaseService } from "@/services/BaseService";

export default class BaseFavoritesAndShownService extends BaseService {
  protected shownKeyName = "";

  constructor(shownKeyName: string) {
    super();
    this.shownKeyName = shownKeyName;
  }

  public async toogleFavor(
    item: FovoritesSupport,
    filterModelForPayLoad: BaseFilterModel
  ) {
    if (false) {
      await this.toogleFavorites(item, filterModelForPayLoad);
    }
    item.isInFavor = !item.isInFavor;
  }

  async toogleFavorites(
    _item: FovoritesSupport,
    _filterModelForPayLoad: BaseFilterModel
  ) {}

  public addToShown(
    item: FovoritesSupport,
    filterModelForPayLoad: BaseFilterModel
  ) {
    if (!this.isShownExists(item) && item.id > 0) {
      const toStore: any = {};
      toStore.id = item.id;
      toStore.payload = filterModelForPayLoad;

      const prevVal = window.localStorage.getItem(this.shownKeyName) || "";
      window.localStorage.setItem(
        this.shownKeyName,
        `${prevVal}|${JSON.stringify(toStore)}`
      );
    }
    item.isShown = true;
  }

  public getShowedList() {
    const vals = window.localStorage.getItem(this.shownKeyName);
    if (!!vals) {
      const favorList = vals
        .split("|")
        .filter((iter) => !!iter)
        .map((iter) => {
          return JSON.parse(iter);
        });
      return favorList;
    }
    return null;
  }

  public getShowedIdList(excludeIds: number[] = [0]) {
    const showed = this.getShowedList();
    if (!!showed) {
      const ids = showed.map((iter) => {
        return iter.id;
      });
      return ids.filter((x) => !excludeIds.includes(x));
    }
    return null;
  }

  public updateFavorStatus(items: FovoritesSupport[]) {
    const userAuth = false;
    const userAgency = false;

    if (!!items) {
      items.forEach((iterItem) => {
        iterItem.isInFavor = userAuth
          ? userAgency
            ? iterItem.agency_favorite
            : iterItem.client_favorite
          : false;
      });
    }
  }

  protected isShownExists(item: FovoritesSupport) {
    const vals = this.getShowedIdList();
    return vals?.includes(item.id);
  }
}
