import { BaseFilterModel } from "@/models/BaseFilterModel";
import { ServiceLocator } from "@/ServiceLocator";
import { BaseService } from "@/services/BaseService";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { TouristFolderModel } from "./models/TouristFolderModel";
import { FolderItemDef } from "./models/FolderItemDef";

export class TouristFoldersService extends BaseService {
  async getFolders(): Promise<TouristFolderModel[]> {
    const result = await this.getArrayOrEmpty(
      TouristFolderModel,
      `search-module/${this.routePartName}/folders`
    );

    if (!!result) {
      result.forEach((iter) => {
        if (!!iter.favorites && iter.favorites.length > 0) {
          const firstFav = iter.favorites[0].favorable;
          if (!!firstFav && !!firstFav.photos && firstFav.photos.length > 0) {
            iter.image_left = firstFav.photos[0].url;
          }

          if (iter.favorites.length > 1) {
            const firstFav = iter.favorites[1].favorable;
            if (!!firstFav && !!firstFav.photos && firstFav.photos.length > 0) {
              iter.image_right = firstFav.photos[0].url;
            }
          }

          if (!iter.image_right) {
            const firstFav = iter.favorites[0].favorable;
            if (!!firstFav && !!firstFav.photos && firstFav.photos.length > 1) {
              iter.image_right = firstFav.photos[1].url;
            }
          }

          if (!iter.image_right) {
            const firstFav = iter.favorites[0].favorable;
            if (!!firstFav && !!firstFav.photos && firstFav.photos.length > 0) {
              iter.image_right = firstFav.photos[0].url;
            }
          }
        }
      });
    }
    return result;
  }

  async getAllItems() {
    return await this.getArrayOrEmpty(
      FolderItemDef,
      `search-module/${this.routePartName}/favorites`
    );
  }

  async getFolderItems(id: number) {
    const folder = await this.getOneOrEmpty(
      TouristFolderModel,
      `search-module/${this.routePartName}/folders/${id}`
    );
    return folder.favorites;
  }

  async createFolder(name: string) {
    return await this.getOneOrEmptyPost(
      TouristFolderModel,
      `search-module/${this.routePartName}/folders`,
      {},
      { name }
    );
  }

  async deleteFolder(id: number) {
    return await this.apiRequest.delete(
      `search-module/${this.routePartName}/folders/${id}`
    );
  }

  async editFolder(id: number, name: string) {
    return await this.apiRequest.put(
      `search-module/${this.routePartName}/folders/${id}`,
      {},
      { name }
    );
  }

  async addToMainFolder(
    entityId: number,
    filterModelForPayLoad: BaseFilterModel,
    entityType = "tours"
  ) {
    const filterModelAsPojo = BaseViewModel.cloneAndRemoveEmptyValue(
      filterModelForPayLoad,
      ["child_count"]
    );
    await this.apiRequest.post(
      `search-module/${this.routePartName}/favorites`,
      {},
      {
        favorable_id: entityId,
        favorable_type: entityType,
        payload: filterModelAsPojo,
      }
    );
  }

  async addSeaTourToMainFolder(
    tourId: number,
    hotelId: number,
    filterModelForPayLoad: BaseFilterModel
  ) {
    const filterModelAsPojo = BaseViewModel.cloneAndRemoveEmptyValue(
      filterModelForPayLoad,
      ["child_count"]
    );
    filterModelAsPojo.hotel_id = hotelId;
    await this.apiRequest.post(
      `search-module/${this.routePartName}/favorites`,
      {},
      {
        favorable_id: tourId,
        hotel_id: hotelId,
        favorable_type: "sea_tours",
        payload: filterModelAsPojo,
      }
    );
  }

  async addToFolder(entityId: number, folderId: number) {
    // const dataName = ServiceLocator.instance.getService(AuthService).isUserAgency ? "agency_folder_id" : "client_folder_id";
    // await this.apiRequest.put(`search-module/${this.routePartName}/favorites/${entityId}`, {}, { [dataName]: folderId });
  }

  getExcursionsModelByFlderItems(folderItemList: FolderItemDef[]) {
    return folderItemList
      .filter((iter) => iter.type.endsWith("\\Tour"))
      .map((iter) => iter.favorable);
  }

  getHotelModelByFlderItems(folderItemList: FolderItemDef[]) {
    return folderItemList
      .filter((iter) => iter.type.endsWith("Hotel"))
      .map((iter) => iter.favorable);
  }

  getSeaTourModelByFlderItems(folderItemList: FolderItemDef[]) {
    return folderItemList
      .filter((iter) => iter.type.endsWith("SeaTour"))
      .map((iter) => iter);
  }

  private get routePartName() {
    return "clients";
  }
}
