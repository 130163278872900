var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',_vm._g(_vm._b({staticClass:"Button",class:[
    {
      'close-icon': _vm.closeIcon,
      'Button--small': _vm.small,
      'Button--medium': _vm.medium,
      'Button--large': _vm.large,
      'Button--fluid': _vm.fluid,
      'Button--transparent': _vm.transparent,
      'Button--grayline': _vm.outline && _vm.gray,
      'Button--darkline': _vm.outline && _vm.dark,
      'Button--dark': _vm.dark && !_vm.outline,
    },
    _vm.classes,
  ],attrs:{"type":_vm.type}},'button',{
    ..._vm.$attrs,
    disabled: _vm.isDisabled,
  },false),_vm.$listeners),[(_vm.loading)?_c('base-loader',{attrs:{"size":_vm.loaderSize}}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }