import { render, staticRenderFns } from "./BaseLoader.vue?vue&type=template&id=4fc4dc96&scoped=true&"
import script from "./BaseLoader.vue?vue&type=script&lang=ts&"
export * from "./BaseLoader.vue?vue&type=script&lang=ts&"
import style0 from "./BaseLoader.vue?vue&type=style&index=0&id=4fc4dc96&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fc4dc96",
  null
  
)

export default component.exports