// export enum HotelCategory {
//   HIGHT = "Высокий (4-5) звезд",
//   MEDIUM = "Средний (3) звезды",
//   STANDART = "Стандарт (1-2) звезда",
// }

import { BaseViewModel } from "@/models/core/BaseViewModel";
export class HotelCategoriesModel extends BaseViewModel {
  id: number;
  name: string;
  constructor(id?: number, name?: string) {
    super();
    this.id = id || 0;
    this.name = name || "";
  }
}
