import { plainToClass } from "class-transformer";
import { Pagination } from "@/models/core/Pagination";
import { PaginationCollection } from "@/api/PaginationCollection";
import { RequestAPI } from "@/api/core/RequestAPI";
import AxiosRequest from "@/api/core/AxiosRequest";

export class BaseService {
  private api: RequestAPI;
  protected ctx: any;

  public set nuxtContext(ctx: any) {
    this.ctx = ctx;
  }

  public get nuxtContext() {
    return this.ctx;
  }

  public set apiRequest(api: RequestAPI) {
    this.api = api;
  }

  public get apiRequest() {
    if (!this.api) {
      this.api = new AxiosRequest();
    }
    return this.api;
  }

  protected async getOneOrEmpty<T>(
    Ctor: { new (): T },
    url: string,
    options?: any
  ): Promise<T> {
    try {
      const response = await this.apiRequest.getJSON(url, options);
      const data = response?.data?.data || response?.data;
      const result = !!data
        ? Array.isArray(data) && data.length > 0
          ? data[0]
          : data
        : null;
      return !!result ? plainToClass(Ctor, result) : new Ctor();
    } catch (e) {
      return new Ctor();
    }
  }

  protected async getOneOrFail<T>(
    Ctor: { new (): T },
    url: string,
    options?: any
  ): Promise<T> {
    const response = await this.apiRequest.getJSON(url, options);
    const data = response?.data?.data || response?.data;
    const result = !!data
      ? Array.isArray(data) && data.length > 0
        ? data[0]
        : data
      : null;
    return !!result ? plainToClass(Ctor, result) : new Ctor();
  }

  protected async getOneOrEmptyPost<T>(
    Ctor: { new (): T },
    url: string,
    options?: any,
    postData?: any
  ): Promise<T> {
    return await this.getOneOrEmptyOrThrowErrorPost(
      false,
      Ctor,
      url,
      options,
      postData
    );
  }

  protected async getOneOrErrorPost<T>(
    Ctor: { new (): T },
    url: string,
    options?: any,
    postData?: any
  ): Promise<T> {
    return await this.getOneOrEmptyOrThrowErrorPost(
      true,
      Ctor,
      url,
      options,
      postData
    );
  }

  private async getOneOrEmptyOrThrowErrorPost<T>(
    isSendError = false,
    Ctor: { new (): T },
    url: string,
    options?: any,
    postData?: any
  ): Promise<T> {
    try {
      const response = await this.apiRequest.post(url, options, postData);
      const data = response?.data?.data || response?.data;
      const result = !!data
        ? Array.isArray(data) && data.length > 0
          ? data[0]
          : data
        : null;
      return !!result ? plainToClass(Ctor, result) : new Ctor();
    } catch (err) {
      if (isSendError) {
        throw err;
      } else {
        return new Ctor();
      }
    }
  }

  protected async getArrayOrEmpty<T>(
    ctor: { new (): T },
    url: string,
    params?: any,
    pagination?: Pagination
  ): Promise<T[]> {
    try {
      const response = await this.apiRequest.getJSON(url, params, pagination);
      const data = response?.data?.data || response?.data;
      return !!data ? plainToClass(ctor, Array.from(data)) : [];
    } catch {
      return [];
    }
  }

  protected async getArrayOrEmptyPost<T>(
    ctor: { new (): T },
    url: string,
    params?: any,
    postData?: any
  ): Promise<T[]> {
    try {
      const response = await this.apiRequest.post(url, params, postData);
      const data = response?.data?.data || response?.data;
      return !!data ? plainToClass(ctor, Array.from(data)) : [];
    } catch {
      return [];
    }
  }

  protected async getArrayOrEmptyWithPaginationGet<T>(
    ctor: { new (): T },
    url: string,
    params?: any,
    pagination?: Pagination
  ): Promise<PaginationCollection<T>> {
    return await this.getArrayOrEmptyWithPaginationAny(
      ctor,
      url,
      params,
      pagination,
      false
    );
  }

  protected async getArrayOrEmptyWithPaginationPost<T>(
    ctor: { new (): T },
    url: string,
    params?: any,
    postData?: any,
    pagination?: Pagination
  ): Promise<PaginationCollection<T>> {
    return await this.getArrayOrEmptyWithPaginationAny(
      ctor,
      url,
      params,
      pagination,
      true,
      postData
    );
  }

  private async getArrayOrEmptyWithPaginationAny<T>(
    ctor: { new (): T },
    url: string,
    params?: any,
    pagination?: Pagination,
    isPostMethod = false,
    postData?: any
  ): Promise<PaginationCollection<T>> {
    const paginCollection = new PaginationCollection<T>();

    try {
      let response: any;
      if (isPostMethod) {
        response = await this.apiRequest.post(
          url,
          params,
          postData,
          pagination
        );
      } else {
        response = await this.apiRequest.getJSON(url, params, pagination);
      }

      const data = response?.data?.data || response?.data;
      paginCollection.data = !!data ? plainToClass(ctor, Array.from(data)) : [];
      // Разберемся с пагинацией. Везде по разному, обобщим.
      if (response?.data?.meta) {
        paginCollection.pagination = plainToClass(
          Pagination,
          response?.data?.meta
        );
      }
      if (response?.data?.count > -1) {
        paginCollection.pagination = pagination as Pagination;
        paginCollection.pagination.total = response.data.count;
      }

      paginCollection.seo = response?.data?.seo || {};
    } catch {
      return paginCollection;
    }
    return paginCollection;
  }

  protected getIdBySlug(slug: string) {
    let result = 0;

    if (!!slug && slug.toString().includes("-")) {
      const tryGet = Number(slug.split("-").pop());
      result = Number.isNaN(tryGet) ? 0 : tryGet;
    }

    if (!!slug && result === 0) {
      result = Number(slug);
    }

    return result;
  }
}
