import BaseFavoritesAndShownService from "@/services/BaseFavoritesAndShownService";
import { ServiceLocator } from "@/ServiceLocator";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { HotelModel } from "../Hotels/models/HotelModel";
import { TouristFoldersService } from "../Tourist/TouristFoldersService";
import { SeaTour } from "./models/SeaTour";

export default class TourFavoritesAndShownService extends BaseFavoritesAndShownService {
  public async toogleSeaTourFavor(
    item: HotelModel,
    filterModelForPayLoad: BaseFilterModel
  ) {
    if (false) {
      await ServiceLocator.instance
        .getService(TouristFoldersService)
        .addSeaTourToMainFolder(
          item.sea_tour_id,
          item.id,
          filterModelForPayLoad
        );
    }
    item.isInFavor = !item.isInFavor;
  }

  public addToShown(item: SeaTour, filterModelForPayLoad: BaseFilterModel) {
    if (
      !this.isShownSeaTourExists(item) &&
      item.id > 0 &&
      !!item.hotel &&
      item.hotel.id > 0
    ) {
      const toStore: any = {};
      toStore.id = item.id;
      toStore.hotel_id = item.hotel.id;
      toStore.payload = filterModelForPayLoad;

      const prevVal = window.localStorage.getItem(this.shownKeyName) || "";
      window.localStorage.setItem(
        this.shownKeyName,
        `${prevVal}|${JSON.stringify(toStore)}`
      );
    }
    item.isShown = true;
  }

  public getShowedIdPairs() {
    const showed = this.getShowedList();
    if (!!showed) {
      const ids = showed.map((iter) => {
        return iter.id;
      });
      // eslint-disable-next-line camelcase
      const hotelsIds = showed.map((iter) => {
        return iter.hotel_id;
      });
      return { ids, hotels_ids: hotelsIds };
    }
    return null;
  }

  public updateFavorStatus(items: HotelModel[]) {
    const userAuth = false;
    const userAgency = false;

    if (!!items) {
      items.forEach((iterItem) => {
        iterItem.isInFavor = userAuth
          ? userAgency
            ? iterItem.agency_favorite?.favorable_id === iterItem.sea_tour_id &&
              iterItem.agency_favorite?.hotel_id === iterItem.id
            : iterItem.client_favorite?.favorable_id === iterItem.sea_tour_id &&
              iterItem.client_favorite?.hotel_id === iterItem.id
          : false;
      });
    }
  }

  protected isShownSeaTourExists(item: SeaTour) {
    const vals = this.getShowedList();
    let result = false;
    vals?.forEach((iter) => {
      if (iter.id === item.id && iter.hotel_id === item.hotel.id) {
        result = true;
      }
    });

    return result;
  }
}
