
import { Component, Prop, Vue } from "vue-property-decorator";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import BaseChoosePassenger from "@/components/BaseChoosePassenger.vue";
import BaseDatepicker from "@/components/BaseDatepicker.vue";
import { getModule } from "vuex-module-decorators";
import AppStore from "@/store/AppStore";

@Component({ components: { BaseChoosePassenger,  BaseDatepicker } })
export default class ModalChangeParams extends Vue {
  routeLocal: BaseFilterModel = new BaseFilterModel();

  @Prop()
  showModal: boolean;

  mounted() {
    const query = getModule(AppStore, this.$store).currentRoute.query;
    // @ts-ignore//
    this.routeLocal.date_from = query.date_from;
    // @ts-ignore//
    this.routeLocal.date_to = query.date_to;
    this.routeLocal.child_count = +query.child_count || 0;
    this.routeLocal.adult_count = +query.adult_count || 1;
  }

  onChangeParams() {
    this.$emit("onChangeParams", this.routeLocal);
  }

  childChanged(
    childArray: {
      id: number;
      label: string;
    }[]
  ) {
    this.routeLocal.child_count = childArray.length;
    const agesArr = childArray.map((iter) => +iter.label.split(" ")[0]);
    this.routeLocal.child_ages = [...agesArr];
  }

  beforeOpen() {
    document.documentElement.style.overflowY = "hidden";
  }

  beforeClose() {
    document.documentElement.style.overflowY = "auto";
  }
}
