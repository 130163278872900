/* eslint-disable camelcase */
import { FovoritesSupport } from "@/models/common/FovoritesSupport";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { HotelModel } from "@/modules/Hotels/models/HotelModel";
import { PhotoModel } from "@/modules/Hotels/models/RoomModel";
import { TourServicesModel } from "@/modules/Tours/models/TourServicesModel";

export class ExcursionCategoryModel extends BaseViewModel {
  id: number = 0;
  name: string = "";
}

export class ExcursionCatalogModel extends BaseViewModel {
  tour_categories: ExcursionCategoryModel[];
}

export class ExcursionDateModel {
  id: number = 0;
  tour_id: number = 0;
  start_date = "";
  finish_date = "";
}

export class ExcursionDayModel {
  id: number = 0;
  tour_id: number = 0;
  description: string = "";
  name: string = "";
  photos: PhotoModel[] = [];
  length: string = "";
  created_at: string | Date | null = null;
  updated_at: string | Date | null = null;
}

export class ExcursionTransportDayModel {
  id: number = 0;
  name: string = "";
  created_at: string | Date | null = null;
  updated_at: string | Date | null = null;
}

export class ExcursionPointCityModel {
  id: number = 0;
  name: string = "";
}
export class ExcursionRoutesElModel {
  id: number;
  info = "нет данных";
  start_time = "";
  start_date = "";
  finish_date = ";";
  finish_time = "";
  arrival_day = 0;
  departure_day = 0;

  point_city: {
    id: 0;
    name: "";
  };
}
export class ExcursionRoutesModel extends BaseViewModel {
  start: ExcursionRoutesElModel;
  finish: ExcursionRoutesElModel;
  intermediates: ExcursionRoutesElModel[] = [];
}

export class ExcursionTransportForthPivotModel extends BaseViewModel {
  day_id: number = 0;
  transport_id: number = 0;
}
export class ExcursionTransportForthDayModel extends BaseViewModel {
  id: number = 0;
  name: string = "";
  number: number = 0;
  pivot: ExcursionTransportForthPivotModel;
  short: string = "";
}
export class ExcursionTransportForthTransportationModel extends BaseViewModel {
  id: number = 0;
  kind: number = 0;
  name: string = "";
  rows: number = 0;
  schema: string = "";
  schema_svg: string = "";
  seats: number = 0;
  second_door: boolean = false;
  second_door_length: number = 0;
  second_door_rows: number = 0;
}
export class ExcursionTransportForthModel extends BaseViewModel {
  commission_sum: number = 0;
  commission_type: number = 0;
  days: ExcursionTransportForthDayModel[] = [];
  days_ids: number[] = [];
  duration: number = 0;
  travel_hours: 0;
  finish_date: string = "";
  id: number = 0;
  name: string = "";
  routes: ExcursionRoutesModel;
  schemas: [] = [];
  seat_cost: string = "";
  start_date: string = "";
  transportation: ExcursionTransportForthTransportationModel;
  transportation_id: number = 0;
  data?: null | {
    id: number;
    transport_id: number;
    date: Date;
    name: string | null;
    number: string;
    glonas_url: string | null;
  };

  forthCheckbox = false;
  backCheckbox = false;

  seats: number[] = [];
  total_price: number;
  price_total: number | null;
  agency_commission = 0;

  from_city: { name: string; id: string };
  to_city: { name: string; id: string };

  start_at = "";
  finish_at = "";

  passengers: string;
}

export class ExcursionModel extends FovoritesSupport {
  id = 0;
  name = "";
  seatsCount: number = 0;
  documents: { name: string; url: string }[];
  description = "";
  short_description = "Нет краткого описания";
  info?: string | null = null;
  hotels: HotelModel[] = [];
  hotel: HotelModel;
  transport_back: ExcursionTransportForthModel =
    new ExcursionTransportForthModel();
  transport_forth: ExcursionTransportForthModel =
    new ExcursionTransportForthModel();
  days: ExcursionDayModel[] = [];
  dates: ExcursionDateModel[] = [];
  category: ExcursionDateModel;
  services: TourServicesModel[] = [];
  start_date = "";
  finish_date = "";
  additional_info: {
    description: string;
    title: string;
  }[];
  duration: {
    days: "";
    nights: "";
  };

  video: {
    url: string;
    alt: string;
  };

  price_from = 0;
  old_price_from = 0;
  photos: [];

  trade_offers: {
    name: string;
    icon: string;
  }[] = [];

  people: {
    adult: any;
    child: any;
    total: any;
  };

  nearest_tour_link = "";

  seo_h1: string = "";
  seo_title: string = "";
  seo_description: string = "";
  slug: string | null = "";

  agency_commission = 0;

  excursion_price = 0;
  meal_price = 0;

  is_hotel_selection = false;
}

export class ExcursionTransportSeat {
  id: number = 0;
  // application_id: number = 0;
  // transport_id: number = 0;
  number: number = 0;
  tourist_id: number = 0;
  seats: [] = [];
  transportation: any = {};
}
