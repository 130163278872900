
import { Vue, Component, Prop } from "vue-property-decorator";
import { ExcursionTransportForthModel } from "@/modules/Excursions/models/ExcursionModel";
import { stringToNumber } from "@/utils/StringToNumber";
import { formatDate } from "@/utils/Formaters";

@Component
export default class TourTransport extends Vue {
  @Prop({ default: false })
  forthSelected: boolean;

  @Prop({ default: false })
  backSelected: boolean;

  @Prop({ default: true })
  refuseEnabled: boolean;

  @Prop()
  forthModel: ExcursionTransportForthModel;

  @Prop()
  backModel: ExcursionTransportForthModel;

  upHere = false;

  get backPriceEnabled() {
    return !!this.backModel.total_price;
  }

  get backPrice() {
    return this.convertPrice(this.backModel.total_price?.toString());
  }

  get forthPriceEnabled() {
    return !!this.forthModel.total_price;
  }

  get forthPrice() {
    return this.convertPrice(this.forthModel.total_price?.toString());
  }

  get routeStart() {
    return this.forthModel.routes.start;
  }

  get routeFinish() {
    return this.forthModel.routes.finish;
  }

  get routeBackStart() {
    return this.backModel.routes.start;
  }

  get routeBackFinish() {
    return this.backModel.routes.finish;
  }

  get forthButtonCaption() {
    return !this.forthSelected ? "Выбрать место" : "  Выбрано  ";
  }

  get backButtonCaption() {
    return !this.backSelected ? "Выбрать место" : "  Выбрано  ";
  }

  conveniences = [
    {
      id: 3,
      name: "Аудио-видео система",
      icon: "audio",
    },

    {
      id: 4,
      name: "Кондиционер",
      icon: "condition",
    },

    {
      id: 5,
      name: "Вежливый экипаж",
      icon: "ekipazh",
    },

    {
      id: 6,
      name: "Ортопедические кресла",
      icon: "ortoped",
    },
  ];

  convertPrice(price: string | undefined) {
    return !!price ? stringToNumber(price).toLocaleString("ru-RU") : 0;
  }

  convertDate(date: string) {
    return formatDate(date);
  }

  selectedOrHoverStyle(isBack: boolean) {
    if (this.upHere || (isBack && this.backSelected) || (!isBack && this.forthSelected)) {
      return {
        color: "#3068F7",
      };
    }
  }
}
