/* eslint-disable camelcase */
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { DraftAppModel } from "@/modules/Order/models/ApplicationModel";

export class TicketModel extends BaseViewModel {
  first_name: string = "";
  last_name: string = "";
  full_name: string = "";
  patronymic: string = "";
  birthday_date: string | Date = "";
  document: number = 1;
  document_series: string = "НЕТ";
  document_number: string | number = "";
  citizenship: number = 1;
  gender: number = 1;
  id?: number;
}

export class OrderModel extends BaseViewModel {
  id: number;
  appId = 0;
  tourists: TicketModel[] = [];
  draftModel: DraftAppModel = new DraftAppModel();
  number = "";
  applicationable: { id: number; name: string };
  can_edit: boolean;
  applicationable_type: string;

  status = 2;
  statusName = "";
  phone = "";
  email = "";

  priceTotal: null | number = null;
  priceRooms: null | number = null;
  priceTransport: null | number = null;

  agency_user?: {
    phone: string;
    email: string;
    full_name: string;
  };

  start_date?: any;
  finish_date?: any;
  adult_count = 0;
  child_count = 0;

  people: {
    adult: string;
    child: string;
    total: string;
  };

  duration?: {
    days: string;
    nights: string;
  };

  seats?: {
    transport_id: number;
    number: number | null;
    date: string;
    tourist_id?: number;
    from_city_id: number;
    to_city_id: number;
  }[];

  transport_back: any;
  transport_forth: any;

  documents: { name: string; url: string }[];

  statusColor: string;

  agencyCommission = 0;

  payments_info?: {
    agency?: {
      commission: number;
      total: number;
    };
    paid: number;
    to_pay: number;
    total: string;
    can_pay: boolean;
  };

  manager_name = "";
  manager_phone = "";
  manager_email = "";
  attendant_phone = "";

  agency_user_id?: number;
  payment_status_name?: string;
}
