import Vue from "vue";
import App from "./App.vue";
import services from "@/services/init";
//@ts-ignore
import vSelect from "vue-select";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
//@ts-ignore
import VueDatePicker from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import Vuelidate from "vuelidate";
import BaseInputWrapper from "@/components/BaseInputWrapper.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseInputMask from "@/components/BaseInputMask.vue";
import BaseDataNotFound from "@/components/BaseDataNotFound.vue";
import DirectionCalendar from "@/components/filter/DirectionCalendar.vue";
import SeaTourCalendar from "@/components/filter/SeaTourCalendar.vue";
import BaseCalendar from "@/components/filter/BaseCalendar.vue";
import BaseCarousel from "@/components/BaseCarousel.vue";
import BreadCrumbs from "@/components/BreadCrumbs.vue";
import BaseAccordion from "@/components/BaseAccordion.vue";
import BaseSelect from "@/components/BaseSelect/BaseSelect.vue";
import BaseModalNotice from "@/components/BaseModalNotice.vue";
import BaseService from "@/components/BaseService.vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseSwiper from "@/components/BaseSwiper/BaseSwiper.vue";
import BaseRating from "@/components/BaseRating.vue";
import FilterGroup from "@/components/FilterGroup.vue";
//@ts-ignore
import vClickOutside from "v-click-outside";
//@ts-ignore
import YmapPlugin from "vue-yandex-maps";
import axios from "axios";
import VModal from "vue-js-modal";
import { VTooltip, VPopover, VClosePopover } from "v-tooltip";
import VScroll from "vue-scrollto";
import mask from "@/plugins/vue-imask-directive";
import "vue-js-modal/dist/styles.css";
import "@/assets/index.scss";
import "@/assets/lib.css";
import { store } from "./store/index";
import VueLazyload from "vue-lazyload";
//@ts-ignore
import StarRating from "vue-star-rating";
//@ts-ignore
import VueModal from "@kouts/vue-modal";
import "@kouts/vue-modal/dist/vue-modal.css";
import { default as dayjs } from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import advancedFormat from "dayjs/plugin/advancedFormat";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import utc from "dayjs/plugin/utc";
import "dayjs/locale/ru";
import { Notification } from "element-ui";
//@ts-ignore
import VueCoreVideoPlayer from "vue-core-video-player";
import BaseCountUsers from "@/components/BaseCountUsers.vue";

const link = document.createElement("link");
link.setAttribute(
  "href",
  `${process.env.VUE_APP_STORAGE_URL}/avianna.widget.css`
);
link.setAttribute("rel", "stylesheet");

document.head.prepend(link);

dayjs.locale("ru");
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);

Vue.component("VueModal", VueModal);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  attempt: 1,
  observer: true,
  error: `${process.env.VUE_APP_STORAGE_URL}/public/images/error.png`,
  loading: `${process.env.VUE_APP_STORAGE_URL}/public/images/loading.gif`,
  observerOptions: {
    rootMargin: "0px",
    threshold: 0.1,
  },
});
Vue.use(VueCoreVideoPlayer);
Vue.use(Vuelidate as any);
Vue.prototype.$notify = Notification;

Vue.use(YmapPlugin, {
  apiKey: "42148354-57d0-4317-b044-4d608b198539",
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1",
});

Vue.use(vClickOutside);
Vue.directive("imask", mask);
Vue.use(VModal, {
  dynamic: true,
  injectModalsContainer: true,
  dynamicDefaults: {
    clickToClose: true,
    adaptive: true,
    maxWidth: 568,
    height: "auto",
    scrollable: true,
  },
});

Vue.use(VScroll);

Vue.component("StarRating", StarRating);
Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("VPopover", VPopover);
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.withCredentials = true;
Vue.prototype.$axios = axios;

Vue.component("BaseDataNotFound", BaseDataNotFound);
Vue.component("BaseSwiper", BaseSwiper);
Vue.component("BaseInputMask", BaseInputMask);
Vue.component("BaseInputWrapper", BaseInputWrapper);
Vue.component("BaseButton", BaseButton);
Vue.component("BreadCrumbs", BreadCrumbs);
Vue.component("BaseModalNotice", BaseModalNotice);
Vue.component("BaseService", BaseService);
Vue.component("BaseInput", BaseInput);
Vue.component("BaseSelect", BaseSelect);
Vue.component("FilterGroup", FilterGroup);
Vue.component("DirectionCalendar", DirectionCalendar);
Vue.component("BaseCalendar", BaseCalendar);
Vue.component("SeaTourCalendar", SeaTourCalendar);
Vue.component("BaseRating", BaseRating);
Vue.component("BaseLoading", BaseLoading);
Vue.component("BaseAccordion", BaseAccordion);
Vue.component("BaseCarousel", BaseCarousel);
Vue.component("BaseCountUsers", BaseCountUsers);

Vue.use(VueDatePicker, {
  lang: "ru",
});
Vue.component("VSelect", vSelect);
Vue.component("Multiselect", Multiselect);
Vue.use(services);

Vue.prototype.$getImage = (path: string) => {
  return `${process.env.VUE_APP_STORAGE_URL}/public${path}`;
};
declare module "vue/types/vue" {
  interface Vue {
    $getImage: (e: string) => typeof e;
    $globalParams: any;
  }
}
Vue.config.productionTip = true;

const component = document.querySelector("#aviannaWidget");
const attrs = component?.getAttributeNames().reduce((acc, name) => {
  return { ...acc, [name]: component.getAttribute(name) };
}, {});

Vue.prototype.$globalParams = attrs;

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#aviannaWidget", false);
