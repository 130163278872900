export class HeadMetaTagsBuilder {
  public static createHead() {
    const meta = "";

    return {
      title: "",
      meta: [
        {
          hid: "og:url",
          property: "og:url",
        },
        {
          hid: "og:type",
          property: "og:type",
          content: "website",
        },
        {
          hid: "og:title",
          property: "og:title",
        },
        {
          hid: "og:description",
          property: "og:description",
        },
        {
          hid: "og:image",
          property: "og:image",
        },
        {
          hid: "description",
          name: "description",
        },
        {
          hid: "Keywords",
          name: "Keywords",
        },
      ],
    };
  }

  private static normalizeSeoMetaObject() {
    return {};
  }
}
