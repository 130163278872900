
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import dayjs from "dayjs";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import TheContainer from "@/components/TheContainer.vue";
import TheLeftBar from "@/components/TheLeftBar.vue";
import TheContent from "@/components/TheContent.vue";
import ChooseSeatsModalExcursion from "@/components/ChooseSeatsModalExcursion.vue";
import AppStore from "@/store/AppStore";
import BaseFilter from "@/components/filter/BaseFilter.vue";
import { decOfNum } from "@/utils/DecOfNum";
import { Pagination } from "@/models/core/Pagination";
import { TourType } from "@/models/TourType";
import { ApplicationService } from "@/modules/Order/ApplicationService";
import { getCityByGeolocation } from "@/utils/Geolocation";
import { CommonService } from "@/services/CommonService";
import { DirectionService } from "../DirectionService";
import { DirectionsCardModel } from "../models/DirectionsCardModel";
import WarningModal from "../components/WarningModal.vue";
import DirectionsFilter from "../components/DirectionsFilter.vue";
import DirectionsCard from "../components/DirectionsCard.vue";

@Component({
  components: {
    ChooseSeatsModalExcursion,
    BaseFilter,
    TheContainer,
    TheLeftBar,
    TheContent,
    DirectionsCard,
    DirectionsFilter,
    WarningModal,
  },
})
export default class DirectionsPage extends Vue {
  directionList: DirectionsCardModel[] = [];
  fromSelectedDirection: DirectionsCardModel = new DirectionsCardModel();
  backSelectedDirection: DirectionsCardModel = new DirectionsCardModel();

  selectedSeats: number[] = [];
  backSelectedSeats: number[] = [];
  isBackLocal = false;
  isBack = false;

  isChoosePlaceModal = false;
  showWarningModal = false;
  loadingState = false;

  itemsLimit = 10;
  pagination: Pagination = new Pagination();
  topFilterModel: BaseFilterModel = new BaseFilterModel();
  extendedFilterModel: BaseFilterModel = new BaseFilterModel();

  sort: number | null = null;

  optionsSelect = [
    { id: 0, label: "По цене (по возрастанию)" },
    { id: 1, label: "По цене (по убыванию)" },
  ];

  get passangersCount() {
    return +this.topFilterModel.adult_count + +this.topFilterModel.child_count;
  }

  get directionFrom() {
    return this.topFilterModel.from;
  }

  get directionTo() {
    return this.topFilterModel.direction;
  }

  get directionDate() {
    return dayjs(this.topFilterModel.start_date).format("DD MMMM YYYY");
  }

  get selectedDirection() {
    return !this.isBack
      ? this.fromSelectedDirection
      : this.backSelectedDirection;
  }

  get fromCityId() {
    return this.topFilterModel.from_city_id;
  }

  get toCityId() {
    return this.topFilterModel.to_city_id;
  }

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  async mounted() {
    try {
      this.topFilterModel = BaseViewModel.fromPlainIfNotClass(
        BaseFilterModel,
        this.currentRoute.query
      );
      this.syncLeftFilter(this.topFilterModel);
      this.pagination.perPage = this.itemsLimit;
      await this.updateDataList(this.topFilterModel, this.pagination);
      if (!this.topFilterModel.from_city_id) {
        const city = await getCityByGeolocation();
        if (!!city) {
          const cities = await this.$serviceLocator
            .getService(CommonService)
            .getCities(city);
          if (!!cities && cities.length > 0) {
            this.topFilterModel.from = cities[0].name;
            this.topFilterModel.from_city_id = cities[0].id;
          }
        }
      }
    } catch (err: any) {}
  }

  async updateDataList(filterModel: BaseFilterModel, pagination: Pagination) {
    this.loadingState = true;
    const filterModelAsPojo = filterModel.cloneAndRemoveEmptyValue([
      "child_count",
    ]);
    const dataListWithPagination = await this.$serviceLocator
      .getService(DirectionService)
      .findDirections(filterModelAsPojo, pagination);
    this.directionList = dataListWithPagination.data;
    this.pagination = dataListWithPagination.pagination;
    this.loadingState = false;
  }

  bayTicket(model: DirectionsCardModel) {
    if (this.isOneWay && !this.isBackLocal) {
      this.fromSelectedDirection = model;
      model.from_city_id = this.topFilterModel.from_city_id;
      model.to_city_id = this.topFilterModel.to_city_id;
      this.isChoosePlaceModal = true;
    } else {
      model.from_city_id = this.topFilterModel.from_city_id;
      model.to_city_id = this.topFilterModel.to_city_id;

      if (!this.isBackLocal) {
        this.fromSelectedDirection = model;
      } else {
        this.backSelectedDirection = model;
      }
      this.isBack = this.isBackLocal;
      this.isChoosePlaceModal = true;
    }
  }

  async seatsSelected(selectedSeats: number[]) {
    this.selectedSeats = [...selectedSeats];

    if (this.isOneWay) {
      await this.createDraftApp();
    } else {
      const reverseFilter = this.topFilterModel.cloneAndRemoveEmptyValue([
        "child_count",
        "price",
      ]);

      this.topFilterModel.from = reverseFilter.direction;
      this.topFilterModel.from_city_id = reverseFilter.to_city_id;

      this.topFilterModel.direction = reverseFilter.from;
      this.topFilterModel.to_city_id = reverseFilter.from_city_id;

      this.topFilterModel.finish_date = reverseFilter.finish_date;
      this.topFilterModel.start_date = reverseFilter.finish_date;

      this.showSearchResultFromTopFilter(TourType.ROUTE, this.topFilterModel);
      this.isBackLocal = true;
    }
  }

  async backSeatsSelected(selectedSeats: number[]) {
    this.backSelectedSeats = [...selectedSeats];
    await this.createDraftApp();
  }

  async createDraftApp() {
    try {
      const selectedSeats = this.selectedSeats.map((iter) => {
        return {
          transport_id: this.fromSelectedDirection.id,
          number: iter,
          date: this.fromSelectedDirection.routes.start.start_date,
          from_city_id: this.fromSelectedDirection.from_city_id,
          to_city_id: this.fromSelectedDirection.to_city_id,
        };
      });
      this.fromSelectedDirection.seats = [...selectedSeats];

      if (
        !!this.backSelectedSeats &&
        this.backSelectedSeats.length &&
        this.backSelectedSeats.length > 0
      ) {
        const backSelectedSeats = this.backSelectedSeats.map((iter) => {
          return {
            transport_id: this.backSelectedDirection.id,
            number: iter,
            date: this.backSelectedDirection.routes.start.start_date,
            from_city_id: this.backSelectedDirection.from_city_id,
            to_city_id: this.backSelectedDirection.to_city_id,
          };
        });
        this.backSelectedDirection.seats = [...backSelectedSeats];
      }

      const result = await this.$serviceLocator
        .getService(ApplicationService)
        .addDirectionDraft(
          this.topFilterModel.child_ages,
          this.fromSelectedDirection,
          this.backSelectedDirection,
          +this.topFilterModel.adult_count,
          +this.topFilterModel.child_count
        );
      getModule(AppStore, this.$store).setRoute({
        name: "order",
        query: this.currentRoute.query,
        params: {id: result.data.id},
      });
    } catch (err: any) {}
  }

  async loadMore() {
    Pagination.createLoadMorePagination(this.pagination);
    const result = await this.$serviceLocator
      .getService(DirectionService)
      .findDirections(this.currentRoute.query, this.pagination);
    this.directionList = [...this.directionList, ...result.data];
  }

  async showSearchResultFromTopFilter(
    _type: TourType,
    filterModel: BaseFilterModel
  ) {
    this.topFilterModel.direction = filterModel.direction;
    this.topFilterModel.start_date = filterModel.start_date;
    this.topFilterModel.finish_date = filterModel.finish_date;
    this.topFilterModel.to_city_id = filterModel.to_city_id;
    this.topFilterModel.from = filterModel.from;
    this.topFilterModel.from_city_id = filterModel.from_city_id;
    this.topFilterModel.child_count = filterModel.child_count;
    this.topFilterModel.child_ages = [...filterModel.child_ages];
    this.topFilterModel.adult_count = filterModel.adult_count;

    this.syncLeftFilter(filterModel);

    const filterModelAsPojo = filterModel.cloneAndRemoveEmptyValue([
      "child_count",
    ]);
    Pagination.clearSelectedPages(this.pagination);
    this.pagination.currentPage = 1;

    this.updateRouteQuery(filterModelAsPojo);
  }

  @Watch("extendedFilterModel", { deep: true })
  async onExtendedFilterModelChanged() {
    const extendedFilterModel = BaseViewModel.fromPlainIfNotClass(
      BaseFilterModel,
      this.extendedFilterModel
    ) as BaseFilterModel;
    this.topFilterModel = BaseViewModel.fromPlainIfNotClass(
      BaseFilterModel,
      this.topFilterModel
    ) as BaseFilterModel;
    this.topFilterModel.price_from = extendedFilterModel.price_from;
    this.topFilterModel.price_to = extendedFilterModel.price_to;
    this.topFilterModel.price = extendedFilterModel.price;

    const combinedFilter = this.topFilterModel.cloneAndRemoveEmptyValue([
      "child_count",
      "price",
    ]);
    this.updateRouteQuery(combinedFilter);

    Pagination.clearSelectedPages(this.pagination);
    this.pagination.currentPage = 1;

    await this.updateDataList(this.topFilterModel, this.pagination);
  }

  syncLeftFilter(filterModel: BaseFilterModel) {
    this.extendedFilterModel.category_types = [...filterModel.category_types];
    this.extendedFilterModel.price_from = filterModel.price_from;
    this.extendedFilterModel.price_to = filterModel.price_to;
    this.extendedFilterModel.name = filterModel.name;
    this.extendedFilterModel.meal_category_types = [
      ...filterModel.meal_category_types,
    ];
    this.extendedFilterModel.sea_distances = [...filterModel.sea_distances];
    this.extendedFilterModel.services = [...filterModel.services];
  }

  updateRouteQuery(filterModelAsPojo: any) {
    getModule(AppStore, this.$store).setRoute({
      name: "directionsList",
      query: filterModelAsPojo,
      params: {},
    });
  }

  onSortModeChanged({ id }) {
    this.extendedFilterModel.price = id;
  }

  generateText(count: number) {
    return (
      count +
      " " +
      decOfNum(count, ["предложение", "предложения", "предложений"])
    );
  }

  continueBay() {
    this.showWarningModal = false;
  }

  get isOneWay() {
    return (
      this.topFilterModel.start_date === this.topFilterModel.finish_date ||
      !this.topFilterModel.finish_date
    );
  }
}
