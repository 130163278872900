import { BaseFilterModel } from "@/models/BaseFilterModel";
import { FovoritesSupport } from "@/models/common/FovoritesSupport";
import { ServiceLocator } from "@/ServiceLocator";
import BaseFavoritesAndShownService from "@/services/BaseFavoritesAndShownService";
import { TouristFoldersService } from "../Tourist/TouristFoldersService";

export default class HotelFavoritesAndShownService extends BaseFavoritesAndShownService {
  async toogleFavorites(item: FovoritesSupport, filterModelForPayLoad: BaseFilterModel) {
    return await ServiceLocator.instance
      .getService(TouristFoldersService)
      .addToMainFolder(item.id, filterModelForPayLoad, "hotels");
  }
}
