
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class WarningModal extends Vue {
  @Prop({ required: true })
  showModal: boolean;

  beforeOpen() {
    document.documentElement.style.overflowY = "hidden";
  }

  beforeClose() {
    document.documentElement.style.overflowY = "auto";
  }
}
