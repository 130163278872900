
import { Component, Vue, Watch } from "vue-property-decorator";
import BaseFilter from "@/components/filter/BaseFilter.vue";
import ExcursionList from "@/modules/Excursions/components/ExcursionList.vue";
import ExcursionFilter from "@/modules/Excursions/components/ExcursionFilter.vue";
import TheContainer from "@/components/TheContainer.vue";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { Pagination } from "@/models/core/Pagination";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { TourType } from "@/models/TourType";
import ExcursionFavoritesAndShownService from "./ExcursionFavoritesAndShownService";
import { ExcursionModel } from "./models/ExcursionModel";
import { ExcursionService } from "./ExcursionService";
import AppStore from "@/store/AppStore";
import { getModule } from "vuex-module-decorators";

@Component({
  components: {
    BaseFilter,
    ExcursionList,
    ExcursionFilter,
    TheContainer,
  },
})
export default class ExcursionListPage extends Vue {
  pagination: Pagination = new Pagination();
  extendedFilterModel: BaseFilterModel = new BaseFilterModel();
  topFilterModel: BaseFilterModel = new BaseFilterModel();
  excursionsList: ExcursionModel[] = [];
  showedExcursions: ExcursionModel[] = [];

  loadingState = false;

  showMoreEnabled = false;

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  async mounted() {
    try {
      this.topFilterModel = BaseViewModel.fromPlainIfNotClass(
        BaseFilterModel,
        this.currentRoute.query
      );
      this.extendedFilterModel.categories = [...this.topFilterModel.categories];
      this.extendedFilterModel.price_from = this.topFilterModel.price_from;
      this.extendedFilterModel.price_to = this.topFilterModel.price_to;
      this.extendedFilterModel.name = this.topFilterModel.name;

      await this.updateHotelList(this.topFilterModel, this.pagination);
    } catch (err: any) {}
  }

  @Watch("extendedFilterModel", { deep: true })
  async onExtendedFilterModelChanged() {
    const extendedFilterModel = BaseViewModel.fromPlainIfNotClass(
      BaseFilterModel,
      this.extendedFilterModel
    ) as BaseFilterModel;

    this.topFilterModel = BaseViewModel.fromPlainIfNotClass(
      BaseFilterModel,
      this.topFilterModel
    ) as BaseFilterModel;
    this.topFilterModel.categories = [...extendedFilterModel.categories];
    this.topFilterModel.price_from = extendedFilterModel.price_from;
    this.topFilterModel.price_to = extendedFilterModel.price_to;
    this.topFilterModel.name = extendedFilterModel.name;

    this.topFilterModel.price = extendedFilterModel.price;
    this.topFilterModel.favorite = extendedFilterModel.favorite;
    const combinedFilter = this.topFilterModel.cloneAndRemoveEmptyValue([
      "child_count",
      "price",
      "favorite",
    ]);

    this.updateRouteQuery(combinedFilter);

    Pagination.clearSelectedPages(this.pagination);
    this.pagination.currentPage = 1;
    await this.updateHotelList(this.topFilterModel, this.pagination);
  }

  @Watch("currentRoute.query", { deep: true })
  async refresh() {
    await this.updateHotelList(this.topFilterModel, this.pagination);
  }

  //  При обновлении фильтра, если на той же странице  - по событию от фильтра
  async showSearchResultFromTopFilter(
    _type: TourType,
    filterModel: BaseFilterModel
  ) {
    // Делаем из модели фильтра POJO (чистоый JS-объект)
    const filterModelAsPojo = filterModel.cloneAndRemoveEmptyValue([
      "child_count",
      "price",
      "favorite",
    ]);

    this.extendedFilterModel.name = filterModel.name || null;
    this.topFilterModel.name = filterModel.name || null;
    this.topFilterModel.direction = filterModel.direction || "";
    this.topFilterModel.from = filterModel.from || "";
    this.topFilterModel.start_date_from = filterModel.start_date_from;
    this.topFilterModel.start_date_to = filterModel.start_date_to;
    this.topFilterModel.duration_from = filterModel.duration_from;
    this.topFilterModel.duration_to = filterModel.duration_to;
    this.topFilterModel.from_city_id = filterModel.from_city_id;
    this.topFilterModel.to_city_id = filterModel.to_city_id;
    this.topFilterModel.child_count = filterModel.child_count;
    this.topFilterModel.adult_count = filterModel.adult_count;
    this.topFilterModel.child_ages = [...filterModel.child_ages];
    // Чистим модель пагинации
    Pagination.clearSelectedPages(this.pagination);
    this.pagination.currentPage = 1;
    // Обновляем строку в браузере (для SEO)
    this.updateRouteQuery(filterModelAsPojo);
  }

  updateRouteQuery(filterModelAsPojo: any) {
    getModule(AppStore, this.$store).setRoute({
      name: "excursionsList",
      query: filterModelAsPojo,
      params: {},
    });
  }

  async updateHotelList(filterModel: BaseFilterModel, pagination: Pagination) {
    this.loadingState = true;
    const filterModelAsPojo = filterModel.cloneAndRemoveEmptyValue([
      "child_count",
      "price",
      "favorite",
    ]);
    const hotelListWithPagination = await this.$serviceLocator
      .getService(ExcursionService)
      .findhExcursions(filterModelAsPojo, pagination);
    this.excursionsList = hotelListWithPagination.data;
    this.pagination = hotelListWithPagination.pagination;

    this.showMoreEnabled = this.pagination.total > this.excursionsList.length;

    this.loadingState = false;
  }

  async loadMore() {
    Pagination.createLoadMorePagination(this.pagination);
    const result = await this.$serviceLocator
      .getService(ExcursionService)
      .findhExcursions(this.currentRoute.query, this.pagination);
    this.excursionsList = [...this.excursionsList, ...result.data];
    this.showMoreEnabled = this.pagination.total > this.excursionsList.length;
  }

  onSortModeChanged({ id }) {
    if (id === 1) {
      this.extendedFilterModel.favorite = 0;
      this.extendedFilterModel.price = null;
    }
    if (id === 2) {
      this.extendedFilterModel.favorite = 1;
      this.extendedFilterModel.price = null;
    }
    if (id === 3) {
      this.extendedFilterModel.price = 0;
      this.extendedFilterModel.favorite = null;
    }
    if (id === 4) {
      this.extendedFilterModel.price = 1;
      this.extendedFilterModel.favorite = null;
    }
  }
}
