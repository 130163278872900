/* eslint-disable camelcase */
import { FolderItemDef } from "./FolderItemDef";
import { BaseViewModel } from "@/models/core/BaseViewModel";

export class TouristFolderModel extends BaseViewModel {
  id: number = 0;
  name: string = "";
  update_at?: string | Date = "";
  created_at: string | Date = "";
  favorites: FolderItemDef[] = [];
  image_left?: string = "";
  image_right?: string = "";
}
