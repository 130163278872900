
import "vue-custom-scrollbar/dist/vueScrollbar.css";
import { Component, Prop, Vue } from "vue-property-decorator";
import { ExcursionRoutesElModel } from "../models/ExcursionModel";
const vueCustomScrollbar = () => import("vue-custom-scrollbar");

@Component({ components: { vueCustomScrollbar } })
export default class ModalIntermediatePoints extends Vue {
  @Prop()
  showModal: boolean;

  @Prop()
  points: any[];

  @Prop()
  intermediatePoints: ExcursionRoutesElModel[];

  beforeOpen() {
    document.documentElement.style.overflowY = "hidden";
  }

  beforeClose() {
    document.documentElement.style.overflowY = "auto";
  }
}
