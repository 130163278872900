
import { Component, Vue, Prop } from "vue-property-decorator";
import { required, email } from "vuelidate/lib/validators";
import TheContainer from "@/components/TheContainer.vue";
import FormTicket from "@/modules/Order/components/FormTicket.vue";
import TicketInfo from "@/modules/Order/components/TicketInfo.vue";
import ExcursionHotel from "@/modules/Excursions/components/ExcursionHotel.vue";
import { phoneNumberMask } from "@/utils/InputMaskDefinitions";
import AppStore from "@/store/AppStore";
import ModalHotelMore from "@/modules/Excursions/components/ModalHotelMore.vue";
import FormLeftSide from "@/modules/Order/components/FormLeftSide.vue";
import EmployeeSearchAutocomplete from "@/modules/Agency/EmployeeSearchAutocomplete.vue";
import { EmployeeModel } from "../Agency/models/EmployeeModel";
import { ApplicationService } from "./ApplicationService";
import { OrderModel, TicketModel } from "./models/OrderModel";
import { Validations } from "vuelidate-property-decorators";
import { getModule } from "vuex-module-decorators";
import EditTouristModal from "./components/EditTouristModal.vue";
import { TouristEditModel } from "../Tourist/models/TouristModel";

@Component({
  components: {
    TheContainer,
    FormTicket,
    ExcursionHotel,
    ModalHotelMore,
    FormLeftSide,
    TicketInfo,
    EmployeeSearchAutocomplete,
    EditTouristModal,
  },
})
export default class OrderPage extends Vue {
  @Prop()
  id: number;

  toursitEditModal: boolean = false;
  toursitsModel: TicketModel[] = [];

  readOnlyMode = false;
  chatVisible = false;
  chatData: any = [];
  totalPrice = 0;
  chatAppId: number | null = null;
  isOpenMoreHotel = false;
  openMoreHotelRoom: any = null;
  orderModel: OrderModel = new OrderModel();
  needValidateTourist = false;
  touristFormsValidCount = 0;
  editableToursit: TouristEditModel | null = null;

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  get oldRoute() {
    return getModule(AppStore, this.$store).oldRoute;
  }

  async mounted() {
    this.loadBreadCrumbList();
    try {
      this.orderModel = await this.$serviceLocator
        .getService(ApplicationService)
        .getOrderByDraft(this.currentRoute.params.id);
      if (this.orderModel.status !== 1) {
        this.readOnlyMode = true;
      }
      this.toursitsModel = this.orderModel.tourists;
    } catch (err: any) {
      throw err;
    }
  }

  async updateTourist(toursit: TicketModel) {
    const idx = this.toursitsModel.findIndex((el) => el.id === toursit.id);
    this.toursitsModel[idx] = toursit;
    this.$serviceLocator
      .getService(ApplicationService)
      .updateTourists(this.toursitsModel, this.orderModel.appId);
  }

  async getMessages(id: number) {
    const result = await this.$serviceLocator
      .getService(ApplicationService)
      .getChatApplication(id);
    this.chatData = result.data;
  }

  onMenagerSelect(model: EmployeeModel) {
    this.orderModel.manager_name = model.name || "нет";
    this.orderModel.manager_phone = model.phone || "+71111111111";
    this.orderModel.manager_email = model.email || "no@mail.ru";
    this.orderModel.agency_user_id = model.id;
  }

  editTourist(iter: TouristEditModel) {
    this.editableToursit = structuredClone(iter);
    this.toursitEditModal = true;
  }

  onFormTicketValid() {
    this.touristFormsValidCount++;
  }

  totlaPriceChanged(val) {
    this.totalPrice = val;
  }

  get isUserAgency() {
    return false;
  }

  get agencyCommissionTotal() {
    return this.orderModel?.payments_info?.agency?.commission || 0;
  }

  get accommodations() {
    return this.orderModel.draftModel.accommodations;
  }

  roomModel(accommodation: any) {
    const room = accommodation.room;
    room.duration = accommodation.duration;
    room.price = accommodation.price_total;
    room.total_persons =
      this.orderModel.adult_count + this.orderModel.child_count;
    return room;
  }

  openMoreHotel(accommodation: any) {
    this.openMoreHotelRoom = this.roomModel(accommodation);
    this.isOpenMoreHotel = true;
  }

  get mainCaption() {
    if (this.readOnlyMode) {
      return "Подробнее о заказе";
    }
    if (
      this.orderModel.draftModel.applicationable_type?.endsWith("Transport")
    ) {
      return "Оформление проезда";
    }
    return !!this.orderModel.draftModel.applicationable_type?.endsWith("Tour")
      ? "Оформление тура"
      : "Бронирование отеля";
  }

  get passangersHeader() {
    return this.orderModel.draftModel.applicationable_type?.endsWith(
      "Transport"
    )
      ? "Введите данные всех пассажиров"
      : "Введите данные всех участников тура";
  }

  get passengersReadOnlyHeader() {
    return this.orderModel.draftModel.applicationable_type?.endsWith(
      "Transport"
    )
      ? "Пассажиры"
      : "Участники тура";
  }

  get phoneMask() {
    return phoneNumberMask;
  }

  loadBreadCrumbList() {
    const breadCrumbList = [
      {
        title: this.oldRoute.title,
        routeName: this.oldRoute.name,
        params: this.oldRoute.params,
        query: this.oldRoute.query,
      },
      { title: "Оформление заказа" },
    ];

    getModule(AppStore, this.$store).updateBreadCrumbList(breadCrumbList);
  }

  hotelStartDate(accommodation: any) {
    return accommodation.start_at;
  }

  durationAndPeople(accommodation: any) {
    return (
      accommodation.duration?.nights +
      ", " +
      this.orderModel.people?.adult +
      ", " +
      this.orderModel.people?.child
    );
  }

  get hotelPeople() {
    return this.orderModel.people;
  }

  async saveOrder() {
    this.touristFormsValidCount = 0;
    this.needValidateTourist = !this.needValidateTourist;

    await this.$nextTick();
    if (this.touristFormsValidCount !== this.orderModel.tourists.length) {
      this.$notify.warning("Введите данные верно !");
      return;
    }

    if (!this.orderModel.agency_user_id && this.isUserAgency) {
      this.$notify.warning("Укажите менеджера !");
      return;
    }

    this.$v.$touch();
    if (this.$v.$invalid) {
      this.$notify.warning("Введите данные верно !");
      return;
    }
    try {
      const res = await this.$serviceLocator
        .getService(ApplicationService)
        .saveOrder(this.orderModel);
      if (res.status === 200) {
        this.$notify({
          title: "Ваш заказ успешно оформлен",
          message: "Менеджер свяжется с вами для подтверждения",
          type: "success",
          duration: 3000,
        });
        setTimeout(() => {
          getModule(AppStore, this.$store).setRoute({
            name: null,
            query: {},
            params: {},
          });
        }, 3000);
      }
    } catch (error: any) {
      const err = error?.data?.message;
      const msg =
        typeof err[Object.keys(err)?.[0]] === "string"
          ? err[Object.keys(err)?.[0]]
          : err[Object.keys(err)?.[0]]?.[0] ||
            "Произошла ошибка при форомлении заказа";
      this.$notify({
        title: "Ошибка",
        message: msg,
        type: "error",
        duration: 3000,
      });
      if (error?.status === 422 && error.data?.message?.tourists) {
        this.$notify.warning(error.data?.message?.tourists[0]);
      }
    }
  }
  @Validations()
  validations() {
    const validations: any = {
      phone: { required },
      email: { required, email },
    };

    if (this.isUserAgency) {
      validations.manager_name = { required };
      validations.manager_phone = { required };
      validations.manager_email = { required, email };
    }

    return { orderModel: validations };
  }
}
