/* eslint-disable camelcase */
import { BaseViewModel } from "@/models/core/BaseViewModel";

export class FolderItemDef extends BaseViewModel {
  id: number;
  type = "App\\Models\\Tour";
  favorable: any;

  hotel: {
    id: number;
    name: string;
  };

  payload: any;
}
