import { Pagination } from "@/models/core/Pagination";

// FIXME: Унаследоваться от ApiResponse, а то нет статуса и statusText
export class PaginationCollection<T> {
  data: T[] = [];
  status = 0;
  statusText = "";
  pagination = new Pagination();
  seo: { [key: string]: string } = {};
}
