
import { Vue, Component, Prop } from "vue-property-decorator";
import { ObjType, RoomModel } from "@/modules/Hotels/models/RoomModel";
import { formatDateStandart, dayName } from "@/utils/Formaters";

@Component
export default class HotelRooms extends Vue {
  opened = false;
  upHere = false;

  @Prop()
  room: RoomModel;

  @Prop({ default: false })
  isCatalog: boolean;

  @Prop({ default: false })
  roomSelected: boolean;

  @Prop()
  people: string;

  @Prop({ default: false })
  isTour: boolean;

  openReserveAutobus(room) {
    this.$emit("to-book", room);
  }

  arrayToText(array: ObjType[]) {
    const text: string[] = [];

    array.forEach((element: ObjType) => {
      text.push(element.name);
    });

    return text.join(", ");
  }

  get roomPrice() {
    return this.room.price?.toLocaleString("ru-RU") || null;
  }

  convertDate(date: string) {
    return formatDateStandart(date);
  }

  convertDateShort(date: string) {
    return formatDateStandart(date);
  }

  getDayName(date: string) {
    return dayName(date);
  }

  get firstMeals() {
    return !!this.room.meal ? this.room.meal.name : "не указано";
  }

  get mealsClass() {
    return !!this.room.meal ? "meals_color_green" : "";
  }

  get selectedOrHoverStyle() {
    if (this.opened || this.upHere || this.roomSelected) {
      return {
        color: "#3068F7",
      };
    }
  }

  get selectedBorderStyle() {
    if (this.roomSelected) {
      return {
        border: "0.5px solid #3068F7",
      };
    }
  }

  get buttonText() {
    return this.roomSelected ? " Выбран " : "Выбрать номер";
  }
}
