
import { Vue, Component, Prop } from "vue-property-decorator";
import { ExcursionCategoryModel } from "../models/ExcursionCategoryModel";
import { ExcursionService } from "../ExcursionService";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { numberMask } from "@/utils/InputMaskDefinitions";

@Component
export default class ExcursionFilter extends Vue {
  @Prop()
  filterModel: BaseFilterModel;

  showMobile = false;

  private categories: ExcursionCategoryModel[] = [];

  async mounted() {
    this.categories = await this.$serviceLocator
      .getService(ExcursionService)
      .getExcursionsCategories();
  }

  get numberMask() {
    return numberMask;
  }
}
