import { stringify } from "query-string";
import { PaginationCollection } from "@/api/PaginationCollection";
import { Pagination } from "@/models/core/Pagination";
import { BaseService } from "@/services/BaseService";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { DirectionsCardModel } from "./models/DirectionsCardModel";

export class DirectionService extends BaseService {
  public async findDirections(queryObject: any, pagination: Pagination): Promise<PaginationCollection<DirectionsCardModel>> {
    if (!queryObject.finish_date) {
      queryObject.finish_date = queryObject.start_date;
    }
    return await this.getArrayOrEmptyWithPaginationGet(
      DirectionsCardModel,
      `search-module/transports/search/?${stringify(queryObject, {
        arrayFormat: "bracket",
      })}`,
      {},
      pagination
    );
  }

  public async getDirectionCalendar(queryObject: any): Promise<{ date: string; price: number }[]> {
    const url = `search-module/transports/calendar?${stringify(queryObject, {
      arrayFormat: "bracket",
    })}`;
    return (await this.getArrayOrEmpty(BaseViewModel, url)) as any;
  }
}
