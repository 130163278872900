
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class EditTouristModal extends Vue {
  @Prop()
  showModal: boolean;

  @Prop()
  title: string;
}
