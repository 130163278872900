
import { Vue, Component, Prop } from "vue-property-decorator";
import { ExcursionDayModel } from "../models/ExcursionModel";
@Component
export default class ExcursionProgram extends Vue {
  @Prop({ required: true })
  program: ExcursionDayModel;

  @Prop({ required: true })
  dayNumber: number;

  showModal = false;
}
