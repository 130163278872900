
import { Component, Vue, Prop } from "vue-property-decorator";
import SelectOption from "./SelectOption";

type SelectValueObg = {
  [key: string]: string | number;
};

@Component({
  inheritAttrs: false,
})
export default class BaseSelect extends Vue {
  @Prop({ default: false })
  required;

  @Prop()
  errorText: string;

  @Prop({ default: false })
  hasError: boolean;

  @Prop({ default: "Выберите значение" })
  placeholder: string;

  @Prop({ default: false })
  multiple: boolean;

  @Prop()
  value: string | number | SelectValueObg;

  @Prop()
  label: string;

  @Prop({ default: "label" })
  labelName: string;

  @Prop({ default: "" })
  labelValue: string;

  @Prop()
  help: string;

  @Prop({ default: false })
  clearable: boolean;

  @Prop({ default: false })
  searchable: boolean;

  @Prop({ required: true, default: () => [] })
  options: SelectOption[];

  @Prop({ default: () => {} })
  reduce: string | number;

  @Prop()
  isSimple: boolean;

  @Prop()
  isDropdownRight: boolean;

  @Prop({ default: true })
  isArrow: boolean;

  @Prop({ default: false })
  isSmall: boolean;

  get initValue() {
    return this.value;
  }

  set initValue(newVal) {
    this.$emit("input", newVal);
  }
}
