
import { Component, Prop, Vue } from "vue-property-decorator";
// import { SplideOptions } from "@splidejs/splide/src/js/splide.d";
import { PhotoModel } from "@/modules/Hotels/models/RoomModel";

@Component
export default class BaseModalGallery extends Vue {
  @Prop()
  images: PhotoModel[];

  @Prop()
  showModal: boolean;

  $refs!: {
    primary: any;
    secondary: any;
  };

  primaryOptions: SplideOptions = {
    type: "loop",
    // width: 800,
    pagination: false,
    arrows: false,
    cover: true,
  };

  secondaryOptions: SplideOptions = {
    type: "slide",
    rewind: true,
    // width: 800,
    gap: 17,
    pagination: false,
    fixedWidth: 131,
    fixedHeight: 73,
    cover: true,
    focus: "center",
    isNavigation: true,
    // arrows: false,
    classes: {
      arrows: "splide__arrows RoomImages__arrows",
      arrow: "splide__arrow RoomImages__arrow",
      prev: "splide__arrow--prev RoomImages__prev",
      next: "splide__arrow--next RoomImages__next",
    },
  };

  opening() {
    this.$refs.primary.sync(this.$refs.secondary.splide);
  }

  beforeOpen() {
    document.documentElement.style.overflowY = "hidden";
  }

  beforeClose() {
    document.documentElement.style.overflowY = "auto";
  }
}
