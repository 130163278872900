
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { ExcursionService } from "@/modules/Excursions/ExcursionService";
import { HotelService } from "@/modules/Hotels/HotelService";
import { TourService } from "@/modules/Tours/TourService";
import { CommonService } from "@/services/CommonService";
import { type } from "os";
import { CityModel } from "@/models/common/CityModel";
import AppStore from "@/store/AppStore";
import { getModule } from "vuex-module-decorators";

type CombinedSearchModel = {
  groupName: string;
  // eslint-disable-next-line camelcase
  options: {
    id: number;
    name: string;
    groupId: number;
    duration?: number | null;
    city_id?: number;
  }[];
};

@Component({
  inheritAttrs: false,
})
export default class DirectionSearchSelect extends Vue {
  @Prop({ default: false })
  isSeaTour: boolean;

  @Prop({ default: false })
  isTour: boolean;

  @Prop({ default: () => [] })
  initItems: CombinedSearchModel["options"];

  @Prop({ default: "" })
  placeholder: string;

  @Prop({ default: false })
  hideHotels: boolean;

  @Prop({ default: false })
  searchIcon: boolean;

  @Prop()
  initSelect?: CityModel;

  combinedSearchResults: CombinedSearchModel[] = [];
  selectedCombinedSearchResults: CombinedSearchModel | null = null;

  debounceTimeout: null | ReturnType<typeof setInterval>;

  value: any = {
    id: null,
    name: null,
    groupId: null,
    duration: null,
    city_id: null,
  };

  async findCitiesAndHotel(query: string) {
    if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
    this.debounceTimeout = setTimeout(async () => {
      if (!!query && query.length > 1) {
        const cities = await this.$serviceLocator
          .getService(CommonService)
          .getCities(query);
        const hotels = this.isSeaTour
          ? await this.$serviceLocator
              .getService(TourService)
              .searchByName(query)
          : this.isTour
          ? await this.$serviceLocator
              .getService(ExcursionService)
              .searchByName(query)
          : await this.$serviceLocator
              .getService(HotelService)
              .searchByName(query);
        this.combinedSearchResults.length = 0;

        if (!!cities) {
          const cityGroup: CombinedSearchModel = {
            groupName: "Города",
            options: [],
          };
          cities.forEach((iter) =>
            cityGroup.options.push({
              groupId: 1,
              id: iter.id,
              name: iter.name,
              city_id: iter.id,
            })
          );
          this.combinedSearchResults.push(cityGroup);
        }

        if (!!hotels && !this.hideHotels) {
          const hotelGroup: CombinedSearchModel = {
            groupName: this.isTour ? "Туры" : "Отели",
            options: [],
          };
          hotels.forEach((iter) =>
            hotelGroup.options.push({
              groupId: 2,
              duration:
                typeof iter.duration === "number" ? iter.duration : null,
              id: iter.id,
              name: iter.name,
              city_id: this.isTour ? iter.to_city_id : iter.city_id,
            })
          );
          this.combinedSearchResults.push(hotelGroup);
        }
      } else if (this.isTour) {
        this.combinedSearchResults = this.initItems?.length
          ? [{ groupName: "Туры", options: this.initItems }]
          : [];
      }
    }, 300);
  }

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  @Watch("currentRoute.query", { deep: true })
  updateValue() {
    if (this.currentRoute.query.name) {
      this.value.name = this.currentRoute.query.name as string;
    } else {
      this.value = null;
    }
  }

  mounted() {
    if (this.currentRoute.query.name) {
      this.value.name = this.currentRoute.query.name as string;
    } else if (this.initSelect) {
      this.value = this.initSelect;
    } else {
      this.value = null;
    }
  }

  onSelect(data: any) {
    this.selectedCombinedSearchResults = data;
    this.$emit("on-select", this.selectedCombinedSearchResults);
  }
}
