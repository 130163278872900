import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import { BreadCrumb } from "@/models/core/BreadCrumb";
import { TourType } from "@/models/TourType";

@Module({
  name: "AppStore",
  stateFactory: true,
  namespaced: true,
})
export default class AppStore extends VuexModule {
  private breadCrumbList: BreadCrumb[] = [];
  private currentActiveMainMenuItemState = 0;
  private currentTourTypeState: TourType = TourType.SEA_TOUR;
  private openModalAuthState = false;
  private unreadNotiffState = 0;
  private routeState: any = {
    name: null,
    query: {},
    params: {},
  };
  private oldRouteState: any = {
    name: null,
    query: {},
    params: {},
  };

  private logs: any[] = [];

  get currentRoute() {
    return this.routeState;
  }

  get oldRoute() {
    return this.oldRouteState;
  }

  get unreadNotiff() {
    return this.unreadNotiffState;
  }

  get breadCrumbs() {
    return this.breadCrumbList;
  }

  get currentActiveMainMenuItem() {
    return this.currentActiveMainMenuItemState;
  }

  get currentTourType() {
    return this.currentTourTypeState;
  }

  get logList() {
    return this.logs;
  }

  @Mutation
  setRoute({
    name,
    query,
    params,
  }: {
    name: string | null;
    query: object;
    params: object;
  }) {
    this.routeState.name = name;
    this.routeState.query = query;
    if (params && Object.keys(params).length) {
      this.routeState.params = params;
    }
  }

  @Mutation
  setOldRoute(value: any) {
    this.oldRouteState = value;
  }

  @Mutation
  private setUnreadNotiffState(unreadNotiffState: number) {
    this.unreadNotiffState = unreadNotiffState;
  }

  @Mutation
  private setBreadCrumbList(breadCrumbList: BreadCrumb[]) {
    this.breadCrumbList = breadCrumbList;
  }

  @Mutation
  private setCurrentActiveMainMenuItem(maiainMenuItemId: number) {
    this.currentActiveMainMenuItemState = maiainMenuItemId;
  }

  @Mutation
  private setCurrentTourType(tourType: TourType) {
    this.currentTourTypeState = tourType;
  }

  @Mutation
  private addLogEntryState(log: any) {
    this.logs.push(log);
  }

  @Mutation
  private setOpenModalAuth(open: boolean) {
    this.openModalAuthState = open;
  }

  @Action
  updateBreadCrumbList(breadCrumbList: BreadCrumb[]) {
    this.setBreadCrumbList(breadCrumbList);
  }

  @Action
  updateUnreadNotiffState(unreadNotiffState: number) {
    this.setUnreadNotiffState(unreadNotiffState);
  }

  @Action
  updateCurrentActiveMainMenuItem(maiainMenuItemId: number) {
    this.setCurrentActiveMainMenuItem(maiainMenuItemId);
  }

  @Action
  updateCurrentTourType(tourType: TourType) {
    this.setCurrentTourType(tourType);
  }

  @Action
  addLogEntry(log: any) {
    if (!log) {
      log = "undefined or null";
    }
    this.addLogEntryState(log);
  }

  @Action
  updateOpenModalAuth(open: boolean) {
    this.setOpenModalAuth(open);
  }

  get openModalAuth() {
    return this.openModalAuthState;
  }
}
