/* eslint-disable camelcase */

import { BaseServiceModel } from "@/models/BaseServiceModel";
import { FovoritesSupport } from "@/models/common/FovoritesSupport";
import { ExcursionTransportForthModel } from "@/modules/Excursions/models/ExcursionModel";
import { PhotoModel, RoomModel } from "./RoomModel";

export class HotelCityModel {
  id: number = 0;
  name: string = "";
}

export class HotelInfrastructuresModel {
  id = 0;
  name: string = "";
  free_items?: string[] = [];
  pay_items?: string[] = [];
}

export class PromotionModel {
  id?: number = 0;
  name: string = "";
  date_from: string | Date;
  date_to: string | Date;
  percent?: number | null = null;
}

export class DiscountModel {
  price_from_without_discount: number | null = null;
  percent: number | null = null;
}

export class FilesModel {
  photos: PhotoModel[] = [];
}

export class HotelModel extends FovoritesSupport {
  name: string = "";
  url: string = "";
  city: HotelCityModel;
  rating: 0 | "";
  category_type_name: string = "";
  address: string = "";
  price_from: number = 0;
  info?: string | null = null;
  services: BaseServiceModel = new BaseServiceModel();
  rooms: RoomModel[] = [];
  infrastructures: HotelInfrastructuresModel[] = [];
  rating_name = "";
  stars = null;
  promotion: PromotionModel = new PromotionModel();
  discount: DiscountModel = new DiscountModel();
  old_price_from = 0;
  files: FilesModel;
  photos: any = [];
  arrival_time = "";
  departure_time = "";
  sea_distance = "";
  departure_day = "";
  slug: string = "";
  arrival_day = "";
  duration: any = {};
  sea_tour_id = 0;
  transport?: {
    transport_back: ExcursionTransportForthModel;
    transport_forth: ExcursionTransportForthModel;
  };
  transport_back: ExcursionTransportForthModel =
    new ExcursionTransportForthModel();
  transport_forth: ExcursionTransportForthModel =
    new ExcursionTransportForthModel();
  video?: {
    alt: string;
    name: string;
    url: string;
  };
  start_date = "";
  finish_date = "";

  description = "";
  seo_title: string = "";
  seo_description: string = "";
  seo_h1: string = "";

  city_id: number;
}
