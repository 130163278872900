export const slugSplit = (slug: string): string => {
  if (typeof slug === "string") {
    const splitedSlug = slug.split("-");
    const id = splitedSlug[splitedSlug.length - 1];
    return id;
  } else {
    return "";
  }
};

export const slugCreater = (id: number, slug?: string | undefined | null): string => {
  return `${slug ? slug + "-" : ""}${id}`;
};
