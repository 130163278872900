import dayjs from "dayjs";

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? `+${match[1]} ` : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const month = (date: string) => dayjs(date?.split("T")[0]).format("MMM");
export const dayNumber = (date: string) =>
  dayjs(date?.split("T")[0]).format("DD");
export const dayName = (date: string) =>
  dayjs(date?.split("T")[0]).format("dddd");
export const dayName2 = (date: string) =>
  dayjs(date?.split("T")[0]).format("dd");

export const formatDate = (date: string) => {
  try {
    const result = dayjs(date.split("T")[0]).format("DD MMMM YYYY, dd");
    if (result === "Invalid Date") {
      return "нет";
    }
    return result;
  } catch {
    return "нет";
  }
};

export const formatDateSimple = (date: string) => {
  try {
    const result = dayjs(date.split("T")[0]).format("DD MMMM YYYY");
    if (result === "Invalid Date") {
      return "нет";
    }
    return result;
  } catch {
    return "нет";
  }
};

export const formatDateShort = (date: string) => {
  try {
    const result = dayjs(date.split("T")[0]).format("DD MMMM");
    if (result === "Invalid Date") {
      return "нет";
    }
    return result;
  } catch {
    return "нет";
  }
};

export const formatDateStandart = (date: string | string | null) => {
  try {
    if (!date) {
      return;
    }
    const result = dayjs(date.split("T")[0]).format("DD.MM.YYYY");
    if (result === "Invalid Date") {
      return "нет";
    }
    return result;
  } catch {
    return "нет";
  }
};

export const formatDateFirstDay = (date: string | string | null) => {
  try {
    if (!date) {
      return;
    }
    const result = dayjs(date.split("T")[0]).format("dd, DD MMMM YYYY");
    if (result === "Invalid Date") {
      return "нет";
    }
    return result;
  } catch {
    return "нет";
  }
};

export const formatYYYMMDD = (dateString: string) => {
  if (!!dateString) {
    return dayjs(dateString.split("T")[0]).format("YYYY-MM-DD");
  }
  return "";
};
