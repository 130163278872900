/* eslint-disable camelcase */

import { BaseViewModel } from "@/models/core/BaseViewModel";

interface ConveniencesSchema {
  id?: number;
  name: string;
  icon: string;
}

interface PaymentsSchema {
  id?: number;
  name: string;
  icon: string;
}

export class DirectionsCardModel extends BaseViewModel {
  conveniences: ConveniencesSchema[] = [];
  payments: PaymentsSchema[] = [];

  id = 0;
  name = "";
  seat_cost = 0;
  duration = 0;

  from_city_id: number;
  to_city_id: number;
  days: [
    {
      id: number;
      name: string;
      short: string;
      number: number;
      pivot: {
        transport_id: number;
        day_id: number;
      };
    }
  ];

  dates: [];
  passengers = "";
  total_price = 0;
  transportation: {
    id: number;
    kind: number;
    name: string;
    seats: number;
    rear_seats: number;
    rows: number;
    second_door: boolean;
    second_door_rows: number;
    second_door_length: number;
    schema: string;
  };

  seats: {
    transport_id: number;
    number: number;
    date: string;
    tourist_id?: number;
    from_city_id: number;
    to_city_id: number;
  }[];

  routes: {
    start: {
      id: number;
      description: string;
      info: string;
      point_city: {
        id: number;
        name: string;
      };
      start_date: string;
      start_time: string;
      finish_date: string;
      finish_time: string;
    };
    intermediates: [
      {
        id: number;
        description: string;
        info: string;
        point_city: {
          id: number;
          name: string;
        };
        start_date: string;
        start_time: string;
        finish_date: string;
        finish_time: string;
      }
    ];
    finish: {
      id: number;
      description: string;
      info: string;
      point_city: {
        id: number;
        name: string;
      };
      start_date: string;
      start_time: string;
      finish_date: string;
      finish_time: string;
    };
  };

  travel_hours: number;

  agency_commission: number;
}
