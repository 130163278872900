
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import dayjs from "dayjs";
import { TourService } from "@/modules/Tours/TourService";
import { BaseDaysRangeModel, BaseFilterModel } from "@/models/BaseFilterModel";

@Component
export default class SeaTourCalendar extends Vue {
  $refs!: {
    SeaTourCalendar: any;
  };

  @Prop()
  filterModel: BaseFilterModel;

  @Prop()
  value: BaseDaysRangeModel;

  @Prop()
  catalogToCity: BaseDaysRangeModel;

  @Watch("value", { immediate: true, deep: true })
  async onDaysRangeChanged() {
    await this.$nextTick();
    this.startDay = this.value?.dateRange.start;
    this.endDay = this.value?.dateRange.end;
  }

  innerValueModel: any = {};
  dayClickedCount = 0;
  startDay: string | undefined = "";
  endDay: string | undefined = "";

  calendarConfig = {
    isMultiple: true,
    calendarsCount: 2,
    isDateRange: false,
    isDatePicker: true,
    placeholder: "Выберите даты",
    isAutoCloseable: false,
    markedDates: [],
  };

  selectedDays: { date: string }[] = [];

  opened() {
    this.dayClickedCount = 0;
    this.endDay = "";
    this.startDay = "";

    const calDate = this.$refs.SeaTourCalendar.getCalendarDateFormatted();
    this.innerValueModel.selectedDate = "";

    this.datesChanged(calDate);
  }

  datesChanged(date: string) {
    this.updateDaySelection(date);
  }

  dayClicked(day) {
    const dateArr = day?.date?.split("-").reverse();
    const dayNum = dateArr?.[0]?.length < 2 ? `0${dateArr[0]}` : dateArr[0];
    const month = dateArr?.[1]?.length < 2 ? `0${dateArr[1]}` : dateArr[1];
    const year = dateArr[2];
    const dateItem = new Date(`${year}-${month}-${dayNum}`).setDate(
      new Date(`${year}-${month}-${dayNum}`).getDate() + 1
    );
    const isValid = dayjs(new Date()).isSameOrBefore(dayjs(dateItem));
    if (!isValid) {
      return;
    }
    this.startDay = this.dayClickedCount === 0 ? day.date : this.startDay;
    this.dayClickedCount = this.dayClickedCount + 1;

    if (this.dayClickedCount === 2) {
      this.endDay = day.date;
    }
    this.emitInput();
    this.updateDaySelection(day.date);

    if (this.dayClickedCount === 2) {
      this.emitAndClose();
    }
  }

  async updateDaySelection(date: string) {
    this.calendarConfig?.markedDates?.splice(
      0,
      this.calendarConfig?.markedDates?.length
    );
    this.selectedDays.splice(0, this.selectedDays.length);

    if (
      !!this.filterModel &&
      !!this.filterModel.from_city_id &&
      (!!this.filterModel.to_city_id || this.catalogToCity)
    ) {
      const filterModelAsPojo = this.filterModel.cloneAndRemoveEmptyValue([
        "child_count",
      ]);
      const start = dayjs(date).format("YYYY-MM-DD");
      const end = dayjs(date).add(60, "day").format("YYYY-MM-DD");
      filterModelAsPojo.start_date = start;
      filterModelAsPojo.finish_date = end;
      filterModelAsPojo.transport_start_date = start;
      if (this.catalogToCity) {
        filterModelAsPojo.to_city_id = this.catalogToCity;
      }

      if (this.dayClickedCount > 0) {
        const from = this.filterModel.from_city_id;
        filterModelAsPojo.from_city_id =
          this.filterModel.to_city_id || this.catalogToCity;
        filterModelAsPojo.to_city_id = from || this.catalogToCity;
      }
      const days =
        this.dayClickedCount > 0
          ? await this.$serviceLocator
              .getService(TourService)
              .getSeaTourCalendarBack(filterModelAsPojo)
          : await this.$serviceLocator
              .getService(TourService)
              .getSeaTourCalendar(filterModelAsPojo);

      days.forEach((iter) => {
        if (!!iter.count) {
          const formatedDate = iter.date.replace(/-0+/g, "-");
          (this.calendarConfig.markedDates as any).push({
            date: formatedDate,
            class: "vfc-calendar-day__selected",
          });
          this.selectedDays.push({ date: formatedDate });
        }
      });
    }
  }
  emitAndClose() {
    this.emitInput();
    this.$refs.SeaTourCalendar.close();
  }

  emitInput() {
    const emitVal = new BaseDaysRangeModel();
    emitVal.dateRange = {
      start: this.startDay as any,
      end: this.endDay as any,
    };
    this.$emit("input", emitVal);
  }

  get selectCaption() {
    return this.dayClickedCount === 0
      ? "Выберите дату отправления"
      : "Выберите дату возвращения";
  }

  get formatDateRange() {
    return `${
      !!this.startDay ? dayjs(this.startDay).format("DD.MM.YYYY") : ""
    } ${!!this.startDay && !!this.endDay ? "~" : ""} ${
      !!this.endDay ? dayjs(this.endDay).format("DD.MM.YYYY") : ""
    }`;
  }
}
