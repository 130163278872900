
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import LivingOrderLeftSide from "@/modules/Order/components/LivingOrderLeftSide.vue";
import OrderDocuments from "@/modules/Order/components/OrderDocuments.vue";
import TrasportOrderLeftSide from "@/modules/Order/components/TrasportOrderLeftSide.vue";
import AdditionalServiceOrderLeftSide from "@/modules/Order/components/AdditionalServiceOrderLeftSide.vue";
import { OrderModel } from "@/modules/Order/models/OrderModel";
import { OrderCalculator } from "@/modules/Order/OrderCalculator";
import { formatDate, formatDateFirstDay } from "@/utils/Formaters";
import { stringToNumber } from "@/utils/StringToNumber";
import dayjs from "dayjs";
import { ExcursionService } from "@/modules/Excursions/ExcursionService";

@Component({
  components: {
    LivingOrderLeftSide,
    TrasportOrderLeftSide,
    AdditionalServiceOrderLeftSide,
    OrderDocuments,
  },
})
export default class FormLeftSide extends Vue {
  @Prop()
  order: OrderModel;
  
  @Prop({ default: false })
  editable: boolean;

  services: { id: number; name: string; sum: number }[] = [];

  orderCalculator: OrderCalculator = new OrderCalculator(new OrderModel());

  @Watch("order", { immediate: true })
  onOrderChanged() {
    this.orderCalculator = new OrderCalculator(this.order);
  }

  @Watch("totalPrice", { immediate: true })
  onTotalPriceVhanged() {
    this.$emit("total-price-changed", this.totalPrice);
  }

  async mounted() {
    const { data } = await this.$serviceLocator
      .getService(ExcursionService)
      .getTourServices();
    this.services = data.data;
  }

  get showServices() {
    return this.order?.applicationable_type === "App\\Models\\Tour";
  }

  get startPointInfoStart() {
    return this.orderCalculator.startPointInfoStart;
  }

  get startPointInfoFinish() {
    return this.orderCalculator.startPointInfoFinish;
  }

  get endPointInfoStart() {
    return this.orderCalculator.endPointInfoStart;
  }

  get endPointInfoFinish() {
    return this.orderCalculator.endPointInfoFinish;
  }

  get startFromToCityName() {
    return this.orderCalculator.startFromToCityName;
  }

  get finishFromToCityName() {
    return this.orderCalculator.finishFromToCityName;
  }

  get transportForthPrice() {
    return this.orderCalculator.transportForthPrice;
  }

  get transportBackPrice() {
    return this.orderCalculator.transportBackPrice;
  }

  get mealPrice() {
    return this.order.draftModel.meal_price && stringToNumber(this.order.draftModel.meal_price)?.toLocaleString(
      "ru-RU"
    );
  }

  get excurtionPrice() {
    return this.order.draftModel.excursion_price && stringToNumber(
      this.order.draftModel.excursion_price
    )?.toLocaleString("ru-RU");
  }

  get totalPrice() {
    return this.orderCalculator.totalPrice;
  }

  get servicePrice() {
    return this.orderCalculator.servicePrice;
  }

  get transportBack() {
    return this.orderCalculator.transportBack;
  }

  get transportForth() {
    return this.orderCalculator.transportForth;
  }

  get forthSeats() {
    return this.orderCalculator.forthSeats;
  }

  get backSeats() {
    return this.orderCalculator.backSeats;
  }

  get forthDateStart() {
    return this.orderCalculator.forthDateStart;
  }

  get backhDateStart() {
    return this.orderCalculator.backhDateStart;
  }

  get forthDateFinish() {
    return this.orderCalculator.forthDateFinish;
  }

  get backhDateFinish() {
    return this.orderCalculator.backhDateFinish;
  }

  get costIncludInTour() {
    return !this.orderCalculator.isDirection;
  }

  backStartDate(date: any) {
    return formatDate(date);
  }

  backStartTime(date: any) {
    return dayjs(date).utc().format("HH:mm");
  }

  roomPrice(acc: any) {
    return stringToNumber(acc.price_total)?.toLocaleString("ru-RU");
  }

  inDate(acc: any) {
    return formatDateFirstDay(acc.start_at);
  }

  outDate(acc: any) {
    return formatDateFirstDay(acc.finish_at);
  }

  outTime(acc: any) {
    return acc.hotel.arrival_time;
  }

  inTime(acc: any) {
    return acc.hotel.departure_time;
  }

  roomName(acc: any) {
    return acc.room?.name;
  }

  meal(acc: any) {
    return acc.meal?.name;
  }

  daySAndNights(acc: any) {
    return `за ${acc.duration?.days}, ${acc.duration?.nights}`;
  }

  get isTour() {
    return this.orderCalculator.isTour;
  }

  get isHotel() {
    return this.orderCalculator.isHotel;
  }

  get name() {
    return this.orderCalculator.name;
  }

  get tourServices() {
    return this.orderCalculator.tourServices;
  }

  get peoples() {
    return this.orderCalculator.peoples;
  }

  get peoplesFor() {
    return this.orderCalculator.peoplesFor;
  }

  get peoplesCount() {
    return this.orderCalculator.peoplesCount;
  }

  get dates() {
    return this.orderCalculator.dates;
  }
}
