
import { Vue, Component, Prop } from "vue-property-decorator";
import SwiperInstance, { Navigation, Pagination, A11y } from "swiper";
import { SwiperOptions, Swiper } from "swiper/swiper.d";
import { v4 as uuidv4 } from "uuid";
import VideoModal from "@/components/VideoModal.vue";
import { CatalogExcursionModel } from "@/modules/Catalog/models/CatalogExcursionModel";

// Import Swiper styles
import "swiper/swiper.scss";

SwiperInstance.use([Navigation, Pagination, A11y]);

@Component({ components: { VideoModal } })
export default class HotelSlider extends Vue {
  @Prop({ default: () => uuidv4() })
  uid: string;

  @Prop()
  slides: {url: string; alt: string;}[];

  @Prop()
  video: CatalogExcursionModel["excursion_tour"]["video"];

  @Prop({ default: false })
  isCatalog: boolean;

  @Prop({ default: false })
  isFavorites: boolean;

  currentIndex: number = 0;

  isShare: boolean = false;
  videoVisible: boolean = false;
  private swiperInstance: Swiper = {} as Swiper;


  private settings: SwiperOptions = {
    speed: 1000,

    autoplay: {
      disableOnInteraction: false,
      delay: 3000,
    },

    pagination: {
      el: ".HotelSlider__pagination",
      type: "custom",
      renderCustom(_, current, total) {
        const c = current < 10 ? "0" + current : current;
        const t = total < 10 ? "0" + total : total;
        return c + `<span> / ${t}</span>`;
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  };

  get currnetAtlName () {
    return this.slides?.length && this.slides[this.currentIndex]?.alt;
  }

  mounted() {
    this.swiperInstance = new SwiperInstance(`.HotelSlider__wrap`, {
      ...this.settings,
      onAny: (eventName, ...args) => {
        //@ts-ignore
        if (eventName === "slideChange") {
        //@ts-ignore
          this.currentIndex = args && args[0]?.realIndex || 0
        }
      },
    }); // .swiper-container--${this.uid} .Swiper__slider
  }

  toogleFavorites() {

    this.$emit("add-favorites");
  }
}
