/* eslint-disable camelcase */
import { BaseViewModel } from "@/models/core/BaseViewModel";

export abstract class FovoritesSupport extends BaseViewModel {
  id = 0;
  isInFavor = false;
  isShown = false;

  client_favorite: any = false;
  agency_favorite: any = false;

  payload: any = null;
}
