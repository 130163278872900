import IMask from "imask";

export default {
  bind: (el: any, _binding: any, _vnode: any) => {
    if (!!_binding?.value?.mask) {
      initMask(el, _binding.value);
    }
  },

  update: (el: any, _binding: any, _vnode: any) => {
    if (!!_binding?.value?.mask) {
      if (!!el.maskRef) {
        el.maskRef.updateOptions(_binding.value);
        if (el.value !== el.maskRef.value) el.maskRef._onChange();
      } else initMask(el, _binding.value);
    } else {
      destroyMask(el);
    }
  },

  unbind: (el: any) => {
    destroyMask(el);
  },
};

const initMask = (el: any, opts: any) => {
  el.maskRef = IMask(el, opts)
    .on("accept", () => fireEvent(el, "accept", el.maskRef))
    .on("complete", () => fireEvent(el, "complete", el.maskRef));
};

const fireEvent = (el: HTMLElement, eventName: string, data: any) => {
  const e = document.createEvent("CustomEvent");
  e.initCustomEvent(eventName, true, true, data);
  el.dispatchEvent(e);
};

const destroyMask = (el: any) => {
  if (el.maskRef) {
    el.maskRef.destroy();
    delete el.maskRef;
  }
};
