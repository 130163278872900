
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  inheritAttrs: false,
})
export default class BaseInputWrapper extends Vue {
  @Prop()
  label: string;

  @Prop()
  help: string;

  @Prop()
  errorText: string;

  @Prop({ default: false })
  hasError: boolean;
}
