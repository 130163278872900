import {
  ContextServiceRegistry as ContextServiceLocator,
  ServiceLocator,
} from "@/ServiceLocator";
import Vue from "vue";
// import { CommonService } from "@/services/CommonService";
// import { ContentService } from "@/services/ContentService";
import { TestService } from "@/services/TestService";

declare module "vue/types/vue" {
  interface Vue {
    $serviceLocator: ContextServiceLocator;
  }
}

const initializeApp = async () => {
  initializeServiceLocator();
};

const initializeServiceLocator = () => {
  ServiceLocator.createFreshServiceLocator();
    ServiceLocator.instance.register(TestService);

  Vue.prototype.$serviceLocator = ServiceLocator.instance;
};

export default initializeApp;
