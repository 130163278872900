
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { stringToNumber } from "@/utils/StringToNumber";
import { ApplicationService } from "@/modules/Order/ApplicationService";

type TourService = {
  id: number;
  serviceable: {
    id: number;
    name: string;
  };
  places: string;
  // eslint-disable-next-line camelcase
  price_total: string;
};

@Component
export default class AdditionalServiceOrderLeftSide extends Vue {
  @Prop()
  tourServices: TourService[];

  @Prop({ default: () => [] })
  allServices: TourService["serviceable"][];

  @Prop({ default: true })
  editable: boolean;

  serviceForm: any[] = [];

  getServicePrice(service: any) {
    return stringToNumber(service.sum).toLocaleString("ru-RU");
  }

  changeAdult(idx: number, val: number) {
    this.serviceForm[idx].count += val;

    if (this.serviceForm[idx].selected) {
      this.updateOrder(this.serviceForm[idx]);
    }
  }

  changeSelected(idx: number) {
    if (this.serviceForm[idx].selected && this.serviceForm[idx].count > 0) {
      this.updateOrder(this.serviceForm[idx]);
    }
  }

  async updateOrder(data: {
    id: number;
    name: string;
    sum: number;
    count: number;
  }) {
    await this.$serviceLocator
      .getService(ApplicationService)
      .updateOrder(data, 59);
  }

  get formPrice() {
    return this.serviceForm.reduce((acc, curr) => {
      return (acc += (curr.selected && curr.sum * curr.count) || 0);
    }, 0);
  }

  mounted() {
    this.serviceForm = structuredClone(
      this.allServices?.map((el: any) => {
        const serviceById = this.tourServices.find(
          (serv) => serv?.serviceable?.id === el.id
        );
        if (serviceById) {
          el.selected = true;
          el.count = Number(serviceById?.places?.replace(/[^0-9.]/g, "")) || 0;
        } else {
          el.count = 0;
        }
        return el;
      })
    );
  }

  get totalPrice() {
    let total = 0;
    this.tourServices.forEach((iter) => {
      total = total + stringToNumber(iter.price_total);
    });
    return total.toLocaleString("ru-RU");
  }
}
