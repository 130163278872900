
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { HotelModel } from "@/modules/Hotels/models/HotelModel";
import { formatDate } from "@/utils/Formaters";
import { RoomModel } from "@/modules/Hotels/models/RoomModel";
import { slugCreater } from "@/utils/SlugSplit";

@Component
export default class ExcursionHotel extends Vue {
  @Prop({ required: false })
  isAllView: boolean;

  @Prop({ required: false })
  isOrder: boolean;

  @Prop({ required: true })
  hotel: HotelModel;

  @Prop()
  roomInOrder: RoomModel;

  @Prop({ default: false })
  isCatalog: boolean;

  @Prop()
  dateFrom: string;

  @Prop()
  durationAndPeople: string;

  @Prop()
  people: any;

  @Prop({ default: false })
  hotelSelected;

  seletcedRoom: any = null;
  upHere = false;

  @Watch("seletcedRoom")
  onSeletcedRoomChanged() {
    this.$emit("selected-room-changed", this.seletcedRoom?.value);
  }

  clearSelectedRoom() {
    this.seletcedRoom = null;
    this.$emit("selected-room-changed", null);
  }

  get selectedBorderStyle() {
    if (this.hotelSelected) {
      return {
        border: "0.5px solid #3068F7",
      };
    }
  }

  get roomModel() {
    return this.roomInOrder || this.seletcedRoom?.value;
  }

  get adultCount() {
    return this.people?.adult.split(" ")[0] || 0;
  }

  get childCount() {
    return this.people?.child.split(" ")[0] || 0;
  }

  get dateFromFormten() {
    return formatDate(this.dateFrom);
  }

  get roomsOptionsForSelect() {
    let result: any[] = [];
    if (!!this.hotel.rooms) {
      result = this.hotel.rooms.map((iter) => {
        return { label: iter.name, value: iter };
      });
    }
    return result;
  }

  routeParam(id: number, slug: string | undefined) {
    return slugCreater(id, slug);
  }
}
