/* eslint-disable camelcase */
import dayjs from "dayjs";
import { BaseViewModel } from "@/models/core/BaseViewModel";

export enum FilterTypes {
  "DIRECTION" = "directions",
  "EXCURSION" = "excursions",
  "TOUR" = "tours",
  "HOTEL" = "hotels",
}

export class BaseDaysRangeModel {
  dateRange: {
    start: "";
    end: "";
  };
}
export class BaseDaysSelectModel {
  id: number = 0;
  days: string = "";
}

export class BaseFilterModel extends BaseViewModel {
  from: string = "";
  hotel_id?: number;
  direction: string;
  duration?: number | null = null;
  child_count = 0;
  adult_count = 1;
  child_ages: number[] = [];
  services: number[] = [];
  price_from = 0;
  price_to = 0;
  category_types: number[] = [];
  categories: number[] = [];
  meal_category_types: number[] = [];
  sea_distances: number[] = [];
  name: string | null = "";
  cities: number[] = [];
  from_city_id = 0;
  to_city_id = 0;
  infrastructures: number[] = [];
  duration_from?: number = 1;
  duration_to?: number = 30;
  date_from?: string | Date = dayjs(new Date()).format("YYYY-MM-DD");
  date_to?: string | Date = dayjs(new Date())
    .add(5, "day")
    .format("YYYY-MM-DD");
  start_date_from?: string | Date = dayjs(new Date()).format("YYYY-MM-DD");
  start_date_to?: string | Date = dayjs(new Date())
    .add(5, "day")
    .format("YYYY-MM-DD");
  start_date?: string | Date = dayjs(new Date())
    .add(1, "day")
    .format("YYYY-MM-DD");
  // finish_date?: string | Date = dayjs(new Date()).add(6, "day").format("YYYY-MM-DD");
  finish_date?: string | Date = "";
  // Сортировки
  favorite?: number | null = null;
  price?: number | null = null;
  sort_by?: string | null = null;
  sort_direction?: number | null = null;
}
