import dayjs from "dayjs";
import { formatDateFirstDay, formatDateStandart } from "@/utils/Formaters";
import { OrderModel } from "./models/OrderModel";
import { decOfNum } from "@/utils/DecOfNum";

export class OrderCalculator {
  private order: OrderModel;

  constructor(order: OrderModel) {
    this.order = order;
  }

  get startPointInfoStart() {
    if (!!this.order.draftModel.transport_forth) {
      return this.order.draftModel.transport_forth.from_info;
    }
  }

  get startPointInfoFinish() {
    if (!!this.order.draftModel.transport_back) {
      return this.order.draftModel.transport_back.from_info;
    }
  }

  get endPointInfoStart() {
    if (!!this.order.draftModel.transport_forth) {
      return this.order.draftModel.transport_forth.to_info;
    }
  }

  get endPointInfoFinish() {
    if (!!this.order.draftModel.transport_back) {
      return this.order.draftModel.transport_back.to_info;
    }
  }

  get startFromToCityName() {
    if (!!this.order.draftModel.transport_forth) {
      return (
        this.order.draftModel.transport_forth.from_city?.name +
        " → " +
        this.order.draftModel.transport_forth.to_city?.name
      );
    }
  }

  get finishFromToCityName() {
    if (!!this.order.draftModel.transport_back) {
      return (
        this.order.draftModel.transport_back.from_city?.name +
        " → " +
        this.order.draftModel.transport_back.to_city?.name
      );
    }
  }

  get agencyCommissionTotal() {
    return this.order.agencyCommission;
  }

  get typeName() {
    return this.isTour
      ? "Тур"
      : this.isHotel || this.isSeaTour
      ? "Бронь"
      : "Проезд";
  }

  get directionOrHoteCaption() {
    return this.isTour || this.isDirection ? "Направление" : "Отель";
  }

  get firstBlockCaption() {
    return this.isTour
      ? "Тур"
      : this.isHotel || this.isSeaTour
      ? "Бронь отеля"
      : "Туда";
  }

  get secondBlockCaption() {
    return this.isSeaTour ? "Проезд" : "Обратно";
  }

  get showSecondDetailBlock() {
    return this.isSeaTour || this.isDirection;
  }

  get totalPrice() {
    return Number(this.order.payments_info?.total) ?? 0;
  }

  get transportForthPrice() {
    return this.order.draftModel.transport_forth?.price_total;
  }

  get transportBackPrice() {
    return this.order.draftModel.transport_back?.price_total;
  }

  get forthDateStart() {
    return this.order.draftModel.transport_forth?.start_at;
  }

  get backhDateStart() {
    return this.order.draftModel.transport_back?.start_at;
  }

  get forthDateFinish() {
    return this.order.draftModel.transport_forth?.finish_at;
  }

  get backhDateFinish() {
    return this.order.draftModel.transport_back?.finish_at;
  }

  get servicePrice() {
    return 0;
  }

  get transportBack() {
    return this.order.draftModel.transport_back?.transport;
  }

  get transportForth() {
    return this.order.draftModel.transport_forth?.transport;
  }

  get forthSeats() {
    if (!!this.order.seats) {
      const seats = this.order.seats.map((iter) => {
        if (
          !!this.order.draftModel.transport_forth?.transport &&
          this.order.draftModel.transport_forth?.transport.id ===
            iter.transport_id
        ) {
          return iter.number;
        }
        return null;
      });
      return seats.filter((iter) => !!iter).join(" ,");
    }
    return "не выбраны";
  }

  get forthSeatsArr() {
    if (!!this.order.seats) {
      const seats = this.order.seats.map((iter) => {
        if (
          !!this.order.draftModel.transport_forth?.transport &&
          this.order.draftModel.transport_forth?.transport.id ===
            iter.transport_id
        ) {
          return iter.number;
        }
        return null;
      });
      return seats.filter((iter) => !!iter);
    }
    return null;
  }

  get backSeatsArr() {
    if (!!this.order.seats) {
      const seats = this.order.seats.map((iter) => {
        if (
          !!this.order.draftModel.transport_back?.transport &&
          this.order.draftModel.transport_back?.transport.id ===
            iter.transport_id
        ) {
          return iter.number;
        }
        return null;
      });
      return seats.filter((iter) => !!iter);
    }
    return null;
  }

  get backSeats() {
    if (!!this.order.seats) {
      const seats = this.order.seats.map((iter) => {
        if (
          !!this.order.draftModel.transport_back?.transport &&
          this.order.draftModel.transport_back?.transport.id ===
            iter.transport_id
        ) {
          return iter.number;
        }
        return null;
      });
      return seats.filter((iter) => !!iter).join(",");
    }
    return "не выбраны";
  }

  get roomPrice() {
    return this.order.priceRooms?.toLocaleString("ru-RU");
  }

  get roomPriceNumber() {
    return this.order.priceRooms;
  }

  get inDate() {
    return formatDateFirstDay(this.order.start_date);
  }

  get outDate() {
    return formatDateFirstDay(this.order.finish_date);
  }

  get outTime() {
    return this.order.draftModel.accommodations[0].hotel.arrival_time;
  }

  get inTime() {
    return this.order.draftModel.accommodations[0].hotel.departure_time;
  }

  get roomName() {
    return this.order.draftModel.accommodations[0].room.name;
  }

  get meal() {
    return this.order.draftModel.accommodations[0].meal?.name;
  }

  get isSeaTour() {
    return this.order.draftModel.applicationable_type?.endsWith("SeaTour");
  }

  get isTour() {
    return this.order.draftModel.applicationable_type?.endsWith("Tour");
  }

  get isHotel() {
    return this.order.draftModel.applicationable_type?.endsWith("Hotel");
  }

  get isDirection() {
    return this.order.draftModel.applicationable_type?.endsWith("Transport");
  }

  get name() {
    if (this.isDirection) {
      const startDate = dayjs(this.order.start_date?.split("T")[0]).format(
        "DD MMMM YYYY"
      );

      if (!!this.order.draftModel.transport_forth) {
        return (
          this.order.draftModel.transport_forth.from_city?.name +
          " → " +
          this.order.draftModel.transport_forth.to_city?.name +
          ", " +
          startDate
        );
      }
    }
    return !!this.order.draftModel.applicationable
      ? this.order.draftModel.applicationable.name
      : "нет";
  }

  get tourOrHotelShortDescription() {
    if (this.isDirection) {
      return this.order.people.total;
    }

    if (this.isHotel || this.isSeaTour) {
      const hotel = this.order.draftModel.accommodations[0].hotel;
      return hotel.city.name + ", " + hotel.address;
    }
  }

  get secondBlockName() {
    if (this.isDirection) {
      const startDate = dayjs(this.order.finish_date?.split("T")[0]).format(
        "DD MMMM YYYY"
      );

      if (!!this.order.draftModel.transport_back) {
        return (
          this.order.draftModel.transport_back.from_city?.name +
          " → " +
          this.order.draftModel.transport_back.to_city?.name +
          ", " +
          startDate
        );
      }
    }

    return "-";
  }

  get secondBlockShortDescription() {
    if (this.isDirection) {
      return this.order.people.total;
    }

    return this.simpleDatesInterval;
  }

  get tourServices() {
    return this.order.draftModel.services;
  }

  get peoples() {
    return `${this.order.people.adult}, ${this.order.people.child}`;
  }

  get peoplesFor() {
    const num = this.order.people.total.replace(/[^0-9.]/g, "");
    return `за ${num} ${decOfNum(Number(num), [
      "человека",
      "человек",
      "человек",
    ])}`;
  }

  get peoplesCount() {
    return this.order.adult_count + this.order.child_count;
  }

  get daySAndNights() {
    return `за ${this.order.duration?.days}, ${this.order.duration?.nights}`;
  }

  get dates() {
    if (
      formatDateStandart(this.order.start_date) &&
      !formatDateStandart(this.order.finish_date)
    ) {
      return "c " + formatDateStandart(this.order.start_date);
    } else if (
      formatDateStandart(this.order.start_date) &&
      formatDateStandart(this.order.finish_date)
    ) {
      return (
        "c " +
        formatDateStandart(this.order.start_date) +
        " по " +
        formatDateStandart(this.order.finish_date)
      );
    } else {
      return undefined;
    }
  }

  get simpleDatesInterval() {
    const backDate =
      this.order.finish_date ||
      this.order.draftModel.transport_back?.finish_at ||
      this.order.draftModel.transport_forth.finish_at;
    return `${
      !!this.order.start_date
        ? dayjs(this.order.start_date?.split("T")[0]).format("DD.MM.YY")
        : ""
    } ${!!this.order.start_date && !!backDate ? "~" : ""} ${
      !!backDate ? dayjs(backDate?.split("T")[0]).format("DD.MM.YY") : ""
    }`;
  }
}
