import Vue from "vue";
import Vuex, { Module } from "vuex";

import AppStore from "./AppStore";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {},
  modules: {
    AppStore,
  },
});
