
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import TourListItem from "@/modules/Tours/components/TourListItem.vue";
import { decOfNum } from "@/utils/DecOfNum";
import { HotelModel } from "@/modules/Hotels/models/HotelModel";
import TourFavoritesAndShownService from "../TourFavoritesAndShownService";

@Component({ components: { TourListItem } })
export default class TourList extends Vue {
  @Prop()
  loadingState: boolean;

  @Prop()
  tourList: HotelModel[];

  @Prop({ default: false })
  showMoreEnabled: boolean;

  sort: number | null = null;

  generateText(count: number) {
    return count + " " + decOfNum(count, ["предложение", "предложения", "предложений"]);
  }

  @Watch("tourList", { immediate: true })
  onListChanged() {
    this.updateFavorites();
  }

  updateFavorites() {
    this.$serviceLocator.getService(TourFavoritesAndShownService).updateFavorStatus(this.tourList);
  }

  optionsSelect = [
    { id: 1, label: "По рейтингу (по возрастанию)" },
    { id: 2, label: "По рейтингу (по убыванию)" },
    { id: 3, label: "По цене (по возрастанию)" },
    { id: 4, label: "По цене (по убыванию)" },
  ];

  onSortModeChanged(mode) {
    this.$emit("on-sort-mode-changed", mode);
  }
}
