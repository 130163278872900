
import { Vue, Component, Prop } from "vue-property-decorator";
import { HotelModel } from "@/modules/Hotels/models/HotelModel";
import { RouteLink } from "@/models/core/RouteLink";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { slugCreater } from "@/utils/SlugSplit";
import HotelFavoritesAndShownService from "../HotelFavoritesAndShownService";
import HotelRoomsAccordion from "./HotelRoomsAccordion.vue";
import { getModule } from "vuex-module-decorators";
import { Calc } from "@/utils/CalcDiscount";
import ModalHotelMap from "@/modules/Hotels/components/ModalHotelMap.vue";
import AppStore from "@/store/AppStore";

@Component({ components: { HotelRoomsAccordion, ModalHotelMap } })
export default class HotelListItem extends Vue {
  @Prop()
  hotelModel: HotelModel;

  @Prop()
  additionalMenuItems: RouteLink[];

  @Prop({ default: false })
  isFromSelecton: boolean;

  @Prop()
  detailPageRouteQuery: any;

  showModalMap: boolean = false;
  seatAvailability: boolean = false;

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  get pageRouteQuery() {
    return this.detailPageRouteQuery ?? this.currentRoute.query;
  }
  routeTo(data: any) {
    getModule(AppStore, this.$store).setRoute(data);
  }

  toogleFavorites() {
    const filterModel = BaseViewModel.fromPlainIfNotClass(
      BaseFilterModel,
      this.currentRoute.query
    );
    this.$serviceLocator
      .getService(HotelFavoritesAndShownService)
      .toogleFavor(this.hotelModel, filterModel);
  }

  clickRemoveItem() {
    this.$emit("on-remove-from-favor", this.hotelModel);
  }

  routeParam(id: number, slug: string | undefined) {
    return slugCreater(id, slug);
  }
}
