var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{key:_vm.componentKey,staticClass:"FormTicket"},[_c('div',{staticClass:"FormTicket__title d-flex justify-content-between align-items-center"},[_c('span',{staticClass:"FormTicket__title"},[_vm._v(_vm._s(_vm.cardName))])]),_c('div',{staticClass:"FormTicket__group"},[_c('TouristLastNameSearch',{ref:"lastNameSearchComponent",staticClass:"Field FormTicket__input",on:{"selected":_vm.lastProfileSelected},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('BaseInput',{staticClass:"FormTicket__input",attrs:{"label":"Имя*","placeholder":"Введите имя","error-text":_vm.$v.value.first_name.$dirty && !_vm.$v.value.first_name.required
          ? 'Введите имя'
          : null,"has-error":_vm.$v.value.first_name.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.value.first_name.$touch()}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('div',{staticClass:"FormTicket__group"},[_c('BaseInput',{staticClass:"FormTicket__input",attrs:{"label":"Отчество*","placeholder":"Введите отчество","error-text":_vm.$v.value.patronymic.$dirty && !_vm.$v.value.patronymic.required
          ? 'Введите отчество'
          : null,"has-error":_vm.$v.value.patronymic.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.value.patronymic.$touch()}},model:{value:(_vm.patronymic),callback:function ($$v) {_vm.patronymic=$$v},expression:"patronymic"}}),_c('BaseInputMask',{staticClass:"FormTicket__input",attrs:{"label":"Дата рождения*","placeholder":"Дата рождения","type":"date","mask":_vm.dateMask,"error-text":(_vm.$v.value.birthday_date.$dirty && !_vm.$v.value.birthday_date.required
          ? 'Поле не заполнено'
          : null) ||
        (_vm.$v.value.birthday_date.$dirty && !_vm.$v.value.birthday_date
          ? `Дата рождения`
          : null),"has-error":_vm.$v.value.birthday_date.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.value.birthday_date.$touch()}},model:{value:(_vm.value.birthday_date),callback:function ($$v) {_vm.$set(_vm.value, "birthday_date", $$v)},expression:"value.birthday_date"}})],1),_c('div',{staticClass:"FormTicket__group"},[_c('BaseSelect',{staticClass:"FormTicket__input",attrs:{"options":_vm.document_types,"placeholder":"Выберите вид документа","label-name":"name","label":"Вид документа*","reduce":(item) => item.id,"error-text":_vm.$v.value.document.$dirty && !_vm.$v.value.document.required
          ? 'Выберите вид документа'
          : null,"has-error":_vm.$v.value.document.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.value.document.$touch()}},model:{value:(_vm.value.document),callback:function ($$v) {_vm.$set(_vm.value, "document", $$v)},expression:"value.document"}}),(_vm.isDocumentPassport)?_c('BaseInput',{staticClass:"FormTicket__input",attrs:{"label":"Серия и номер*","maxLength":"12","mask":_vm.documentNumberMask,"placeholder":"Серия и номер","error-text":_vm.documentNumberValidationErrorMessage,"has-error":_vm.$v.value.document_number.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.value.document_number.$touch()}},model:{value:(_vm.value.document_number),callback:function ($$v) {_vm.$set(_vm.value, "document_number", $$v)},expression:"value.document_number"}}):_vm._e(),(!_vm.isDocumentPassport)?_c('BaseInput',{staticClass:"FormTicket__input",attrs:{"label":"Номер*","placeholder":"Номер","error-text":_vm.documentNumberValidationErrorMessage,"has-error":_vm.$v.value.document_number.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.value.document_number.$touch()}},model:{value:(_vm.value.document_number),callback:function ($$v) {_vm.$set(_vm.value, "document_number", $$v)},expression:"value.document_number"}}):_vm._e()],1),_c('div',{staticClass:"FormTicket__group"},[_c('BaseSelect',{staticClass:"FormTicket__input",attrs:{"options":_vm.citizenship_types,"label-name":"name","placeholder":"Выберите гражданство","label":"Гражданство*","reduce":(item) => item.id,"error-text":_vm.$v.value.citizenship.$dirty && !_vm.$v.value.citizenship.required
          ? 'Выберите гражданство'
          : null,"has-error":_vm.$v.value.citizenship.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.value.citizenship.$touch()}},model:{value:(_vm.value.citizenship),callback:function ($$v) {_vm.$set(_vm.value, "citizenship", $$v)},expression:"value.citizenship"}}),_c('BaseSelect',{staticClass:"FormTicket__input FormTicket__select--small",attrs:{"options":_vm.gender_types,"placeholder":"Выберите пол","label-name":"name","label":"Пол*","reduce":(item) => item.id,"error-text":(_vm.$v.value.citizenship.$dirty && !_vm.$v.value.citizenship.required
          ? 'Поле не заполнено'
          : null) ||
        (_vm.$v.value.citizenship.$dirty && !_vm.$v.value.citizenship
          ? `Выберите пол`
          : null),"has-error":_vm.$v.value.citizenship.$error,"is-shake-error":true},on:{"blur":function($event){return _vm.$v.value.citizenship.$touch()}},model:{value:(_vm.value.gender),callback:function ($$v) {_vm.$set(_vm.value, "gender", $$v)},expression:"value.gender"}})],1),(_vm.editable)?_c('BaseButton',{staticClass:"mt-16",on:{"click":function($event){$event.preventDefault();return _vm.submitToursit.apply(null, arguments)}}},[_vm._v("Сохранить")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }