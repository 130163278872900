
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LivingOrderLeftSide extends Vue {
  @Prop({ default: false })
  isHotel: boolean;

  @Prop()
  inDate: string;

  @Prop()
  outDate: string;

  @Prop()
  inTime: string;

  @Prop()
  outTime: string;

  @Prop()
  meal: string;

  @Prop()
  roomName: string;

  @Prop()
  daySAndNights: string;

  @Prop()
  peoplesFor: string;

  @Prop()
  sum: string;
}
