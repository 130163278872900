
import { Component, Vue, Watch } from "vue-property-decorator";
import BaseFilter from "@/components/filter/BaseFilter.vue";
import HotelList from "@/modules/Hotels/components/HotelList.vue";
import HotelFilter from "@/modules/Hotels/components/HotelFilter.vue";
import TheContainer from "@/components/TheContainer.vue";
import { TourType } from "@/models/TourType";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { Pagination } from "@/models/core/Pagination";
import { HotelService } from "./HotelService";
import { HotelModel } from "./models/HotelModel";
import { getModule } from "vuex-module-decorators";
import AppStore from "@/store/AppStore";

@Component({
  components: {
    BaseFilter,
    HotelList,
    HotelFilter,
    TheContainer,
  },
})
export default class HotelListPage extends Vue {
  pagination: Pagination = new Pagination();
  hotelList: HotelModel[] = [];
  showedHotels: HotelModel[] = [];
  extendedFilterModel: BaseFilterModel = new BaseFilterModel();
  topFilterModel: BaseFilterModel = new BaseFilterModel();
  loadingState = false;
  showMoreEnabled = false;
  get currentRoute(){
    return getModule(AppStore, this.$store).currentRoute
  }

  async created() {
    try {
      this.topFilterModel = BaseViewModel.fromPlainIfNotClass(BaseFilterModel, this.currentRoute.query);
      this.syncLeftFilter(this.topFilterModel);
      await this.updateHotelList(this.topFilterModel, this.pagination);
    } catch (err: any) {
    }
  }


  @Watch("extendedFilterModel", { deep: true })
  async onExtendedFilterModelChanged() {
    const extendedFilterModel = BaseViewModel.fromPlainIfNotClass(BaseFilterModel, this.extendedFilterModel) as BaseFilterModel;
    this.topFilterModel = BaseViewModel.fromPlainIfNotClass(BaseFilterModel, this.topFilterModel) as BaseFilterModel;
    this.topFilterModel.category_types = [...extendedFilterModel.category_types];
    this.topFilterModel.price_from = extendedFilterModel.price_from;
    this.topFilterModel.price_to = extendedFilterModel.price_to;
    this.topFilterModel.name = extendedFilterModel.name;

    this.topFilterModel.meal_category_types = [...extendedFilterModel.meal_category_types];
    this.topFilterModel.sea_distances = [...extendedFilterModel.sea_distances];
    this.topFilterModel.services = [...extendedFilterModel.services];

    this.topFilterModel.price = extendedFilterModel.price;
    this.topFilterModel.favorite = extendedFilterModel.favorite;

    const combinedFilter = this.topFilterModel.cloneAndRemoveEmptyValue(["child_count", "price", "favorite"]);
    this.updateRouteQuery(combinedFilter);

    Pagination.clearSelectedPages(this.pagination);
    this.pagination.currentPage = 1;

    await this.updateHotelList(this.topFilterModel, this.pagination);
  }

  syncLeftFilter(filterModel: BaseFilterModel) {
    this.extendedFilterModel.category_types = [...filterModel.category_types];
    this.extendedFilterModel.price_from = filterModel.price_from;
    this.extendedFilterModel.price_to = filterModel.price_to;
    this.extendedFilterModel.name = filterModel.name;
    this.extendedFilterModel.meal_category_types = [...filterModel.meal_category_types];
    this.extendedFilterModel.sea_distances = [...filterModel.sea_distances];
    this.extendedFilterModel.services = [...filterModel.services];
  }

  //  При обновлении фильтра, если на той же странице (отелей) - по событию от фильтра
  async showSearchResultFromTopFilter(_type: TourType, filterModel: BaseFilterModel) {
    this.topFilterModel.direction = filterModel.direction;
    this.topFilterModel.date_from = filterModel.date_from;
    this.topFilterModel.date_to = filterModel.date_to;
    this.topFilterModel.to_city_id = filterModel.to_city_id;
    this.topFilterModel.child_count = filterModel.child_count;
    this.topFilterModel.child_ages = [...filterModel.child_ages];
    this.topFilterModel.adult_count = filterModel.adult_count;

    // Синхронизируем левый фильтр
    this.syncLeftFilter(filterModel);
    // Делаем из модели фильтра POJO (чистоый JS-объект)
    const filterModelAsPojo = filterModel.cloneAndRemoveEmptyValue(["child_count"]);

    // Чистим модель пагинации
    Pagination.clearSelectedPages(this.pagination);
    this.pagination.currentPage = 1;
    // Обновляем строку в браузере (для SEO)
    this.updateRouteQuery(filterModelAsPojo);
  }

  async updateHotelList(filterModel: BaseFilterModel, pagination: Pagination) {
    this.loadingState = true;
    const filterModelAsPojo = filterModel.cloneAndRemoveEmptyValue(["child_count"]);
    const hotelListWithPagination = await this.$serviceLocator
      .getService(HotelService)
      .findhHotels(filterModelAsPojo, pagination);
    this.hotelList = hotelListWithPagination.data;
    this.pagination = hotelListWithPagination.pagination;

    this.showMoreEnabled = hotelListWithPagination.pagination.total > this.hotelList.length;
    this.loadingState = false;
  }

  // Показать еще. Для текущей модели пагинации увеличиваем номер страницы
  async loadMore() {
    Pagination.createLoadMorePagination(this.pagination);
    const result = await this.$serviceLocator.getService(HotelService).findhHotels(this.currentRoute.query, this.pagination);
    this.hotelList = [...this.hotelList, ...result.data];
    this.showMoreEnabled = this.pagination.total > this.hotelList.length;
  }

  // Вот такой хак. Вью рутизация не позволяет программно переходить на тот же роут,
  // даже если query разные. Благо replace - это промис
  updateRouteQuery(filterModelAsPojo: any) {
      getModule(AppStore, this.$store).setRoute({
      name: "hotelsList",
      query: filterModelAsPojo,
      params: getModule(AppStore, this.$store).currentRoute?.params,
    });
  }

  onSortModeChanged({ id }) {
    if (id === 1) {
      this.extendedFilterModel.favorite = 0;
      this.extendedFilterModel.price = null;
    }
    if (id === 2) {
      this.extendedFilterModel.favorite = 1;
      this.extendedFilterModel.price = null;
    }
    if (id === 3) {
      this.extendedFilterModel.price = 0;
      this.extendedFilterModel.favorite = null;
    }
    if (id === 4) {
      this.extendedFilterModel.price = 1;
      this.extendedFilterModel.favorite = null;
    }
  }
}
