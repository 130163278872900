import { BaseViewModel } from "@/models/core/BaseViewModel";
import { email, required } from "vuelidate/lib/validators";

export class EmployeeModel extends BaseViewModel {
  id = 0;
  name = "";
  surname = "";
  patronymic = "";
  email = "";
  phone = "";
}

export const employeeValidationModel = {
  name: { required },
  surname: { required },
  patronymic: { required },
  phone: { required },
  email: { required, email },
};
