/* eslint-disable camelcase */
//@ts-ignore
import { HotelModel as CatalogHotelModel } from "@/modules/Catalog/models/CatalogHotelModel";
import { stringToNumber } from "@/utils/StringToNumber";
import { BaseService } from "@/services/BaseService";
import { ServiceLocator } from "@/ServiceLocator";
import { formatYYYMMDD } from "@/utils/Formaters";
import { ExcursionModel } from "@/modules/Excursions/models/ExcursionModel";
import { HotelModel } from "@/modules/Hotels/models/HotelModel";
import { RoomModel } from "@/modules/Hotels/models/RoomModel";
import { AdditionalServicesModel } from "../Tours/models/AdditionalServicesModel";
import { SeaTour } from "../Tours/models/SeaTour";
import { DirectionsCardModel } from "@/modules/Directions/models/DirectionsCardModel";
import { OrderModel, TicketModel } from "./models/OrderModel";
import {
  AppEntityType,
  DraftAppModel,
  ShortApplicationModel,
  PaymentsMethodsModel,
} from "./models/ApplicationModel";
import { OrderCalculator } from "./OrderCalculator";
import { getModule } from "vuex-module-decorators";
import AppStore from "@/store/AppStore";

export class ApplicationService extends BaseService {
  private get routePartName() {
    return "clients";
  }

  gotoCompleteDraftApp(draftAppId: number) {
    this.nuxtContext.app.router?.push({
      name: "order",
      params: { id: draftAppId.toString() },
    });
  }

  async getPaymentMethods(type: string) {
    return await this.getArrayOrEmpty(
      PaymentsMethodsModel,
      `search-module/${type}/applications/payment-methods/list`
    );
  }

  async toPayOrder(type: string, id: number, payment_id: number) {
    return await this.apiRequest.post(
      `search-module/${type}/applications/${id}/payments`,
      {},
      { payment_method: payment_id }
    );
  }

  async addDraftHotelApplication(
    childEdges: number[],
    hotel: HotelModel | CatalogHotelModel,
    selectedRoom: RoomModel
  ) {
    const app = new DraftAppModel();

    app.applicationable_id = hotel.id;
    app.applicationable_type = AppEntityType.HOTEL;
    app.child_count = +selectedRoom.people?.child?.split(" ")[0] || 0;
    app.child_ages = [...childEdges];
    app.adult_count = +selectedRoom.people?.adult?.split(" ")[0] || 1;

     const accommodationsObj: any = {
       hotel_id: hotel.id,
       hotel_room_id: selectedRoom.id,
       start_date: formatYYYMMDD(selectedRoom.from_date),
       finish_date: formatYYYMMDD(selectedRoom.to_date),
       price_total: selectedRoom.price,
       agency_commission: selectedRoom.agency_commission,
     };

     if (selectedRoom.meal?.id) {
       accommodationsObj.hotel_meal_id = selectedRoom.meal.id;
     } else if (selectedRoom?.external_info) {
       accommodationsObj.external_info = selectedRoom?.external_info;
     }
     app.accommodations = [accommodationsObj];

    const result = await this.apiRequest.post(
      `search-module/agencies/applications`,
      {},
      app
    );
    return result;
  }

  async addSeaTourDraftApp(
    childEdges: number[],
    fromCityId: number,
    toCityId: number,
    seaTour: SeaTour,
    selectedRoom: RoomModel
  ) {
    const draftModel = new DraftAppModel();
    draftModel.applicationable_id = seaTour.id;
    draftModel.applicationable_type = AppEntityType.SEA_TOURS;
    draftModel.child_count = +selectedRoom.people?.child?.split(" ")[0] || 0;
    draftModel.child_ages = [...childEdges];
    draftModel.adult_count = +selectedRoom.people?.adult?.split(" ")[0] || 1;
    draftModel.agency_commission = seaTour.agency_commission;

    let accommodationsObj: any = {
      hotel_id: seaTour.hotel.id,
      hotel_room_id: selectedRoom.id,
      start_date: formatYYYMMDD(selectedRoom.from_date),
      finish_date: formatYYYMMDD(selectedRoom.to_date),
      price_total: selectedRoom.price,
      agency_commission: selectedRoom.agency_commission,
    };

    if (selectedRoom.meal?.id) {
      accommodationsObj.hotel_meal_id = selectedRoom.meal.id;
    } else if (selectedRoom?.external_info) {
      accommodationsObj.external_info = selectedRoom?.external_info;
    }
    draftModel.accommodations = [accommodationsObj];

    const passCount = draftModel.child_count + draftModel.adult_count;
    if (!seaTour.transport_forth.forthCheckbox) {
      draftModel.transport_forth = {
        transport_id: seaTour.transport_forth.id,
        start_date: formatYYYMMDD(
          seaTour.transport_forth.routes.start.start_date
        ),
        finish_date: formatYYYMMDD(
          seaTour.transport_forth.routes.finish.finish_date
        ),
        count: passCount,
        price: +seaTour.transport_forth.seat_cost,
        // price_total: +seaTour.transport_forth.seat_cost * passCount,
        price_total:
          seaTour.transport_forth.total_price ||
          +seaTour.transport_forth.seat_cost * passCount,
        agency_commission: seaTour.transport_forth.agency_commission,
        from_city_id: fromCityId,
        to_city_id: toCityId,
      };
    }

    if (!seaTour.transport_back.backCheckbox) {
      draftModel.transport_back = {
        transport_id: seaTour.transport_back.id,
        start_date: formatYYYMMDD(
          seaTour.transport_back.routes.start.start_date
        ),
        finish_date: formatYYYMMDD(
          seaTour.transport_back.routes.finish.finish_date
        ),
        count: passCount,
        price: +seaTour.transport_back.seat_cost,
        // price_total: +seaTour.transport_back.seat_cost * passCount,
        price_total:
          seaTour.transport_back.total_price ||
          +seaTour.transport_back.seat_cost * passCount,
        agency_commission: seaTour.transport_back.agency_commission,
        from_city_id: toCityId,
        to_city_id: fromCityId,
      };
    }

    draftModel.seats = [];
    if (!!seaTour.transport_forth) {
      const seats = seaTour.transport_forth.seats.map((iter) => {
        return {
          transport_id: seaTour.transport_forth.id,
          number: iter,
          date: formatYYYMMDD(seaTour.transport_forth.routes.start.start_date),
          from_city_id: seaTour.transport_forth.routes.start.point_city.id,
          to_city_id: seaTour.transport_forth.routes.finish.point_city.id,
        };
      });
      draftModel.seats = [...seats];
    }

    if (!!seaTour.transport_back) {
      const seats = seaTour.transport_back.seats.map((iter) => {
        return {
          transport_id: seaTour.transport_back.id,
          number: iter,
          date: formatYYYMMDD(seaTour.transport_back.routes.start.start_date),
          from_city_id: seaTour.transport_back.routes.start.point_city.id,
          to_city_id: seaTour.transport_back.routes.finish.point_city.id,
        };
      });
      draftModel.seats = [...draftModel.seats, ...seats];
    }

    const result = await this.apiRequest.post(
      `search-module/agencies/applications`,
      {},
      draftModel
    );

    return result;
  }

  async addExcersionTourDraftApp(
    childEdges: number[],
    fromCityId: number,
    toCityId: number,
    excursionModel: ExcursionModel,
    selectedRooms: RoomModel[],
    additionalServices: AdditionalServicesModel[],
    ToCityBack: number,
    start_date: string | (string | null)[]
  ) {
    const findRoomByHotel = (hotelId: number) => {
      return selectedRooms.find((iter) => iter.hotel_id === hotelId);
    };

    const draftModel = new DraftAppModel();

    draftModel.applicationable_id = excursionModel.id;
    draftModel.applicationable_type = AppEntityType.TOURS;
    draftModel.child_count = excursionModel.people?.child?.split(" ")[0] || 0;
    draftModel.child_ages = [...childEdges];
    draftModel.adult_count = excursionModel.people?.adult?.split(" ")[0] || 1;
    draftModel.agency_commission = excursionModel.agency_commission;
    draftModel.meal_price = excursionModel.meal_price;
    draftModel.excursion_price = excursionModel.excursion_price;

    const hotels: any[] = [];
    excursionModel.hotels.forEach((iter) => {
      const room = findRoomByHotel(iter.id);
      if (!!room) {
        const accroom: any = {
          hotel_id: iter.id,
          hotel_room_id: room.id,
          hotel_meal_id:
            !!room.meals && !!room.meals.length ? +room.meals[0].id : undefined,
          start_date: formatYYYMMDD(room?.from_date),
          finish_date: formatYYYMMDD(room?.to_date),
          price_total: room?.price,
          agency_commission: room.agency_commission,
        };

        if (room?.external_info) {
          accroom.external_info = room?.external_info;
        }

        hotels.push(accroom);
      }
    });

    draftModel.accommodations = hotels;

    const peoplesCount = +excursionModel.people.total.split(" ")[0];

    if (
      !!excursionModel.transport_forth &&
      !excursionModel.transport_forth.forthCheckbox
    ) {
      draftModel.transport_forth = {
        transport_id: excursionModel.transport_forth?.id,
        start_date: formatYYYMMDD(
          excursionModel.transport_forth.routes.start.start_date
        ),
        finish_date: formatYYYMMDD(
          excursionModel.transport_forth.routes.finish.finish_date
        ),
        count: peoplesCount,
        price: +excursionModel.transport_forth.seat_cost,
        price_total:
          excursionModel.transport_forth.total_price ||
          +excursionModel.transport_forth.seat_cost * peoplesCount,
        agency_commission: excursionModel.transport_forth.agency_commission,
        from_city_id: fromCityId,
        to_city_id: toCityId,
      };
    }

    if (
      !!excursionModel.transport_back &&
      !excursionModel.transport_back.backCheckbox
    ) {
      draftModel.transport_back = {
        transport_id: excursionModel.transport_back?.id,
        start_date: formatYYYMMDD(
          excursionModel.transport_back?.routes.start.start_date
        ),
        finish_date: formatYYYMMDD(
          excursionModel.transport_back?.routes.finish.finish_date
        ),
        count: peoplesCount,
        price: +excursionModel.transport_back?.seat_cost,
        price_total:
          excursionModel.transport_back?.total_price ||
          +excursionModel.transport_back?.seat_cost * peoplesCount,
        agency_commission: excursionModel.transport_back?.agency_commission,
        from_city_id: toCityId,
        to_city_id: ToCityBack,
      };
    }

    draftModel.seats = [];
    if (excursionModel.transport_forth) {
      const seats = excursionModel.transport_forth?.seats.map((iter) => {
        return {
          transport_id: excursionModel.transport_forth?.id,
          number: iter,
          date: formatYYYMMDD(
            excursionModel.transport_forth?.routes.start.start_date
          ),
          from_city_id:
            excursionModel.transport_forth?.routes.start.point_city.id,
          to_city_id:
            excursionModel.transport_forth?.routes.finish.point_city.id,
        };
      });
      draftModel.seats = [...seats];
    }

    if (excursionModel.transport_back) {
      const seats = excursionModel.transport_back?.seats.map((iter: any) => {
        return {
          transport_id: excursionModel.transport_back?.id,
          number: iter,
          date: formatYYYMMDD(
            excursionModel.transport_back?.routes.start.start_date
          ),
          from_city_id:
            excursionModel.transport_back?.routes.start.point_city.id,
          to_city_id:
            excursionModel.transport_back?.routes.finish.point_city.id,
        };
      });
      draftModel.seats = [...draftModel.seats, ...seats];
    }

    const services = additionalServices.map((iter) => {
      return {
        serviceable_id: iter.id,
        count: +iter.count,
        price: +iter.sum,
        price_total: +iter.count * +iter.sum,
      };
    });
    if (!draftModel.seats.length) {
      for (let i = 0; i < excursionModel?.seatsCount; i++) {
        draftModel.seats.push({
          transport_id: excursionModel.transport_forth?.id,
          number: null,
          date: formatYYYMMDD(
            excursionModel.transport_forth?.routes.start.start_date
          ),
          from_city_id:
            excursionModel.transport_forth?.routes.start.point_city.id,
          to_city_id:
            excursionModel.transport_forth?.routes.finish.point_city.id,
        });
        draftModel.seats.push({
          transport_id: excursionModel.transport_back?.id,
          number: null,
          date: formatYYYMMDD(
            excursionModel.transport_back?.routes?.start.start_date
          ),
          from_city_id:
            excursionModel.transport_back?.routes?.start.point_city.id,
          to_city_id:
            excursionModel.transport_back?.routes?.finish.point_city.id,
        });
      }
    }
    if (start_date) {
      draftModel.start_date = start_date;
    }

    if (!!services && services.length > 0) {
      draftModel.services_type = "tour_services";
      draftModel.services = services as any;
    }
    const result = await this.apiRequest.post(
      `search-module/agencies/applications`,
      {},
      draftModel
    );
    return result;
  }

  async addDirectionDraft(
    childEdges: number[],
    from: DirectionsCardModel,
    to: DirectionsCardModel,
    adultCount: number,
    childCoint: number
  ) {
    const draftModel = new DraftAppModel();

    draftModel.applicationable_id = from.id;
    draftModel.applicationable_type = AppEntityType.TRANSPORT;
    draftModel.child_count = childCoint;
    draftModel.child_ages = [...childEdges];
    draftModel.adult_count = adultCount;

    draftModel.transport_forth = {
      transport_id: from.id,
      start_date: formatYYYMMDD(from.routes.start.start_date),
      finish_date: formatYYYMMDD(from.routes.finish.finish_date),
      count: adultCount + childCoint,
      price: +from.seat_cost,
      price_total: from.total_price,
      agency_commission: from.agency_commission,
      from_city_id: from.from_city_id,
      to_city_id: from.to_city_id,
    };
    draftModel.seats = [...from.seats];

    if (!!to && to.id > 0) {
      draftModel.transport_back = {
        transport_id: to.id,
        start_date: formatYYYMMDD(to.routes.start.start_date),
        finish_date: formatYYYMMDD(to.routes.finish.finish_date),
        count: adultCount + childCoint,
        price: +to.seat_cost,
        price_total: to.total_price,
        agency_commission: to.agency_commission,
        from_city_id: to.from_city_id,
        to_city_id: to.to_city_id,
      };
      draftModel.seats = [...from.seats, ...to.seats];
    }

    const result = await this.apiRequest.post(
      `search-module/agencies/applications`,
      {},
      draftModel
    );
    return result;
  }

  async getOrderByDraft(appId: number) {
    const result = await this.getApplicationById(appId);
    const orderModel = this.convertDraftModelToOrderModel(result);
    const totlaPersons = result.adult_count + result.child_count;

    if (!orderModel.tourists || orderModel.tourists.length === 0) {
      const tickets: TicketModel[] = [];
      for (let index = 0; index < totlaPersons; index++) {
        tickets.push(new TicketModel());
      }
      orderModel.tourists = tickets;
    }
    return orderModel;
  }

  async getAllApplications(isHistory = false, search?: string) {
    return await this.getArrayOrEmpty(
      DraftAppModel,
      `search-module/${this.routePartName}/applications?limit=100&type=${
        isHistory ? 0 : 1
      }&query=${search || ""}`
    );
  }

  async updateOrder(
    data: {
      id: number;
      name: string;
      sum: number;
      count: number;
    },
    appId: number
  ) {
    await this.apiRequest.put(
      `/front/agencies/applications/${appId}/services/${data.id}`,
      {},
      { count: data.count || 0 }
    );
  }

  async updateTourists(toursits: TicketModel[], id: number) {
    await this.apiRequest.put(
      `front/agencies/applications/${id}/tourists`,
      {},
      { toursits }
    );
  }

  convertDraftModelToOrderModel(draft: DraftAppModel): OrderModel {
    const orderModel: OrderModel = new OrderModel();
    orderModel.appId = draft.id;
    orderModel.applicationable_type = draft.applicationable_type;
    orderModel.status = draft.status;
    orderModel.can_edit = draft.can_edit;
    orderModel.statusName = draft.status_name;
    orderModel.statusColor = this.getStatusColor(draft.status);
    orderModel.number = draft.number;
    orderModel.applicationable = draft.applicationable;
    orderModel.documents = draft.documents;
    orderModel.tourists = draft.tourists;
    orderModel.phone = draft.phone;
    orderModel.payments_info = draft.payments_info;
    orderModel.payment_status_name = draft.payment_status_name;
    orderModel.email = draft.email;
    orderModel.agencyCommission = stringToNumber(
      draft.agency_commission?.toString()
    );
    orderModel.manager_email = draft.manager_email;
    orderModel.manager_name = draft.manager_name;
    orderModel.manager_phone = draft.manager_phone;
    orderModel.agency_user = draft.agency_user;
    orderModel.tourists = draft.tourists;

    if (
      !!draft.accommodations &&
      draft.accommodations.length &&
      draft.accommodations.length > 0
    ) {
      orderModel.priceTotal = +draft.accommodations[0].price_total || 0;
      orderModel.priceRooms = +draft.accommodations[0].price_total || 0;
      orderModel.start_date = draft.accommodations[0].start_at as any;
      orderModel.finish_date = draft.accommodations[0].finish_at as any;
      orderModel.duration = draft.accommodations[0]?.duration;

      // draft.accommodations.forEach((iter) => {
      //   orderModel.agencyCommission = orderModel.agencyCommission + stringToNumber(iter.agency_commission);
      // });
    } else if (draft.start_date || draft.finish_date) {
      orderModel.start_date = draft.start_date;
      orderModel.finish_date = draft.finish_date;
    }
    orderModel.adult_count = draft.adult_count;
    orderModel.child_count = draft.child_count;
    orderModel.people = draft.people;
    orderModel.seats = [...draft.seats];

    if (draft.applicationable_type.endsWith("\\SeaTour")) {
      orderModel.priceTotal = orderModel.priceRooms || 0;
      if (!!draft.transport_forth) {
        orderModel.priceTotal =
          orderModel.priceTotal +
          stringToNumber(draft.transport_forth.price_total);
        // orderModel.agencyCommission = orderModel.agencyCommission + stringToNumber(draft.transport_forth.agency_commission);
      }
      if (!!draft.transport_back) {
        orderModel.priceTotal =
          orderModel.priceTotal +
          stringToNumber(draft.transport_back.price_total);
        // orderModel.agencyCommission = orderModel.agencyCommission + stringToNumber(draft.transport_back.agency_commission);
      }
    }

    if (draft.applicationable_type.endsWith("\\Tour")) {
      const servicesPrice = draft.services.reduce((prev, item) => {
        return prev + stringToNumber(item.price_total);
      }, 0);

      let priceRooms = 0;
      draft.accommodations.forEach((iter) => {
        priceRooms = priceRooms + stringToNumber(iter.price_total);
      });
      const priceTotal =
        priceRooms +
        servicesPrice +
        stringToNumber(draft.meal_price) +
        stringToNumber(draft.excursion_price);
      orderModel.priceRooms = priceRooms;
      orderModel.priceTotal = priceTotal;
      if (!!draft.transport_forth) {
        orderModel.priceTotal =
          orderModel.priceTotal +
          stringToNumber(draft.transport_forth.price_total);
        // orderModel.agencyCommission = orderModel.agencyCommission + stringToNumber(draft.transport_forth.agency_commission);
      }
      if (!!draft.transport_back) {
        orderModel.priceTotal =
          orderModel.priceTotal +
          stringToNumber(draft.transport_back.price_total);
        // orderModel.agencyCommission = orderModel.agencyCommission + stringToNumber(draft.transport_back.agency_commission);
      }
    }

    if (draft.applicationable_type.endsWith("\\Transport")) {
      orderModel.priceTotal = 0;
      if (!!draft.transport_forth) {
        orderModel.priceTotal =
          orderModel.priceTotal +
          stringToNumber(draft.transport_forth.price_total);
        // orderModel.agencyCommission = orderModel.agencyCommission + stringToNumber(draft.transport_forth.agency_commission);
      }
      if (!!draft.transport_back) {
        orderModel.priceTotal =
          orderModel.priceTotal +
          stringToNumber(draft.transport_back.price_total);
        // orderModel.agencyCommission = orderModel.agencyCommission + stringToNumber(draft.transport_back.agency_commission);
      }
    }

    if (!!draft.transport_forth) {
      orderModel.start_date = draft.transport_forth.start_at as any;
    }

    if (!!draft.transport_back) {
      orderModel.finish_date = draft.transport_back.finish_at as any;
    }

    orderModel.draftModel = draft;

    return orderModel;
  }

  async getChatApplication(id: number) {
    const url = `search-module/${this.routePartName}/chat?application_id=${id}`;
    const result = await this.apiRequest.getJSON(url);
    return result;
  }

  async sendChatMessage(app: any) {
    const url = `search-module/${this.routePartName}/chat`;
    const result = await this.apiRequest.post(url, {}, app);
    return result;
  }

  async getAllShortApplicationModel(
    isHistory = false,
    search?: string
  ): Promise<ShortApplicationModel[]> {
    const result: ShortApplicationModel[] = [];
    const appList = await this.getAllApplications(isHistory, search);

    try {
      if (!!appList && !!appList.length) {
        return appList.map((iter) => {
          const iterOrder = this.convertDraftModelToOrderModel(iter);
          return this.buildShortApplicationModel(iterOrder);
        });
      }
    } catch (err: any) {}
    return result;
  }

  async getApplicationById(id: number) {
    return await this.getOneOrFail(
      DraftAppModel,
      `search-module/agencies/applications/${id}`
    );
  }

  async saveOrder(order: OrderModel) {
    order.phone = order.phone.startsWith("+") ? order.phone : "+" + order.phone;
    if (!!order.manager_phone) {
      order.manager_phone = order.manager_phone.startsWith("+")
        ? order.manager_phone
        : "+" + order.manager_phone;
    }

    return await this.apiRequest.put(
      `search-module/agencies/applications/${order.appId}`,
      {},
      order
    );
  }

  checkAuth() {
    const isAuth = false;
    return isAuth;
  }

  buildShortApplicationModel(orderModel: OrderModel): ShortApplicationModel {
    const result = new ShortApplicationModel();

    const calc = new OrderCalculator(orderModel);

    result.id = orderModel.appId;
    result.status = orderModel.status;
    result.statusName = orderModel.statusName;
    result.payments_info = orderModel.payments_info;
    result.payment_status_name = orderModel.payment_status_name;
    result.statusColor = this.getStatusColor(orderModel.status);
    result.number = orderModel.number;

    result.typeName = calc.typeName;
    result.dateFrom = calc.inDate;
    result.dateTo = calc.outDate;
    result.simpleDatesInterval = calc.simpleDatesInterval;
    result.directionOrHotelName = calc.name;
    result.directionOrHoteCaption = calc.directionOrHoteCaption;
    result.tourOrHotelShortDescription = calc.tourOrHotelShortDescription;
    result.totalPrice = calc.totalPrice;

    result.firstBlockCaption = calc.firstBlockCaption;
    result.secondBlockCaption = calc.secondBlockCaption;
    result.showSecondDetailBlock = calc.showSecondDetailBlock;
    result.secondBlockName = calc.secondBlockName;
    result.secondBlockShortDescription = calc.secondBlockShortDescription;

    result.documents = orderModel.documents;

    result.agencyCommissionTotal = orderModel.agencyCommission;

    return result;
  }

  getStatusColor(status: number) {
    switch (status) {
      case 1:
        return "#16192C";
      case 2:
        return "#3068F7";
      case 3:
        return "#FF4E4E";
      case 4:
        return "#1AC035";
      case 5:
        return "#9495A6";
      default:
        return "#9495A6";
    }
  }

  async deleteDraft(id: number) {
    return await this.apiRequest.delete(
      `search-module/${this.routePartName}/applications/${id}`
    );
  }
}
