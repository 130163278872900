
import { Vue, Component, Prop } from "vue-property-decorator";
import dayjs from "dayjs";
import { RouteLink } from "@/models/core/RouteLink";
import { dayName2, formatDateStandart } from "@/utils/Formaters";
import { HotelModel } from "@/modules/Hotels/models/HotelModel";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import TourFavoritesAndShownService from "../TourFavoritesAndShownService";
import AppStore from "@/store/AppStore";
import { getModule } from "vuex-module-decorators";
import ModalHotelMap from "@/modules/Hotels/components/ModalHotelMap.vue";
import HotelRoomsAccordion from "@/modules/Hotels/components/HotelRoomsAccordion.vue";
import ChooseSeatsModalExcursion from "@/components/ChooseSeatsModalExcursion.vue";
import { slugCreater } from "@/utils/SlugSplit";

@Component({
  components: { HotelRoomsAccordion, ModalHotelMap, ChooseSeatsModalExcursion },
})
export default class TourListItem extends Vue {
  @Prop()
  hotelModel: HotelModel;

  @Prop()
  additionalMenuItems: RouteLink[];

  @Prop({ default: false })
  isFromSelecton: boolean;

  @Prop()
  detailPageRouteQuery: any;

  showModalMap: boolean = false;
  seatAvailability: boolean = false;
  isChoosePlaceModal: boolean = false;
  isBack: boolean = false;

  get pageRouteQuery() {
    return (
      this.detailPageRouteQuery ??
      getModule(AppStore, this.$store).currentRoute?.query
    );
  }

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  get isOneWay() {
    return (
      this.currentRoute.query?.date_from === this.currentRoute.query?.date_to ||
      !this.currentRoute.query?.date_to
    );
  }

  openBusModal() {
    this.isChoosePlaceModal = true;
  }

  get selectedDirection() {
    return this.isBack
      ? this.hotelModel?.transport?.transport_back
      : this.hotelModel?.transport?.transport_forth;
  }

  get price() {
    return `${this.hotelModel.price_from?.toLocaleString("ru-RU")} ₽`;
  }

  get fromCityId() {
    return this.isBack
      ? this.hotelModel?.transport?.transport_back?.routes?.start?.point_city
          ?.id
      : this.hotelModel?.transport?.transport_forth?.routes?.start?.point_city
          ?.id;
  }

  get toCityId() {
    return this.isBack
      ? this.hotelModel?.transport?.transport_back?.routes?.finish?.point_city
          ?.id
      : this.hotelModel?.transport?.transport_forth?.routes?.finish?.point_city
          ?.id;
  }

  routeParam(id: number, slug: string | undefined) {
    return slugCreater(id, slug);
  }

  async toogleFavorites() {
    const filterModel = BaseViewModel.fromPlainIfNotClass(
      BaseFilterModel,
      getModule(AppStore, this.$store).currentRoute.query
    );
    await this.$serviceLocator
      .getService(TourFavoritesAndShownService)
      .toogleSeaTourFavor(this.hotelModel, filterModel);
  }

  clickRemoveItem() {
    this.$emit("on-remove-from-favor", this.hotelModel);
  }

  goToCard() {
    getModule(AppStore, this.$store).setRoute({
      name: "tours-card",
      params: { slug: `${this.hotelModel.sea_tour_id}` },
      query: { hotel_id: this.hotelModel.id, ...this.pageRouteQuery },
    });
  }

  get seaDistance() {
    return this.hotelModel.sea_distance
      ? `До моря ${this.hotelModel.sea_distance}`
      : "";
  }

  get startDate() {
    return !!this.hotelModel.start_date
      ? formatDateStandart(this.hotelModel.start_date)
      : "";
  }

  get startDateAdd() {
    return !!this.hotelModel.start_date
      ? `(${dayName2(this.hotelModel.start_date)}) Отправление`
      : "";
  }

  get durationNights() {
    return !!this.hotelModel.duration
      ? `на ${this.hotelModel.duration?.nights}`
      : "";
  }

  get durationNightsAdd() {
    return !!this.hotelModel.finish_date
      ? `до ${dayjs(this.hotelModel.finish_date).format("DD.MM")}`
      : "";
  }
}
