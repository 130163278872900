
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ModalShare extends Vue {
  @Prop({ required: true })
  showModal: boolean;

  shareUrl: string = "";

  beforeOpen() {
    document.documentElement.style.overflowY = "hidden";
  }

  beforeClose() {
    document.documentElement.style.overflowY = "auto";
  }

  mounted() {
    this.shareUrl = window.location.href;
  }

  shareLink(type: string) {
    // const url = window.location.origin + window.location.pathname;
    const url = window.location.href;

    if (type === "vk") {
      window.open(`https://vk.com/share.php?url=${url}`, "_blank");
    }
    if (type === "telegram") {
      window.open(`https://t.me/share/url?url=${url}`, "_blank");
    }
    if (type === "whatsapp") {
      window.open(
        `https://api.whatsapp.com/send?text=${url}&url=${url}`,
        "_blank"
      );
    }
    if (type === "instagram") {
      window.open(`https://www.instagram.com/?url=${url}`, "_blank");
    }
    if (type === "copy") {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          this.$notify.success("Ссылка скопирована");
        })
        .catch((err) => {});
    }
  }
}
