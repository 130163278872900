
import { Component, Prop, Vue } from "vue-property-decorator";

import SwiperInstance, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { SwiperOptions, Swiper } from "swiper/swiper.d";
import { v4 as uuidv4 } from "uuid";
import "swiper/swiper.scss";

SwiperInstance.use([Navigation, Pagination, A11y, Autoplay]);

@Component
export default class BaseCarousel extends Vue {
  @Prop({ default: () => uuidv4() })
  id;

  @Prop({ required: true })
  propsArray: any[];

  @Prop({ default: 3 })
  minCountSlide: number;

  @Prop({ default: true })
  autoplay: boolean;

  @Prop({ default: "" })
  classes: string;

  @Prop({ default: "" })
  classSlide: string;

  @Prop({ default: "" })
  classControl: string;

  @Prop({ default: true })
  isPerViewOne: boolean;

  @Prop({ default: true })
  noProgress: boolean;

  @Prop({ default: 30 })
  spaceBetween: number;

  private swiperInstance: Swiper = {} as Swiper;

  private settings: SwiperOptions = {
    slidesPerView: "auto",
    spaceBetween: 30,
    navigation: {
      nextEl: ".Carousel__next",
      prevEl: ".Carousel__prev",
    },
    pagination: {
      el: ".Carousel__pagination",
      type: "progressbar",
    },
  };

  mounted() {
    if (this.isPerViewOne) {
      this.swiperInstance = new SwiperInstance(`.Carousel_${this.id}`, {
        ...this.settings,
        ...{
          autoplay: {
            disableOnInteraction: this.autoplay,
            delay: 10000,
          },
        },
        slidesPerView: 1,
      });
    } else {
      this.swiperInstance = new SwiperInstance(`.Carousel_${this.id}`, {
        ...this.settings,
        ...{
          autoplay: {
            disableOnInteraction: this.autoplay,
            delay: 10000,
          },
        },
        breakpoints: {
          300: {
            slidesPerView: 1,
          },
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 3,
          },
        },
        spaceBetween: this.spaceBetween,
      });
    }
  }
}
