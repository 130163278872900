
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { minLength, required } from "vuelidate/lib/validators";
import {
  dateMask,
  passportSeriasAndNumber,
  validateBirthCertificate,
} from "@/utils/InputMaskDefinitions";
import TouristLastNameSearch from "@/modules/Order/components/TouristLastNameSearch.vue";
import { TouristToursService } from "@/modules/Tourist/TouristToursService";
import { v4 as uuidv4 } from "uuid";
import { TicketModel } from "../models/OrderModel";
import { Validations } from "vuelidate-property-decorators";

@Component({ components: { TouristLastNameSearch } })
export default class FormTicket extends Vue {
  @Prop({ required: true })
  value: TicketModel;

  @Prop({ required: true })
  cardName: string;

  @Prop({ default: false })
  needValidate;

  @Prop({ default: false })
  editable: boolean;

  @Watch("needValidate")
  onNeedValidate() {
    this.validateForm();
  }

  internalValue = null;

  $refs!: {
    lastNameSearchComponent: any;
  };

  componentKey = uuidv4();

  saveFormToSorage() {
    this.$serviceLocator
      .getService(TouristToursService)
      .saveTouristToStorage(this.value);
  }

  submitToursit() {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.$emit("submit");
    }
  }

  async mounted() {
    const result = await this.$serviceLocator
      .getService(TouristToursService)
      .getDataTypes();
    if (result?.citizenship_types) {
      this.citizenship_types = result.citizenship_types;
    }
    if (result?.document_types) {
      this.document_types = result.document_types;
    }
    if (result?.gender_types) {
      this.gender_types = result.gender_types;
    }
  }

  get saveFormToStorageEnabled() {
    return !!this.value.document_number;
  }

  lastProfileSelected(profile: TicketModel) {
    Object.assign(this.value, profile);
    this.componentKey = uuidv4();
  }

  validateForm() {
    this.$refs.lastNameSearchComponent.validateLastName();
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.$emit("on-validate");
    }
  }

  get firstName() {
    return this.capitalizeFirstChar(this.value.first_name);
  }

  set firstName(val: string) {
    this.value.first_name = this.capitalizeFirstChar(val);
  }

  get lastName() {
    return this.capitalizeFirstChar(this.value.last_name);
  }

  set lastName(val: string) {
    this.value.last_name = this.capitalizeFirstChar(val);
  }

  get patronymic() {
    return this.capitalizeFirstChar(this.value.patronymic);
  }

  set patronymic(val: string) {
    this.value.patronymic = this.capitalizeFirstChar(val);
  }

  capitalizeFirstChar(val: string) {
    return !!val
      ? val.charAt(0).toUpperCase() + val.slice(1).toLowerCase()
      : val;
  }

  gender_types = [
    { id: 1, label: "М" },
    { id: 2, label: "Ж" },
  ];

  document_types = [
    { id: 1, label: "Паспорт РФ" },
    { id: 2, label: "Свидетельство о рождении" },
  ];

  citizenship_types = [
    { id: 1, label: "РФ" },
    // { id: 2, label: "РОССИЯ" },
  ];

  get dateMask() {
    return dateMask;
  }

  get documentNumberMask() {
    return passportSeriasAndNumber;
  }

  get isDocumentPassport() {
    return this.value.document === 1;
  }

  get documentNumberValidationErrorMessage() {
    return this.value.document === 2
      ? "Введите номер свидетельства (VАМ123456)"
      : "Введите серию и номер паспорта (без пробела)";
  }

  @Validations()
  validations() {
    return {
      value: {
        first_name: { required },
        last_name: { required },
        patronymic: { required },
        birthday_date: { required },
        document: { required },
        document_number: { required },
        citizenship: { required },
        gender: { required },
      },
    };
  }
}
