
import { Vue, Component, Prop } from "vue-property-decorator";
import TheContainer from "@/components/TheContainer.vue";
import ExcursionProgram from "@/modules/Excursions/components/ExcursionProgram.vue";
import TourDate from "@/modules/Excursions/components/TourDate.vue";
import ModalIntermediatePoints from "@/modules/Excursions/components/ModalIntermediatePoints.vue";
import AppStore from "@/store/AppStore";
import TourAside from "@/modules/Tours/components/TourAside.vue";
import TourPageHotel from "@/modules/Tours/components/TourPageHotel.vue";
import ModalInfrastructure from "@/modules/Tours/components/ModalInfrastructure.vue";
import TourManager from "@/modules/Tours/components/TourManager.vue";
import TourTransport from "@/modules/Tours/components/TourTransport.vue";
import ExcursionHotel from "@/modules/Excursions/components/ExcursionHotel.vue";
import ModalHotelMore from "@/modules/Excursions/components/ModalHotelMore.vue";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import ChooseSeatsModalExcursion from "@/components/ChooseSeatsModalExcursion.vue";
import BaseFilter from "@/components/filter/BaseFilter.vue";
import { formatDateStandart } from "@/utils/Formaters";
import HotelRooms from "@/modules/Hotels/components/HotelRooms.vue";
import BaseModalGallery from "@/components/BaseModalGallery.vue";
import { ApplicationService } from "../Order/ApplicationService";
import { HotelModel } from "../Hotels/models/HotelModel";
import {
  ExcursionRoutesElModel,
  ExcursionTransportForthModel,
} from "../Excursions/models/ExcursionModel";
import { HotelService } from "../Hotels/HotelService";
import { PhotoModel, RoomModel } from "../Hotels/models/RoomModel";
import { SeaTour } from "./models/SeaTour";
import TourFavoritesAndShownService from "./TourFavoritesAndShownService";
import { TourService } from "./TourService";
import { getModule } from "vuex-module-decorators";
import { Notification } from "element-ui";

@Component({
  components: {
    TheContainer,
    ChooseSeatsModalExcursion,
    BaseFilter,
    ExcursionProgram,
    ExcursionHotel,
    TourAside,
    TourManager,
    TourTransport,
    TourDate,
    ModalIntermediatePoints,
    ModalHotelMore,
    HotelRooms,
    BaseModalGallery,
    TourPageHotel,
    ModalInfrastructure,
  },
})
export default class TourCardPage extends Vue {
  @Prop()
  slug: string;

  descrFull = false;

  excursionModel: SeaTour = new SeaTour();

  topFilterModel: BaseFilterModel = new BaseFilterModel();
  roomModel: RoomModel = new RoomModel();
  numberPrice: number = 0;
  isChoseHotel = false;
  isOpenItinerary = false;
  isOpenNoticeModal = false;
  isOpenNoticeSelectSeatsModal = false;
  isChoosePlaceModal = false;
  isOpenInfrastructure = false;
  isBack = false;
  selectedRooms: RoomModel[] = [];
  intermediates: ExcursionRoutesElModel[] = [];
  galleryImages: PhotoModel[] = [];
  showModal = false;
  rooms: RoomModel[] = [];
  roomsCountToShow = 4;
  selectedSeats: number[] = [];
  selectedBackSeats: number[] = [];

  tourManager = {
    image: this.$getImage("/images/manager.png"),
    full_name: "Мария Сомченко",
    position: "Менеждер тура",
  };

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  get routeQueryParams() {
    return Object.keys(this.currentRoute.query).length === 0
      ? this.$serviceLocator.getService(HotelService).getDefaultFilterParams()
      : this.currentRoute.query;
  }

  async mounted() {
    try {
      await this.updateData();
      this.loadBreadCrumbList();
      this.topFilterModel = BaseViewModel.fromPlainIfNotClass(
        BaseFilterModel,
        this.routeQueryParams
      );
    } catch (err: any) {
      throw err;
    }
    if (Object.keys(this.currentRoute.query).length === 0) {
      const defFolterPojo = this.$serviceLocator
        .getService(HotelService)
        .getDefaultFilterParams();
      getModule(AppStore, this.$store).setRoute({
        name: "tour-card",
        query: defFolterPojo,
        params: this.currentRoute.params,
      });
    }
  }

  get dateFrom() {
    return this.excursionModel.hotel.departure_day;
  }

  get duration() {
    return this.excursionModel.hotel.rooms[0]?.duration;
  }

  get peopleAndDates() {
    return (
      this.peopleTotal +
      " , c " +
      formatDateStandart(this.excursionModel.start_date) +
      " по " +
      formatDateStandart(this.excursionModel.finish_date)
    );
  }

  get peopleFroRooms() {
    return !!this.excursionModel.hotel.rooms &&
      this.excursionModel.hotel.rooms.length > 0
      ? this.excursionModel.hotel.rooms[0].people?.total
      : "нет";
  }

  get durationAndPeople() {
    return this.durationNights + ", " + this.adult + ", " + this.child;
  }

  get durationNights() {
    return !!this.excursionModel.hotel.rooms &&
      this.excursionModel.hotel.rooms.length > 0
      ? this.excursionModel.hotel.rooms[0].duration.nights
      : "нет";
  }

  get peopleTotal() {
    return !!this.excursionModel.hotel.rooms &&
      this.excursionModel.hotel.rooms.length > 0
      ? this.excursionModel.hotel.rooms[0].people?.total
      : "нет";
  }

  passangersCount() {
    return !!this.excursionModel.hotel.rooms &&
      this.excursionModel.hotel.rooms.length > 0
      ? parseInt(this.excursionModel.hotel.rooms[0].people?.total.split(" ")[0])
      : 1;
  }

  get people() {
    return !!this.excursionModel.hotel.rooms &&
      this.excursionModel.hotel.rooms.length > 0
      ? this.excursionModel.hotel.rooms[0].people
      : {};
  }

  get adult() {
    return !!this.excursionModel.hotel.rooms &&
      this.excursionModel.hotel.rooms.length > 0
      ? this.excursionModel.hotel.rooms[0].people?.adult
      : "нет";
  }

  get child() {
    return !!this.excursionModel.hotel.rooms &&
      this.excursionModel.hotel.rooms.length > 0
      ? this.excursionModel.hotel.rooms[0].people?.child
      : "нет";
  }

  showMoreRooms() {
    this.rooms = this.excursionModel.hotel.rooms?.slice(
      0,
      this.rooms.length + this.roomsCountToShow
    );
  }

  selectedRoomChanged(room: RoomModel) {
    this.selectedRooms = [];
    this.selectedRooms.push(room);
  }

  isRommSelected(room: RoomModel) {
    return !!this.selectedRooms.find(
      (iter) => iter.id === room.id && iter.meal.id === room.meal.id
    );
  }

  async updateData() {
    this.excursionModel = await this.$serviceLocator
      .getService(TourService)
      .getSeaTour(this.currentRoute.params?.slug, this.routeQueryParams);
    this.rooms = this.excursionModel.hotel.rooms?.slice(
      0,
      this.roomsCountToShow
    );
  }

  loadBreadCrumbList() {
    const breadCrumbList = [
      {
        title: "Туры ...",
        routeName: "toursList",
        query: this.currentRoute.query,
      },
      { title: this.excursionModel.name },
    ];
    getModule(AppStore, this.$store).updateBreadCrumbList(breadCrumbList);
  }

  openItinerary(model: ExcursionTransportForthModel) {
    this.intermediates = model.routes.intermediates;
    this.isOpenItinerary = true;
  }

  chosePlace(model: ExcursionTransportForthModel, isBack: boolean) {
    this.isBack = isBack;
    this.excursionModel.transport_forth = !this.isBack
      ? model
      : this.excursionModel.transport_forth;
    this.excursionModel.transport_back = this.isBack
      ? model
      : this.excursionModel.transport_back;
    this.isChoosePlaceModal = true;
  }

  isTransportSelected(forth: ExcursionTransportForthModel) {
    return (
      !!forth &&
      !!this.excursionModel.transport_forth &&
      !!this.selectedSeats.length
    );
  }

  isTransportBackSelected(back: ExcursionTransportForthModel) {
    return (
      !!back &&
      this.excursionModel.transport_back &&
      !!this.selectedBackSeats.length
    );
  }

  seatsSelected(selectedSeats: number[]) {
    this.selectedSeats = [...selectedSeats];
  }

  backSeatsSelected(selectedSeats: number[]) {
    this.selectedBackSeats = [...selectedSeats];
  }

  loadGallery(images: PhotoModel[]) {
    this.galleryImages = images;
    this.showModal = true;
  }

  toBook() {
    // this.showModalBus = true;
  }

  get fromCityId() {
    return this.routeQueryParams.from_city_id;
  }

  get toCityId() {
    return this.routeQueryParams.to_city_id;
  }

  async onBuy() {
    getModule(AppStore, this.$store).setOldRoute({
      ...this.currentRoute,
      title: this.excursionModel.name,
    });
    this.isOpenNoticeSelectSeatsModal = false;
    if (
      !this.excursionModel.transport_forth.forthCheckbox &&
      !this.selectedSeats.length
    ) {
      this.isOpenNoticeSelectSeatsModal = true;
      return;
    }

    if (
      !this.excursionModel.transport_back.backCheckbox &&
      !this.selectedBackSeats.length
    ) {
      this.isOpenNoticeSelectSeatsModal = true;
      return;
    }

    if (this.selectedRooms.length === 0) {
      this.isOpenNoticeModal = true;
    } else {
      this.excursionModel.transport_forth.seats = this.selectedSeats;
      this.excursionModel.transport_back.seats = this.selectedBackSeats;
      const toCityId = this.routeQueryParams.to_city_id;
      const fromCityId = this.routeQueryParams.from_city_id;
      try {
        const result = await this.$serviceLocator
          .getService(ApplicationService)
          .addSeaTourDraftApp(
            this.topFilterModel.child_ages,
            fromCityId,
            toCityId,
            this.excursionModel,
            this.selectedRooms[0]
          );
        getModule(AppStore, this.$store).setRoute({
          name: "order",
          query: this.currentRoute.query,
          params: { id: result.data.id },
        });
      } catch (e: any) {
        const err = e?.data?.message;
        Notification.warning({
          title: "Ошибка",
          message: err[Object.keys(err)?.[0]]?.[0] || "",
        });
      }
    }
  }

  async toogleFavorites() {
    this.excursionModel.hotel.sea_tour_id = this.excursionModel.id;
    await this.$serviceLocator
      .getService(TourFavoritesAndShownService)
      .toogleSeaTourFavor(this.excursionModel.hotel, this.topFilterModel);
  }
}
