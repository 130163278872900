
import { Component, Prop, Vue } from "vue-property-decorator";
import { decOfNum } from "@/utils/DecOfNum";
import { DirectionsCardModel } from "../models/DirectionsCardModel";

@Component
export default class DirectionsCard extends Vue {
  @Prop()
  card: DirectionsCardModel;

  @Prop({ default: 1 })
  passangersCount: number;

  get carrier() {
    return !!this.card.transportation.name ? this.card.transportation.name.split(" ")[0] : "не указано";
  }

  get departureTime() {
    return this.card.routes.start.start_time;
  }

  get departureFrom() {
    return this.card.routes.start.info;
  }

  get arrivalTime() {
    return this.card.routes.finish.finish_time;
  }

  get arrivalIn() {
    return this.card.routes.finish.info;
  }

  get price() {
    return this.card.total_price?.toLocaleString("ru-RU");
  }

  get passangersFor() {
    return "за " + this.passangersCount + " " + decOfNum(this.passangersCount, ["пассажира", "пассажиров", "предложений"]);
  }

  get duration() {
    return `${this.card.travel_hours} ч`;
  }

  conveniences = [
    {
      id: 3,
      name: "Аудио-видео система",
      icon: "audio",
    },

    {
      id: 4,
      name: "Кондиционер",
      icon: "condition",
    },

    {
      id: 5,
      name: "Вежливый экипаж",
      icon: "ekipazh",
    },

    {
      id: 6,
      name: "Ортопедические кресла",
      icon: "ortoped",
    },
  ];

  payments = [
    {
      id: 1,
      name: "Visa",
      icon: "visa",
    },
    {
      id: 2,
      name: "Mastercard",
      icon: "mastercard",
    },
    {
      id: 3,
      name: "Apple Pay",
      icon: "apple-pay",
    },
  ];
}
