
import { Component, Prop, Vue } from "vue-property-decorator";
import FunctionalCalendar from "vue-functional-calendar/src/components/FunctionalCalendar.vue";
import dayjs from "dayjs";

@Component({
  inheritAttrs: false,
  components: {
    FunctionalCalendar,
  },
})
export default class BaseCalendar extends Vue {
  public $refs!: {
    Calendar: any;
  };

  @Prop()
  config: any;

  @Prop()
  value: any;

  get valueModel() {
    return this.value || {};
  }

  set valueModel(val) {
    this.$emit("input", val);
  }

  get defaultConfig() {
    return {
      isModal: true,
      disabledDates: ["beforeToday"],
      enabledDates: [],
      dateFormat: "yyyy-mm-dd",
      isAutoCloseable: true,
      isLayoutExpandable: true,
      alwaysUseDefaultClasses: true,
      transition: true,
      dayNames: ["ПН", "ВТ", "СР", "ЧТ", "ПТ", "СБ", "ВС"],
      monthNames: [
        "ЯНВАРЬ",
        "ФЕВРАЛЬ",
        "МАРТ",
        "АПРЕЛЬ",
        "МАЙ",
        "ИЮНЬ",
        "ИЮЛЬ",
        "АВГУСТ",
        "СЕНТЯБРЬ",
        "ОКТЯБРЬ",
        "НОЯБРЬ",
        "ДЕКАБРЬ",
      ],
    };
  }

  get calendarConfigs() {
    return { ...this.defaultConfig, ...this.config };
  }

  get formatDateRange() {
    if (!!this.valueModel && !!this.valueModel.dateRange) {
      return `${!!this.valueModel.dateRange.start ? dayjs(this.valueModel.dateRange.start).format("DD.MM.YYYY") : ""} ${
        !!this.valueModel.dateRange.start && !!this.valueModel.dateRange.end ? "~" : ""
      } ${!!this.valueModel.dateRange.end ? dayjs(this.valueModel.dateRange.end).format("DD.MM.YYYY") : ""}`;
    }
    return null;
  }

  pickerClick() {
    this.$refs.Calendar.onFocusIn();
  }

  close() {
    this.$refs.Calendar.showCalendar = this.$refs.Calendar.showMonthPicker = this.$refs.Calendar.showYearPicker = false;
  }

  getCalendarDate(): Date {
    return this.$refs.Calendar.listCalendars[0].date;
  }

  getCalendarDateJsFormatted(): string {
    return dayjs(this.getCalendarDate()).format("YYYY-MM-DD")?.replace(/-0+/g, "-");
  }

  getCalendarDateFormatted(): string {
    return dayjs(this.getCalendarDate()).format("YYYY-MM-DD");
  }
}
