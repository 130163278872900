import { HotelCategoriesModel } from "./models/HotelCategoriesModel";
import { HotelSea } from "./models/HotelSea";
import { MealModel } from "./models/MealModel";
import { ServiceInHotel } from "./models/ServiceInHotel";
import { BaseService } from "@/services/BaseService";

export class HotelDictianarySerivce extends BaseService {
  public async getMealsList() {
    return await this.getArrayOrEmpty(
      MealModel,
      "search-module/hotels/meals/categories/list"
    );
  }

  public async getHotelServicesExtended(): Promise<ServiceInHotel[]> {
    const result = await this.getArrayOrEmpty(
      ServiceInHotel,
      "search-module/hotels/services/list"
    );
    return result.filter((iter) => iter.is_filter);
  }

  public async getHotelServicesMain(): Promise<ServiceInHotel[]> {
    const result = await this.getArrayOrEmpty(
      ServiceInHotel,
      "search-module/hotels/services/list"
    );
    return result.filter((iter) => iter.is_point_filter);
  }

  public async getHotelSeaDistances(): Promise<HotelSea[]> {
    return await this.getArrayOrEmpty(
      HotelSea,
      "search-module/hotels/sea-distances/list"
    );
  }

  async getHotelCategories(): Promise<HotelCategoriesModel[]> {
    return await this.getArrayOrEmpty(
      HotelCategoriesModel,
      "search-module/hotels/categories/list"
    );
  }
}
