
import { TouristToursService } from "@/modules/Tourist/TouristToursService";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { TicketModel } from "../models/OrderModel";

@Component({
  inheritAttrs: false,
})
export default class TouristLastNameSearch extends Vue {
  @Prop()
  value: string;

  internalValue = "";
  isVisible = true;
  needValidate = false;
  tourists: TicketModel[] | undefined = [];

  @Watch("value", { immediate: true })
  onValueChanged() {
    this.internalValue = this.value;
  }

  get internalLastName() {
    return this.value;
  }

  set internalLastName(val) {
    this.$emit("input", val);
    this.internalValue = val;
    this.tourists = this.$serviceLocator.getService(TouristToursService).searchTouristByLastName(val);
  }

  get hasError() {
    return this.needValidate && (!this.internalValue || this.internalValue?.trim().length === 0);
  }

  getFio(ticket: TicketModel) {
    return `${ticket.last_name} ${ticket.first_name} ${ticket.patronymic}`;
  }

  itemSelected(ticket: TicketModel) {
    this.isVisible = false;
    this.needValidate = false;
    this.internalValue = ticket.last_name;
    this.$emit("selected", ticket);
  }

  onInput(val) {
    this.isVisible = true;
    this.internalValue = val;
  }

  validateLastName() {
    this.needValidate = true;
  }
}
