var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (!!_vm.hotelModel && _vm.hotelModel.id > 0)?_c('div',[_c('div',{staticClass:"HotelItem"},[_c('div',{staticClass:"HotelItem__slider"},[_c('BaseSwiper',{attrs:{"slide-list":_vm.hotelModel.photos,"image-name":"url","type":"pagination-navigation","class-background":"HotelItem__background","is-background":true}})],1),_c('a',{staticClass:"HotelItem__wrap",on:{"click":function($event){return _vm.routeTo({
          name: 'hotel-card',
          params: { slug: _vm.hotelModel.id },
          query: _vm.pageRouteQuery,
        })}}},[_c('div',{staticClass:"HotelItem__info"},[_c('div',{staticClass:"HotelItem__top"},[_c('base-rating',{staticClass:"HotelItem__stars",attrs:{"rating":_vm.hotelModel.stars,"readonly":true}}),_c('base-rating',{staticClass:"HotelItem__rating",attrs:{"rating":_vm.hotelModel.rating,"name":"Рейтинг Авианны","no-stars":true}})],1),_c('div',{staticClass:"HotelItem__name"},[_vm._v(" "+_vm._s(_vm.hotelModel.name)+" ")]),_c('div',{staticClass:"HotelItem__address",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return (() => (_vm.showModalMap = true)).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.hotelModel.city.name)+_vm._s(", " + _vm.hotelModel.address)+" ")]),_c('div',{staticClass:"HotelItem__line"},[_c('a',{staticClass:"HotelItem__link",attrs:{"to":{
              name: 'hotel-card',
              params: { slug: _vm.hotelModel.id },
              query: _vm.pageRouteQuery,
            }},on:{"click":function($event){return _vm.routeTo({
                name: 'hotel-card',
                params: { slug: _vm.hotelModel.id },
                query: _vm.pageRouteQuery,
              })}}},[_c('img',{attrs:{"src":_vm.$getImage('/images/Hotels/hoteml-room.svg'),"alt":""}}),_vm._v(" Выбрать номер ")]),_c('div',{staticClass:"HotelItem__price discount_price"},[_vm._v(" от "+_vm._s(_vm.hotelModel.price_from ? _vm.hotelModel.price_from.toLocaleString("ru-RU") : 0)+" ₽ ")])])]),_c('div',{staticClass:"mt-20"},[(_vm.hotelModel.info)?_c('div',{staticClass:"mt-20"},[_c('div',{staticClass:"desc-discount text-14",domProps:{"innerHTML":_vm._s(_vm.hotelModel.info)}})]):_vm._e()]),_c('div',{staticClass:"HotelItem__bottom"},[_c('div',{staticClass:"HotelItem__services"},_vm._l((_vm.hotelModel.services),function(service){return _c('BaseService',{key:service.id,staticClass:"HotelItem__service",attrs:{"service":service,"small":true}})}),1),(_vm.hotelModel.rooms && _vm.hotelModel.rooms.length)?_c('div',{staticClass:"HotelItem__places",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();_vm.seatAvailability = !_vm.seatAvailability}}},[_vm._v(" Наличие мест ")]):_vm._e(),(!!_vm.hotelModel.promotion)?_c('div',{staticClass:"HotelItem__stock"},[_vm._v(" Акции "),_c('img',{attrs:{"src":_vm.$getImage('/images/Hotels/stock.svg'),"alt":"Акции"}})]):_vm._e()])])]),_c('HotelRoomsAccordion',{attrs:{"to":{
      name: 'hotel-card',
      params: { slug: _vm.routeParam(_vm.hotelModel.id, _vm.hotelModel.slug) },
      query: _vm.pageRouteQuery,
    },"rooms":_vm.hotelModel.rooms,"visible":_vm.seatAvailability}}),_c('modal-hotel-map',{attrs:{"show-modal":_vm.showModalMap,"address":_vm.hotelModel.address,"city":_vm.hotelModel.city.name},on:{"close":function($event){_vm.showModalMap = false}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }