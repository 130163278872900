
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class TheContainer extends Vue {
  @Prop()
  large: Boolean;

  @Prop()
  fluid: Boolean;

  @Prop()
  small: Boolean;
}
