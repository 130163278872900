
import { Component, Prop, Vue } from "vue-property-decorator";
import { ExcursionService } from "@/modules/Excursions/ExcursionService";
import { SeatsSVGService } from "@/modules/Excursions/SeatsSVGService";
import { ExcursionTransportSeat } from "@/modules/Excursions/models/ExcursionModel";
import { DirectionsCardModel } from "@/modules/Directions/models/DirectionsCardModel";
import { getModule } from "vuex-module-decorators";
import AppStore from "@/store/AppStore";

@Component
export default class ChooseSeatsModalExcursion extends Vue {
  @Prop({ required: true })
  showModal: boolean;

  @Prop({ default: false })
  isBack: boolean;

  @Prop({ required: true })
  titleModal: string;

  @Prop()
  tourists?;

  @Prop({ required: true })
  transport: DirectionsCardModel;

  @Prop({ default: 1 })
  passangersCount: number;

  @Prop({ default: false })
  isFromDirectionPage: boolean;

  @Prop({ default: false })
  isOneWay: boolean;

  @Prop()
  fromCityId: number;

  @Prop()
  toCityId: number;

  schema: string = "";

  selectedForthSeats: number[] = [];
  selectedBackSeats: number[] = [];

  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }

  get textBtn() {
    if (this.isFromDirectionPage) {
      return `${
        this.isOneWay ? "Перейти к покупке" : !this.isBack ? "Подтвердить и выбрать обратный рейс" : "Перейти к покупке"
      }`;
    }
    return `Выбрать места №: ${this.isBack ? this.selectedBackSeats.join(" , ") : this.selectedForthSeats.join(" , ")}`;
  }

  isSelectedEnabled() {
    return this.isBack
      ? this.passangersCount === this.selectedBackSeats.length
      : this.passangersCount === this.selectedForthSeats.length;
  }

  selectSeat(event: MouseEvent) {
    const target = event.target as HTMLElement | null;
    let seatNumber: any = target?.getAttribute("data-id");

    if (target?.matches('rect.js-seat:not([class*="js-occupied-seat"])') && seatNumber) {
      let selectedSeats = this.isBack ? this.selectedBackSeats : this.selectedForthSeats;
      seatNumber = parseInt(seatNumber);

      if (selectedSeats.length === this.passangersCount && !selectedSeats.includes(seatNumber)) {
        try {
          const lastSeatNmb = selectedSeats.pop();
          const lastSelectedDomSeatList = target.parentElement?.parentElement?.querySelectorAll(`*[data-id="${lastSeatNmb}"]`);
          if (!!lastSelectedDomSeatList && lastSelectedDomSeatList.length > 0) {
            lastSelectedDomSeatList[0].classList.remove("js-seat-group-selected");
            this.$emit("seat-unselect", seatNumber);
          }
        } catch {}
      }

      if (selectedSeats.includes(seatNumber)) {
        selectedSeats = selectedSeats.filter((seat) => seat !== seatNumber);
        if (this.isBack) {
          this.selectedBackSeats = [...selectedSeats];
        } else {
          this.selectedForthSeats = [...selectedSeats];
        }
        target.parentElement?.classList.remove("js-seat-group-selected");
        this.$emit("seat-unselect", seatNumber);
        return;
      }

      if (selectedSeats.length === this.passangersCount) {
        return;
      }

      target.parentElement?.classList.add("js-seat-group-selected");

      selectedSeats.push(seatNumber);

      this.$emit("seat-select", seatNumber);

      if (selectedSeats.length === this.passangersCount) {
        this.$emit("all-seats-selected", selectedSeats);
      }
    }
  }

  bay() {
    getModule(AppStore, this.$store).setOldRoute({...this.currentRoute, title: "Проезды"})
    if (!this.isBack) {
      this.$emit("seats-selected", this.selectedForthSeats);
      this.$emit("close");
      // this.$router.push({ name: "directions-bay" });
    } else {
      this.$emit("back-seats-selected", this.selectedBackSeats);
      this.$emit("close");
      // this.$router.push({ name: "directions", query: { type: "back" } });
    }
  }

  async beforeOpen() {
    const excursionTransportSeat: ExcursionTransportSeat = await this.$serviceLocator
      .getService(ExcursionService)
      .getExcursionTransportInfo(this.transport.id, this.fromCityId, this.toCityId, this.transport.routes.start.start_date);
    let chosen;

    const occupied = this.$serviceLocator.getService(ExcursionService).getOccupiedSeats(excursionTransportSeat);
    const selectedSeats = this.isBack ? this.selectedBackSeats : this.selectedForthSeats;
    if (this.tourists) {
      if (this.tourists.length > 0) {
        chosen = this.$serviceLocator.getService(ExcursionService).getChosenSeats(excursionTransportSeat, this.tourists);
      }
    }
    this.schema = this.$serviceLocator
      .getService(SeatsSVGService)
      .setSvg(excursionTransportSeat.transportation.schema_svg)
      .markOccupiedSeats(occupied, selectedSeats, chosen)
      .disableTextPointerEvents()
      .get();

    document.documentElement.style.overflowY = "hidden";
  }

  beforeClose() {
    document.documentElement.style.overflowY = "auto";
  }
}
