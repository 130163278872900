import { ServiceLocator } from "@/ServiceLocator";
import { BaseService } from "@/services/BaseService";
import { TicketModel } from "../Order/models/OrderModel";
import { TouristEditModel, TouristPasswordModel } from "./models/TouristModel";

export class TouristToursService extends BaseService {
  touristLocalStorageKey = "tourist_store";

  async save(tourist: TouristEditModel) {
    tourist.phone = tourist.phone.startsWith("+")
      ? tourist.phone
      : "+" + tourist.phone;
    return await this.apiRequest.post(
      `search-module/clients/update`,
      {},
      tourist
    );
  }

  async getDataTypes() {
    const { data } = await this.apiRequest.getJSON("front/tourists/data");
    return data.data;
  }

  async uploadAvatar(formData: FormData) {
    try {
      // const result = await this.apiRequest.postForm("search-module/clients/save-logo", {}, formData);
      // const touristUser = ServiceLocator.instance.getService(AuthService).touristUser;
      // if (!!result.data?.data && !!touristUser) {
      //   touristUser.logo = result.data?.data;
      // }
    } catch (err) {
      return console.log(err);
    }
  }

  async changePassword(passwords: TouristPasswordModel) {
    return await this.apiRequest.post(
      "search-module/clients/change-password",
      {},
      passwords
    );
  }

  saveTouristToStorage(ticketModel: TicketModel) {
    if (!this.isTouristExists(ticketModel) && ticketModel.document_number > 0) {
      const toStore = ticketModel;
      const prevVal =
        window.localStorage.getItem(this.touristLocalStorageKey) || "";
      window.localStorage.setItem(
        this.touristLocalStorageKey,
        `${prevVal}|${JSON.stringify(toStore)}`
      );
    }
  }

  getAllTouristsFromStorage(): TicketModel[] | null {
    const vals = window.localStorage.getItem(this.touristLocalStorageKey);
    if (!!vals) {
      const favorList = vals
        .split("|")
        .filter((iter) => !!iter)
        .map((iter) => {
          return JSON.parse(iter);
        });
      return favorList;
    }
    return null;
  }

  searchTouristByLastName(lastNamePattern: string) {
    if (!!lastNamePattern && lastNamePattern.length > 0) {
      const all = this.getAllTouristsFromStorage();
      return all?.filter((iter) =>
        iter.last_name?.toUpperCase().includes(lastNamePattern?.toUpperCase())
      );
    }
  }

  private isTouristExists(ticketModel: TicketModel) {
    const vals = this.getAllTouristsFromStorage();
    return vals?.find(
      (iter) => iter.document_number === ticketModel.document_number
    );
  }
}
