import { DocumentModel } from "@/modules/Agency/models/DocumentModel";
import { BaseService } from "@/services/BaseService";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { AgencyModel, AgencyPasswordModel } from "./models/AgencyModel";
import { ServiceLocator } from "./../../ServiceLocator";
import { EmployeeModel } from "./models/EmployeeModel";

export class AgencyService extends BaseService {
  async getLoalityProgramm() {
    return await this.getOneOrEmpty(
      BaseViewModel,
      "search-module/pages/loyalty-program"
    );
  }

  async saveAgency(agency: AgencyModel) {
    agency.contact_phone = agency.contact_phone.startsWith("+")
      ? agency.contact_phone
      : "+" + agency.contact_phone;
    agency.company_phone = agency.company_phone.startsWith("+")
      ? agency.company_phone
      : "+" + agency.company_phone;
    return await this.apiRequest.post(
      `search-module/agencies/update`,
      {},
      agency
    );
  }

  async uploadAvatar(formData: FormData) {
    try {
      const result = await this.apiRequest.postForm(
        "search-module/agencies/save-logo",
        {},
        formData
      );
      const agencyUser = {};
      if (!!result.data?.data && !!agencyUser) {
        // agencyUser.logo = result.data?.data;
      }
    } catch (err) {
      return console.log(err);
    }
  }

  async changePassword(passwords: AgencyPasswordModel) {
    return await this.apiRequest.post(
      "search-module/agencies/change-password",
      {},
      passwords
    );
  }

  async getReportObjectUrl(month: number, year: number) {
    const url = `search-module/agencies/applications/report?month=${month}&year=${year}`;
    // const response = await this.apiRequest.getJSON(url, { responseType: "blob" });
    const response = await this.apiRequest.getJSON(url, {
      responseType: "arraybuffer",
    });
    if (!!response && response.status === 200) {
      // return window.URL.createObjectURL(new Blob([response.data]));
      return window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf;charset=utf-8" })
      );
    }
    return null;
  }

  getEmployeeList() {
    return this.getArrayOrEmpty(
      EmployeeModel,
      "search-module/agencies/users?limit=99"
    );
  }

  addEmployee(model: EmployeeModel) {
    model.phone = model.phone.startsWith("+") ? model.phone : "+" + model.phone;
    return this.apiRequest.post("search-module/agencies/users", {}, model);
  }

  async deleteEmployee(model: EmployeeModel) {
    try {
      await this.apiRequest.delete(`search-module/agencies/users/${model.id}`);
      return true;
    } catch {
      return false;
    }
  }

  async editEmployee(model: EmployeeModel) {
    try {
      model.phone = model.phone.startsWith("+")
        ? model.phone
        : "+" + model.phone;
      await this.apiRequest.put(
        `search-module/agencies/users/${model.id}`,
        {},
        model
      );
      return true;
    } catch {
      return false;
    }
  }

  getFullEmployeeList() {
    return this.getArrayOrEmpty(
      EmployeeModel,
      "search-module/agencies/users/list"
    );
  }

  getDocumentsList() {
    return this.getArrayOrEmpty(DocumentModel, "search-module/agencies/files");
  }
}
