import { stringify } from "query-string";
import dayjs from "dayjs";
import { BaseService } from "@/services/BaseService";
import { PaginationCollection } from "@/api/PaginationCollection";
import { Pagination } from "@/models/core/Pagination";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { HotelModel } from "./models/HotelModel";

export class HotelService extends BaseService {
  public searchByName(name: string) {
    return this.getArrayOrEmpty(
      HotelModel,
      `search-module/hotels/search-by-name?name=${name}`
    );
  }

  public async findhHotels(queryObject: any, pagination: Pagination): Promise<PaginationCollection<HotelModel>> {
    return await this.getArrayOrEmptyWithPaginationGet(
      HotelModel,
      `search-module/hotels/search/?${stringify(queryObject, {
        arrayFormat: "bracket",
      })}`,
      {},
      pagination
    );
  }

  public async getHotel(slug: string, queryObject: any): Promise<HotelModel> {
    return await this.getOneOrFail(
      HotelModel,
      `search-module/hotels/${slug}?${stringify(queryObject, {
        arrayFormat: "bracket",
      })}`
    );
  }

  public async getHotelListByIdList(hotelIdList: number[], queryObject: any): Promise<HotelModel[]> {
    const params = { ids: Array.from(new Set(hotelIdList)), ...queryObject };
    if (!!hotelIdList && Array.isArray(hotelIdList) && hotelIdList.length > 0) {
      return await this.getArrayOrEmpty(
        HotelModel,
        `search-module/hotels/collect-by-ids?${stringify(params, {
          arrayFormat: "bracket",
        })}`
      );
    }
    return [];
  }

  public getDefaultFilterParams() {
    const filterModel = new BaseFilterModel();

    filterModel.to_city_id = 27;
    filterModel.direction = "Анапа";

    filterModel.date_from = dayjs().format("YYYY-MM-DD");
    filterModel.date_to = dayjs().add(10, "day").format("YYYY-MM-DD");
    filterModel.adult_count = 1;
    filterModel.child_count = 0;
    return filterModel.cloneAndRemoveEmptyValue(["child_count"]);
  }
}
