
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import SwiperInstance, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { SwiperOptions, Swiper } from "swiper/swiper.d";
import { v4 as uuidv4 } from "uuid";

import settings from "./settings";
import SwiperPagination from "./controls/SwiperPagination.vue";
import SwiperPaginationNavigation from "./controls/SwiperPaginationNavigation.vue";
import SwiperPaginationBottom from "./controls/SwiperPaginationBottom.vue";

// Import Swiper styles
import "swiper/swiper.scss";

SwiperInstance.use([Navigation, Pagination, A11y, Autoplay]);

export interface Slide {
  albumId: number;
  id: number;
  title: string;
  url: string;
  thumbnailUrl: string;
}

export type TypeSlider = "pagination-navigation" | "pagination" | "pagination-bottom";

@Component({
  components: {
    SwiperPaginationBottom,
    SwiperPaginationNavigation,
    SwiperPagination,
  },
})
export default class BaseSwiper extends Vue {
  @Prop({ default: () => uuidv4() })
  uid: string;

  @Prop({ required: true })
  readonly type!: string;

  @Prop()
  slideList: any[];

  @Prop({ default: true })
  isImage: boolean;

  @Prop({ default: false })
  isBackground: boolean;

  @Prop({ default: "image" })
  imageName: string;

  @Prop({ default: "" })
  classSlide: string;

  @Prop({ default: "" })
  classBackground: string;

  @Prop({ default: false })
  isOpenImageToGallery: boolean;

  @Prop({ default: false })
  isInit: boolean;

  @Prop({ default: false })
  isBottomControls: boolean;

  @Watch("isInit")
  changeIsInit() {
    this.initSwiper();
  }

  private swiperInstance: Swiper = {} as Swiper;

  private settings: SwiperOptions = settings[this.type];

  get hasCardSlot() {
    return !!this.$scopedSlots.card || !!this.$slots.card;
  }

  get isVisibleControls() {
    return !!(this.slideList && this.slideList.length >= 2);
  }

  get getSlide() {
    switch (this.type) {
      case "pagination-bottom":
        return "SwiperPaginationBottom";
      case "pagination-navigation":
        return "SwiperPaginationNavigation";
      case "pagination":
        return "SwiperPagination";
      default:
        return {};
    }
  }

  openImageToGallery() {
    if (this.isOpenImageToGallery) {
      this.$emit("open-gallery");
    }
  }

  initSwiper() {
    let settings = this.settings;
    if (this.hasCardSlot) {
      const autoplay = {
        disableOnInteraction: false,
        delay: 3000,
      };
      settings = { ...settings, ...{ autoplay } };
    }
    this.swiperInstance = new SwiperInstance(`.swiper-container--${this.uid}`, settings);
  }

  async mounted() {
    await this.$nextTick();
    if (!this.isInit) this.initSwiper();
  }
}
