/* eslint-disable camelcase */
import { FovoritesSupport } from "@/models/common/FovoritesSupport";
import { ExcursionTransportForthModel } from "@/modules/Excursions/models/ExcursionModel";
import { HotelModel } from "@/modules/Hotels/models/HotelModel";

export class SeaTour extends FovoritesSupport {
  id = 0;
  name = "";
  hotel: HotelModel = new HotelModel();
  start_date = "";
  finish_date = "";
  agency_commission = 0;

  transports: {
    transport_forth: ExcursionTransportForthModel;
    transport_back: ExcursionTransportForthModel;
  }[] = [{ transport_forth: new ExcursionTransportForthModel(), transport_back: new ExcursionTransportForthModel() }];

  transport_forth: ExcursionTransportForthModel = new ExcursionTransportForthModel();
  transport_back: ExcursionTransportForthModel = new ExcursionTransportForthModel();
}
