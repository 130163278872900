
import { Component, Vue, Prop } from "vue-property-decorator";
import { ExcursionDateModel } from "../models/ExcursionModel";
import { formatDateShort, dayNumber, month } from "@/utils/Formaters";

type CurrencyType = "₽" | "$" | "€" | "£";

@Component
export default class TourDate extends Vue {
  @Prop({ required: true })
  day: ExcursionDateModel;

  @Prop({ default: "₽" })
  currency: CurrencyType;

  get content() {
    // return "";
    return `
      <div class="TourDate__wrap">
        <div class="TourDate__top">
          Даты тура:  ${this.startDay} -
          ${this.endDay}
        </div>
      </div>
    `;
  }

  tourDayClicked() {
    this.$emit("on-day-clicked");
  }

  get startDay() {
    return formatDateShort(this.day.start_date);
  }

  get endDay() {
    return formatDateShort(this.day.finish_date);
  }

  get dayPrice() {
    return 10000;
  }

  get startDayMonth() {
    return month(this.day.start_date);
  }

  get startDayNumber() {
    return dayNumber(this.day.start_date);
  }
}
