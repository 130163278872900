var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('VueModal',{attrs:{"based-on":_vm.showModal,"wrapper-class":"Book__wrapper"},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"titlebar",fn:function(){return [_c('button',{staticClass:"RoomImages__close",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('svg',{attrs:{"width":"24","height":"26","viewBox":"0 0 24 26","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"6.5376","y":"6.31836","width":"17.6582","height":"2.20728","rx":"1.10364","transform":"rotate(45 6.5376 6.31836)","fill":"#16192C"}}),_c('rect',{attrs:{"x":"19.0239","y":"7.87988","width":"17.6582","height":"2.20728","rx":"1.10364","transform":"rotate(135 19.0239 7.87988)","fill":"#16192C"}})])])]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"Book"},[_c('div',{staticClass:"Book__image"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.$getImage('/images/Hotels/reserve-autobus.png')}})]),_c('div',{staticClass:"Book__text"},[_c('div',{staticClass:"Book__title"},[_vm._v("Забронировать "),_c('br'),_vm._v("билеты на автобус?")]),_c('div',{staticClass:"Book__btns"},[_c('base-button',{staticClass:"Book__btn",on:{"click":function($event){{
                _vm.$emit('close');
                _vm.$emit('book_bus');
              }}}},[_vm._v(" Забронировать ")]),_c('base-button',{staticClass:"Book__btn",attrs:{"dark":true,"outline":true},on:{"click":function($event){{
                _vm.$emit('close');
                _vm.$emit('dont-book_bus');
              }}}},[_vm._v(" Нет, сам доберусь ")])],1)])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }