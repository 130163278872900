import { CityModel } from "@/models/common/CityModel";
import AppStore from "@/store/AppStore";

import { BaseService } from "./BaseService";

export class CommonService extends BaseService {
  public async getCities(name: string): Promise<CityModel[]> {
    return await this.getArrayOrEmptyPost(
      CityModel,
      "search-module/cities/search",
      null,
      { name }
    );
  }

  public log(message: any) {
  }

  // public openPersonalDataConsentModal() {
  //   this.nuxtContext.$modalManager.modalShow(
  //     PersonalDataConsent,
  //     {},
  //     { clickToClose: true, classes: "v--modal w-50vw h-90vh m-auto left-0" }
  //   );
  // }

  // public openOfertaContractModal() {
  //   this.nuxtContext.$modalManager.modalShow(
  //     OfertaContractContent,
  //     {},
  //     { clickToClose: true, classes: "v--modal w-50vw h-90vh m-auto left-0" }
  //   );
  // }
}
