
import { Vue, Component } from "vue-property-decorator";

@Component
export default class BaseAdvancedFilter extends Vue {
  clearFilterClicked = false;

  clearFilter() {
    this.clearFilterClicked = !this.clearFilterClicked;
  }
}
