
import { Component, Vue, Prop } from "vue-property-decorator";
import { v4 as uuidv4 } from "uuid";

type InputType = "text" | "number" | "tel" | "email" | "password";

@Component({
  inheritAttrs: false,
})
export default class BaseInput extends Vue {
  showPassword = false;

  @Prop({ default: () => uuidv4() })
  id: string;

  @Prop()
  value;

  @Prop({ default: false })
  isShakeError: boolean;

  @Prop({ default: "text" })
  type: InputType;

  @Prop()
  label: string;

  @Prop({ default: false })
  labelRequered: boolean;

  @Prop()
  help: string;

  @Prop()
  classes: string;

  @Prop()
  errorText: string;

  @Prop({ default: false })
  hasError: boolean;

  get initType() {
    if (this.type === "password") {
      return this.showPassword ? "text" : "password";
    }
    return this.type;
  }

  get currentClasses() {
    return {
      "Field__input--suffix": this.$slots.suffix,
      "Field__input--prefix": this.$slots.prefix,
      "Field__input--error": this.hasError,
      "Field__input--help": !!this.help,
    };
  }
}
