import dayjs from "dayjs";
import { stringify } from "query-string";
import { PaginationCollection } from "@/api/PaginationCollection";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { Pagination } from "@/models/core/Pagination";
import { BaseService } from "@/services/BaseService";
import { BaseViewModel } from "@/models/core/BaseViewModel";
import { HotelModel } from "../Hotels/models/HotelModel";
import { SeaTour } from "./models/SeaTour";

export class TourService extends BaseService {
  public searchByName(name: string) {
    return this.getArrayOrEmpty(
      HotelModel,
      `search-module/tours/sea/search-by-name?name=${name}`
    );
  }

  public async findSeaTours(queryObject: any, pagination: Pagination): Promise<PaginationCollection<HotelModel>> {
    return await this.getArrayOrEmptyWithPaginationGet(
      HotelModel,
      `search-module/tours/sea/search?${stringify(queryObject, {
        arrayFormat: "bracket",
      })}`,
      queryObject,
      pagination
    );
  }

  public async getSeaTour(slug: string, queryObject: any): Promise<SeaTour> {
    const result = await this.getOneOrFail(
      SeaTour,
      `search-module/tours/sea/${slug}?${stringify(queryObject, {
        arrayFormat: "bracket",
      })}`
    );

    result.transport_forth = result.transports[0].transport_forth;
    result.transport_back = result.transports[0].transport_back;
    if (!!result.transport_forth) {
      result.transport_forth.forthCheckbox = false;
    }
    if (!!result.transport_back) {
      result.transport_back.backCheckbox = false;
    }

    return result;
  }

  public async getSeaTourCalendar(queryObject: any): Promise<{ date: string; count: number }[]> {
    const url = `search-module/tours/sea/calendar/forth?${stringify(
      queryObject,
      { arrayFormat: "bracket" }
    )}`;
    return (await this.getArrayOrEmpty(BaseViewModel, url)) as any;
  }

  public async getSeaTourCalendarBack(queryObject: any): Promise<{ date: string; count: number }[]> {
    const url = `search-module/tours/sea/calendar/back?${stringify(
      queryObject,
      { arrayFormat: "bracket" }
    )}`;
    return (await this.getArrayOrEmpty(BaseViewModel, url)) as any;
  }

  public async getSeaTourListByIdList(idList: string[], hotelsids: string[], queryObject: any): Promise<HotelModel[]> {
    const params = {
      ids: Array.from(new Set(idList)),
      hotels_ids: Array.from(new Set(hotelsids)),
      ...queryObject,
    };
    if (!!idList && Array.isArray(idList) && idList.length > 0) {
      return await this.getArrayOrEmpty(
        HotelModel,
        `search-module/tours/sea/collect-by-ids?${stringify(params, {
          arrayFormat: "bracket",
        })}`
      );
    }
    return [];
  }

  public getDefaultFilterParams() {
    const filterModel = new BaseFilterModel();

    filterModel.from_city_id = 1105;
    filterModel.from = "Москва";
    filterModel.to_city_id = 27;
    filterModel.direction = "Анапа";

    filterModel.start_date = dayjs().format("YYYY-MM-DD");
    filterModel.finish_date = dayjs().add(10, "day").format("YYYY-MM-DD");
    filterModel.adult_count = 1;
    filterModel.child_count = 0;
    return filterModel.cloneAndRemoveEmptyValue(["child_count"]);
  }
}
