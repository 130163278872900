
import BaseFilter from "@/components/filter/BaseFilter.vue";
import { getModule } from "vuex-module-decorators";
import TheContainer from "./components/TheContainer.vue";
import AppStore from "@/store/AppStore";
import HotelCardPage from "./modules/Hotels/HotelCardPage.vue";
import DirectionPage from "@/modules/Directions/pages/DirectionsPage.vue";
import ExcursionCardPage from "@/modules/Excursions/ExcursionCardPage.vue";
import ExcursionListPage from "@/modules/Excursions/ExcursionListPage.vue";
import HotelListPage from "@/modules/Hotels/HotelListPage.vue";
import ToursListPage from "@/modules/Tours/TourListPage.vue";
import OrderPage from "@/modules/Order/OrderPage.vue";
import { Component, Vue } from "vue-property-decorator";
import TourCardPage from "@/modules/Tours/TourCardPage.vue";

@Component({
  components: {
    DirectionPage,
    ToursListPage,
    ExcursionCardPage,
    ExcursionListPage,
    HotelListPage,
    BaseFilter,
    HotelCardPage,
    TheContainer,
    TourCardPage,
    OrderPage,
  },
})
export default class App extends Vue {
  get currentRoute() {
    return getModule(AppStore, this.$store).currentRoute;
  }
}
