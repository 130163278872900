
import { Component, Prop, Vue } from "vue-property-decorator";
import { HotelInfrastructuresModel } from "@/modules/Hotels/models/HotelModel";
@Component
export default class ModalInfrastructure extends Vue {
  @Prop()
  showModal: boolean;

  @Prop()
  infrastructures: HotelInfrastructuresModel[];

  beforeOpen() {
    document.documentElement.style.overflowY = "hidden";
  }

  beforeClose() {
    document.documentElement.style.overflowY = "auto";
  }
}
