
import { Component, Prop, Vue } from "vue-property-decorator";
import { formatDateStandart } from "@/utils/Formaters";
import { TicketModel } from "../models/OrderModel";

@Component
export default class TicketInfo extends Vue {
  @Prop({ default: 1 })
  touristNumber;

  @Prop()
  ticketModel: TicketModel;

  get birthdayDate() {
    return formatDateStandart(this.ticketModel.birthday_date);
  }

  get citizenship() {
    return "РОССИЯ";
  }

  get gender() {
    return this.ticketModel.gender === 1 ? "М" : "Ж";
  }

  get document() {
    return "Паспорт РФ";
  }
}
