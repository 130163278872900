
import { Component, Prop, Vue } from "vue-property-decorator";
import { formatDateFirstDay } from "@/utils/Formaters";

@Component
export default class HotelPlaces extends Vue {
  @Prop()
  dateFrom: string;

  @Prop()
  dateTo: string;

  @Prop({ default: "не указано" })
  arrivalTime: string;

  @Prop({ default: "не указано" })
  leaveTime: string;

  @Prop()
  people: string;

  @Prop()
  duration: string;

  formatDate(date) {
    return formatDateFirstDay(date);
  }
}
