
import { Vue, Component, Prop } from "vue-property-decorator";
import { MealModel } from "@/modules/Hotels/models/MealModel";
import { HotelDictianarySerivce } from "@/modules/Hotels/HotelDictianarySerivce";
import { BaseFilterModel } from "@/models/BaseFilterModel";
import { numberMask } from "@/utils/InputMaskDefinitions";
import { HotelCategoriesModel } from "../models/HotelCategoriesModel";
import { HotelSea } from "../models/HotelSea";
import { ServiceInHotel } from "../models/ServiceInHotel";

@Component
export default class HotelFilter extends Vue {
  @Prop()
  filterModel: BaseFilterModel;

  private mealList: MealModel[] = [];
  private servicesInHotel: ServiceInHotel[] = [];
  private hotelCategories: HotelCategoriesModel[] = [];
  private hotelSeaDistances: HotelSea[] = [];

  showMobile = false;

  async mounted() {
    const result = await Promise.all([
      this.$serviceLocator.getService(HotelDictianarySerivce).getMealsList(),
      this.$serviceLocator
        .getService(HotelDictianarySerivce)
        .getHotelServicesExtended(),
      this.$serviceLocator
        .getService(HotelDictianarySerivce)
        .getHotelCategories(),
      this.$serviceLocator
        .getService(HotelDictianarySerivce)
        .getHotelSeaDistances(),
    ]);
    this.mealList = result[0];
    this.servicesInHotel = result[1];
    this.hotelCategories = result[2];
    this.hotelSeaDistances = result[3];
  }

  get numberMask() {
    return numberMask;
  }
}
